* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Lexend', sans-serif;
  &:active, &:focus, &:focus-visible, &:visited {
    outline: none !important;
  }
  &::-webkit-scrollbar {
    height: 3px !important;
    width: 3px !important;
  }
  &::-webkit-scrollbar-track {
    border-radius: 100px;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(92, 0, 137, 0.3);
    border-radius: 100px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(92, 0, 137, 0.6);
  }
  & input[type=number]::-webkit-inner-spin-button,
  & input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  &::placeholder {
    color: #bbb6b6;
    font-size: 14px;
    font-weight: 300;
    opacity: 1; /* Firefox */
    text-transform: lowercase;
  }
  &::-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #D9D9D9;
    font-weight: 400;
    font-size: 14px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  }
}
:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  //Used only for colored theme
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  // Used when no type is provided
  // toast("**hello**")
  --toastify-color-progress-light: linear-gradient(
                  to right,
                  #4cd964,
                  #5ac8fa,
                  #007aff,
                  #34aadc,
                  #5856d6,
                  #ff2d55
  );
  // Used when no type is provided
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

html, body, #root {
  height: 100%;
}
#root {
  overflow: auto;
}

.new_form {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  &_logo {
    width: 200px;
    height: auto;
    background-position: center;
    object-fit: cover;
  }
  &_title {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    text-align: center;
    color: #000000;
    height: 50px;
    line-height: 50px;
  }
  &_description {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #000;
    margin: 10px 0;
  }
  &_columns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    &_loading {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 8;
      background: #fff;
      border-radius: 6px;
      padding: 5px;
    }
  }
  &_column {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    position: relative;
    &--right {
      margin-right: 15px;
    }
    &_wrapper {
      height: 82px;
      width: 100%;
      margin-bottom: 25px;
    }
    &_animated {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      margin-right: 10px;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: -1;
      margin-bottom: 20px;
      &--show {
        animation: animateInput .5s ease;
        opacity: 1;
        z-index: 1;
        top: 82px;
      }
    }
    & input {
      display: block;
      transition: all .3s ease;
      box-sizing: border-box;
      width: 100%;
      padding: 15px 10px;
      line-height: 15px;
      border: 1px solid #A39F9F;
      background: transparent;
      border-radius: 16px;
      height: 35px;
      font-weight: 300;
      font-size: 13px;
      color: #000;
      margin: 10px 0 10px 0;
      &:focus, &:hover, &:active {
        border: 1px solid #5C0089;
      }
      &::placeholder {
        color: #bbb6b6;
        font-weight: 200;
        font-size: 13px;
      }
    }
    & label {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #000000;
      margin: 20px 0 7px 0;
    }
  }
  &_actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    &_in {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      button {
        margin: 0 0 0 0 !important;
      }
      h2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #000;
        cursor: pointer;
        transition: all .3s ease;
        &:hover {
          color: #5C0089;
        }
      }
    }
    h2 {
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #000;
      cursor: pointer;
      transition: all .3s ease;
      &:hover {
        color: #5C0089;
      }
    }
    &_login {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 20px;
      input[type="checkbox"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin-right: 10px;
        cursor: pointer;
        display: inline-block;
        width: 20px !important;
        height: 20px !important;
        color: #fff;
        padding: 1px;
        background-clip: content-box;
        border: 2px solid rgb(200, 200, 200);
        background-color: rgb(200, 200, 200);
        border-radius: 100px;
      }
      input[type="checkbox"]:checked {
        background-color: #5c0089;
      }
      h2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #000;
        cursor: pointer;
        transition: all .3s ease;
        &:hover {
          color: #5C0089;
        }
      }
      span {
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 22px;
        color: #000;
        margin-right: 5px;
      }
    }
    &_back {
      background: #fff !important;
      color: #000 !important;
      border: 1px solid #000 !important;
    }
    &_error {
      color: red;
      font-weight: 400;
      width: 100%;
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px 0;
      margin-top: 10px;
      min-height: 28px;
      svg {
        margin-right: 5px;
        font-size: 20px;
        color: red;
      }
    }
    &_pending {
      cursor: default !important;
      background: #fff !important;
    }
    button {
      height: 48.5px;
      border: 1px solid #5C0089;
      color: #fff;
      transition: all .3s;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: .055em;
      padding: 15px 12px;
      cursor: pointer;
      box-sizing: border-box;
      width: 154px;
      background: #5C0089;
      border-radius: 26px;
      margin: 0 auto;
      &:hover {
        transform: translateY(-2px);
        background: #f0b101;
        color: #fff;
        border: 1px solid #f0b101;
      }
    }
    h3 {
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 22px;
      color: #000;
      transition: all .3s ease;
      cursor: pointer;
      margin: 20px 0;
      &:hover {
        color: #5C0089;
      }
    }
  }
}

.tooltipClass {
  font-size: 11px !important;
  font-family: 'Lexend', "sans-serif";
  text-transform: lowercase;
  z-index: 7;
}

.react-tooltip {
  background: rgba(#000, 1) !important;
  opacity: .85 !important;
  z-index: 11;
}

.billing_popup {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #f1f3f7;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  &_in {
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 50px 20px;
    &_warning {
      font-size: 16px;
      color: red;
      font-weight: 600;
      padding-bottom: 30px;
      text-transform: uppercase;
    }
    p {
      font-size: 14px;
      color: #000;
      font-weight: 400;
      width: 100%;
      text-align: center;
      line-height: 25px;
    }
  }
}

@keyframes animateInput {
  0% {
    top: 0;
    opacity: 0;
    z-index: -1;
  }
  50% {
    top: 82px;
  }
  100% {
    opacity: 1;
    z-index: 1;
  }
}

@media only screen and (max-width: 600px) {
  .new_form_actions button {
    padding: 0 3px;
    height: 35px;
    width: 125px;
    font-size: 10px;
  }
  .new_form_actions_login span {
    font-size: 10px;
  }
  .new_form_actions h2 {
    font-size: 12px;
  }
  .new_form_actions_login {
    flex-wrap: wrap;
  }
  .new_form_actions_login input[type="checkbox"] {
    width: 15px !important;
    height: 15px !important;
  }
  .new_form_title {
    font-size: 25px;
  }
  .new_form_logo {
    width: 150px;
    margin-bottom: 10px;
  }
  .new_form_actions_error {
    font-size: 10px;
  }
  .new_form_description {
    margin-top: 5px;
  }
  .tooltipClass {
    display: none;
  }
  .billing_popup {
    &_in {
      width: 95%;
      height: 95%;
      &_warning {
        font-size: 14px;
      }
      p {
        font-size: 12px;
      }
    }
  }
}

