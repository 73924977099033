.not_found {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #f8faff;
  &__cover {
    width: 55%;
    display: block;
    height: 100vh;
    overflow: hidden;
    & img {
      height: 100%;
      background-size: cover;
      background-position: center;
      filter: brightness(0.8);
    }
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    height: 100vh;
    padding: 0 50px;
    & h2 {
      font-size: 32px;
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 1px;
      padding: 15px 0;
      text-align: center;
      display: inline-block;
      width: 100%;
      color: #000;
    }
    & p {
      font-size: 16px;
      font-weight: 300;
      width: 100%;
      color: #000;
      text-align: center;
      display: inline-block;
    }
    button {
      border: 1px solid #5C0089;
      color: #fff;
      transition: all .3s;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: .055em;
      padding: 8px 12px;
      cursor: pointer;
      box-sizing: border-box;
      width: 180px;
      background: #5C0089;
      border-radius: 26px;
      margin-top: 15px;
      &:hover {
        background: #f0b101;
        color: #fff;
        border: 1px solid #f0b101;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .not_found__content {
    width: 70%;
  }
}

@media only screen and (max-width: 600px) {
  .not_found__content {
    width: 100%;
    padding: 0 25px;
  }
  .not_found__content h2 {
    font-size: 24px;
  }
  .not_found__content p {
    font-size: 14px;
  }
  .not_found__content button {
    font-size: 10px;
  }
}