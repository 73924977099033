.update_agent {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 750px;
  padding: 20px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  z-index: 7;
  &_loading {
    width: 60px;
    height: 60px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-self: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 8;
    background: #fff;
    border-radius: 6px;
  }
  &_col {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
    .drop_down {
      width: 400px;
    }
    &--fit {
      .drop_down {
        width: 100%;
      }
    }
  }
  &_in {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .form_header {
      padding: 0;
    }
    &_custom_dropdown_menu {
      width: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .3s;
      & ul {
        display: flex;
        width: 100%;
        & li {
          display: flex;
          align-items: center !important;
          justify-content: center;
        }
      }
      &__descrip {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 12px;
        font-weight: 600;
        margin-left: 5px;
        padding: 5px 0;
        width: 100%;
        flex-wrap: wrap;
      }
      &__tasks {
        font-size: 12px;
        margin-left: auto;
        &_total {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 12px;
          font-weight: 600;
          margin: 0 10px 0 5px;
        }
      }
      &__status {
        text-transform: uppercase;
        border-radius: 100px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600 !important;
        font-size: 7px;
        padding: 1px 3px;
        width: 30px;
        height: 22px;
        margin-left: auto;
        margin-right: 0;
        &--online {
          background: green;
          color: #fff;
        }
        &--offline {
          background: #f0b101;
          color: #fff;
        }
      }
      & svg {
        font-size: 16px;
        color: #5c0089;
        transition: all .3s;
        margin-right: 10px;
      }
      &__icon {
        margin-top: 4px;
        & svg {
          font-size: 16px;
          color: #5c0089;
          transition: all .3s;
        }
      }
    }
    &_col {
      display: flex;
      width: 100%;
      align-items: center;
    }
    &_data_message {
      width: 100%;
      display: inline;
      text-align: end;
      padding: 5px 10px;
      & p {
        font-size: 12px !important;
        font-weight: 500 !important;
        width: 100% !important;
        color: red !important;
      }
      &--success {
        & p {
          color: green;
        }
      }
    }
  }
  &__cancel_btn {
    display: flex;
    height: 100%;
    line-height: 30px;
    font-size: 14px;
    text-decoration: none;
    text-transform: lowercase;
    transition: all .3s;
    color: #444;
    background: none;
    border: none;
    cursor: pointer;
    margin: 20px 0;
    &:hover {
      color: #5c0089;
    }
  }
  &__submit_btn {
    margin: 20px 0;
    background: #5C0089;
    color: #fff;
    border: 1px solid #5C0089;
    font-size: 14px;
    text-transform: lowercase;
    padding: 7px 10px;
    transition: all .3s ease;
    border-radius: 18px;
    height: 35px;
    cursor: pointer;
    width: 100%;
    &--pending {
      .loading-circle {
        padding: 0;
        height: 100%;
      }
      background: #f0b101 !important;
      border: 1px solid #f0b101;
    }
    &:hover {
      background: #f0b101;
      color: #fff;
      border: 1px solid #f0b101;
    }
  }
}

@media only screen and (max-width: 800px) {
  .update_agent {
    width: 95%;
  }
}
@media only screen and (max-width: 600px) {
  .update_agent__submit_btn {
    font-size: 12px;
  }
  .update_agent__cancel_btn {
    font-size: 12px;
  }
}