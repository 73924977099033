.billing_products_modal {
  width: 100%;
  height: 100%;
  border: 1px solid #999;
  background: #f1f3f7;
  z-index: 7;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  &_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    height: 150px;
    &_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    &_in {
      width: 100%;
    }
  }
  &__close {
    cursor: pointer;
    transition: all .3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 22px;
      transition: all .3s ease;
    }
    &:hover {
      svg {
        color: #5C0089;
      }
    }
  }
  &__title {
    width: 100%;
    font-size: 18px;
    color: #000;
    font-weight: 600;
  }
  &__descrip {
    width: 100%;
    font-size: 14px;
    padding: 10px 10px 10px 0;
    color: #000;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
  }
  &__switcher {
    width: 300px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .3s ease;
    height: 50px;
    p {
      color: #007aff;
      font-size: 14px;
      font-weight: 600;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    &:hover {
      color: #5C0089;
    }
  }
  &_in {
    width: 100%;
    height: calc(100% - 150px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px 40px 20px 40px;
    overflow: auto;
  }
}

@media only screen and (max-width: 1024px) {
  .billing_products_modal_in {
    padding: 10px 0;
    justify-content: center;
  }
}
@media only screen and (max-width: 600px) {
  .billing_products_modal__descrip {
    font-size: 12px;
  }
  .billing_products_modal__title {
    font-size: 16px;
  }
  .billing_products_modal__close svg {
    font-size: 20px;
  }
  .billing_products_modal_header {
    padding: 20px;
  }
}
@media only screen and (max-width: 400px) {
  .billing_products_modal__descrip {
    font-size: 12px;
  }
  .billing_products_modal__title {
    font-size: 14px;
  }
}
