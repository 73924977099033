.marker {
  padding: 6px;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.gm-style .gm-style-iw-c {
  padding: 0 !important;
  .gm-style-iw-d {
    padding: 0 !important;
    overflow: hidden !important;
  }
  button {
    display: none !important;
  }
}
