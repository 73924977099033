.sign_up {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &_in {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    &_cover {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      overflow: hidden;
      background-position: top;
      background-repeat: no-repeat;
      object-fit: contain;
      background-size: 100%;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        background-position: center;
        object-fit: fill;
        opacity: 1;
      }
      h2 {
        position: absolute;
        bottom: 100px;
        left: 50%;
        transform: translateX(-50%);
        font-style: normal;
        font-weight: 900;
        font-size: 18px;
        line-height: 18px;
        text-align: center;
        color: #000000;
        width: 100%;
      }
      p {
        width: 100%;
        position: absolute;
        bottom: 75px;
        left: 50%;
        transform: translateX(-50%);
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 10px;
        text-align: center;
        color: #000000;
      }
    }
    &_form {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 100px;
    }
  }
  &_verification {
    width: 600px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@keyframes fromRightSide {
  0% {
    transform: translate(100%, -50%);
  }
  100% {
    transform: translate(0, -50%);
  }
}

@media only screen and (max-width: 1024px) {
  .sign_up_in_cover {
    display: none;
  }
  .sign_up_in_form {
    width: 550px;
    padding: 0 15px;
  }
}
@media only screen and (max-width: 600px) {
  .sign_up_in_form {
    width: 100%;
  }
}
