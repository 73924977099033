.assign_agent_form {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 0;
  width: 60%;
  background: #f1f3f7;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 14;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 16px;
  &_overlay {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 0;
    width: 60%;
    background: rgba(0,0,0,0.2);
    z-index: 13;
    &--show {
      animation: overlayFadeIn .5s ease;
      height: 100%;
    }
  }
  &_in {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 75px);
    overflow: auto;
    &_search {
      width: 100%;
      height: 30px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      input {
        width: 100%;
        height: 30px;
        border-radius: 8px;
        border: 1px solid #bbb6b6;
        text-indent: 10px;
        font-size: 13px;
        font-weight: 300;
        transition: all .3s ease;
        &:focus, &:hover, &:active {
          border: 1px solid #666;
        }
        &::placeholder {
          color: #bbb6b6;
          font-weight: 200;
          font-size: 13px;
        }
      }
    }
    &_title {
      width: 100%;
      font-size: 13px;
      font-weight: 400;
      padding: 5px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;
      text-align: left;
      &_btn {
        font-size: 13px;
        color: #007aff;
        font-weight: 400;
        cursor: pointer;
        text-align: right;
        transition: all .3s ease;
        &:hover {
          color: #5C0089;
        }
      }
    }
  }
  &--show {
    height: 60%;
    animation: showAssignForm .5s ease;
  }
}

@media only screen and (max-width: 800px) {
  .assign_agent_form {
    width: 100%;
  }
  .assign_agent_form_overlay {
    width: 100%;
  }
  .assign_agent_form_item_distance {
    margin-left: auto;
  }
}
@media only screen and (max-width: 600px) {
  .assign_agent_form_in_title {
    font-size: 12px;
  }
  .assign_agent_form_in_title_btn {
    font-size: 12px;
  }
  .assign_agent_form_item_username {
    font-size: 12px;
  }
  .assign_agent_form_item_tasks {
    font-size: 12px;
  }
  .assign_agent_form_item_distance {
    font-size: 12px;
  }
  .assign_agent_form_item__img {
    min-width: 30px;
    min-height: 30px;
    max-width: 30px;
    max-height: 30px;
  }
  .assign_agent_form_item__status {
    width: 6px;
    height: 6px;
  }
}