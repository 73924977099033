.map {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute !important;
  &__loading {
    width: 100%;
    height: 100%;
    background: #f1f3f7;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .gm-style iframe + div {
    border: none !important;
  }
}
@media only screen and (max-width: 1024px) {
  .map {
    &_btn {
      display: none;
    }
  }
}
