.branch_all_users_popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 8;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  flex-direction: column;
  &__title {
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    padding: 20px;
    svg {
      color: #000;
      font-size: 18px;
      margin-left: 10px;
      transition: all .3s;
      cursor: pointer;
      &:hover {
        color: #5c0089;
        transform: scale(1.1);
      }
    }
  }
  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    svg {
      color: #000;
      font-size: 22px;
      transition: all .3s;
      cursor: pointer;
      &:hover {
        color: #5c0089;
        transform: rotate(180deg);
      }
    }
  }
  &__overlay {
    z-index: 7;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
  }
  &_in {
    width: 100%;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .table {
      overflow-y: auto;
      padding: 0 20px;
    }
    .table_header {
      position: static;
    }
  }
  .form {
    width: 100%;
  }
}
