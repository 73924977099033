.agent_status {
  margin: 0 3px 3px 3px;
  &--started, &--assigned, &--accepted, &--delivered, &--failed, &--canceled, &--picked_up, &--on_way {
    height: 24px !important;
    color: #fff;
    padding: 0 10px;
    text-transform: lowercase;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid transparent;
    border-radius: 6px;
    font-weight: 300;
    & span {
      font-size: 10px;
      margin-left: 5px;
    }
  }
  &--unassigned {
    background: rgba(128, 128, 128, 1);
    color: #fff !important;
    border: 1px solid rgba(128, 128, 128, 1);
  }
  &--created {
    background: rgba(128, 128, 128, 1);
    border: 1px solid rgba(128, 128, 128, 1);
    color: #fff !important;
  }
  &--assigned{
    background: #f0b101;
    color: #fff !important;
    border: 1px solid #f0b101;
  }
  &--accepted {
    background: darkorange;
    color: #fff !important;
    border: 1px solid darkorange;
  }
  &--started {
    background: rgba(92, 0, 137, 1);
    border: 1px solid rgba(92, 0, 137, 1);
    color: #fff !important;
  }
  &--picked_up {
    background: rgba(0, 0, 139, 1);
    border: 1px solid rgba(0, 0, 139, 1);
    color: #fff !important;
  }
  &--on_way {
    background: rgb(0, 0, 255);
    border: 1px solid rgba(0, 0, 255, 1);
    color: #fff !important;
  }
  &--delivered {
    background: rgba(0, 128, 0, 1);
    border: 1px solid rgba(0, 128, 0, 1);
    color: #fff !important;
  }
  &--canceled, &--failed {
    background: rgba(255, 0, 0, 1);
    color: #fff !important;
    border: 1px solid rgba(255, 0, 0, 1);
  }
}
