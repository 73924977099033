.settings_company_agents {
  width: 100%;
  height: 100%;
  &_in {
    width: 100%;
    height: calc(100% - 70px);
    .table {
      thead {
        height: 40px !important;
        background-color: #fff !important;
        color: #000 !important;
        font-weight: 300 !important;
        font-size: 12px !important;
        border-bottom: 1px solid #f1f3f7 !important;
        box-shadow: none;
        tr {
          box-shadow: none !important;
        }
        th {
          background-color: #fff !important;
        }
      }
    }
    &__title {
      width: 100%;
      font-size: 14px;
      color: #000;
      font-weight: 400;
      text-align: left;
      padding-bottom: 10px;
    }
    &__descrip {
      width: 100%;
      font-size: 14px;
      color: #000;
      font-weight: 300;
      text-align: left;
      padding-bottom: 10px;
    }
    &_options {
      margin: 10px 0 20px 0;
      padding-bottom: 10px;
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      border-bottom: 1px solid #e2e3e5;
      &_list {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        width: 50%;
      }
      &_in {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
      }
      &_drop_down {
        width: 50%;
        .drop_down {
          margin-bottom: 20px;
        }
        .drop_down_search input {
          width: 100%;
          text-transform: unset;
        }
      }
      &_image {
        width: 50%;
        margin-left: 50px;
        &_msg {
          width: 100%;
          padding: 12px 14px;
          box-shadow: 0 0 0 rgba(0, 0, 0, 1);
          background: rgba(#e2e3e5, .5);
          border-radius: 16px;
          border: 1px solid #A39F9F;
          &_header {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 10px;
            &_img {
              width: 25px;
              height: 25px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              margin-right: 5px;
              img {
                width: 100%;
                height: 100%;
                background-position: center;
                background-size: cover;
                object-fit: contain;
                object-position: center;
              }
            }
            p {
              font-size: 11px;
              color: #000;
              font-weight: 300;
              &:last-child {
                margin-left: auto;
              }
            }
          }
          h2 {
            font-size: 14px;
            color: #000;
            margin-bottom: 3px;
            font-weight: 400;
          }
          h3 {
            font-size: 13px;
            color: #000;
            font-weight: 300;
          }
        }
      }
      &_wrapper {
        width: 100%;
        display: flex;
        margin-top: 5px;
        align-items: center;
        justify-content: flex-start;
        textarea {
          height: 100px !important;
          padding: 10px;
        }
        input {
          text-indent: 10px;
        }
        input, textarea {
          border: 1px solid #A39F9F;
          border-radius: 16px;
          color: #000;
          font-size: 14px;
          height: 35px;
          width: 100%;
          text-align: start;
          font-weight: 300;
          cursor: pointer;
          transition: all .3s;
          &:hover, &:focus {
            border: 1px solid #5c0089;
          }
        }
      }
      & svg {
        cursor: pointer;
      }
      &_checkbox {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 7px 0;
        width: 100%;
        border-bottom: 1px solid #f1f3f7;
        label {
          font-size: 12px;
          width: calc(100% - 20px);
        }
        p {
          font-size: 14px;
          font-weight: 400;
          text-transform: lowercase;
          color: #000;
        }
        input[type="checkbox"] {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          cursor: pointer;
          display: inline-block;
          width: 20px !important;
          height: 20px !important;
          color: #fff;
          padding: 1px;
          background-clip: content-box;
          border: 2px solid rgb(200, 200, 200);
          background-color: rgb(200, 200, 200);
          border-radius: 100px;
        }
        input[type="checkbox"]:checked {
          background-color: #5c0089;
        }
      }
      &_switcher {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        min-height: 50px;
        & input[type="checkbox"] {
          -webkit-appearance: none;
          background: #f1f3f7;
          width: 45px;
          height: 7px;
          border-radius: 20px;
          box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
          transition: all .3s;
          position: relative;
          left: -7px;
          cursor: pointer;
          margin-left: 20px;
        }
        & input:checked[type="checkbox"] {
          background: #f1f3f7;
        }
        & input[type="checkbox"]:before {
          content: '';
          position: absolute;
          width: 15px;
          height: 15px;
          border-radius: 20px;
          top: 50%;
          left: -5px;
          transform: translateY(-50%);
          background: #f0b101;
          transition: all .5s;
          box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        }
        & input:checked[type="checkbox"]::before {
          left: 30px;
          background: green;
        }
      }
      &_status_name {
        font-size: 13px !important;
        font-weight: 600 !important;
        color: #000;
        text-transform: uppercase;
      }
      &_status_default {
        font-size: 13px !important;
        font-weight: 300 !important;
        color: #000 !important;
        text-transform: lowercase;
        margin-left: 0;
      }
      .table_body td p {
        min-height: 50px;
      }
    }
    &_actions {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 20px;
      button {
        border: 1px solid #5C0089;
        color: #fff;
        transition: all .3s;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: .055em;
        padding: 7px 14px;
        cursor: pointer;
        box-sizing: border-box;
        width: 154px;
        background: #5C0089;
        border-radius: 26px;
        margin: 0 auto;
        &:hover {
          background: #f0b101;
          color: #fff;
          border: 1px solid #f0b101;
        }
      }
    }
    &_loading {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto 0;
    }
  }
}

@media only screen and (max-width: 1150px) {
  .settings_company_agents_in_options_image {
    margin-left: 20px;
    width: 60%;
  }
  .settings_company_agents_in_options_drop_down {
    width: 40%;
  }
}
@media only screen and (max-width: 1024px) {
  .settings_company_agents_in_options_in {
    flex-wrap: wrap;
  }
  .settings_company_agents_in_options_image {
    margin-left: 0;
    width: 60%;
  }
  .settings_company_agents_in_options_drop_down {
    width: 60%;
  }
  .settings_company_agents_in_options_list {
    width: 60%;
    margin-bottom: 20px;
  }
  .settings_company_agents_in_options_wrapper textarea {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 500px) {
  .settings_company_agents_in__descrip {
    font-size: 12px;
  }
}
@media only screen and (max-width: 800px) {
  .settings_company_agents_in_options_image {
    width: 100%;
  }
  .settings_company_agents_in_options_drop_down {
    width: 100%;
  }
  .settings_company_agents_in_options_list {
    width: 100%;
  }
  .settings_company_agents_in_options_switcher input[type="checkbox"] {
    margin-left: 10px;
  }
}
@media only screen and (max-width: 500px) {
  .settings_company_agents_in_options_image {
    display: none;
  }
  .settings_company_agents_in_options_drop_down .drop_down {
    margin-bottom: 0;
  }
  .settings_company_agents_in_options_wrapper textarea {
    margin-bottom: 0;
  }
}