.agent_info {
  width: 100%;
  cursor: pointer;
  margin-bottom: 10px;
  box-shadow: 0 0 0 rgba(0, 0, 0, 1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: rgba(#e2e3e5, .5);
  border-radius: 35px;
  transition: all .3s;
  animation: animate-to-left .5s ease;
  border: 1px solid #e2e3e5;
  &:hover {
    background: #fff;
    box-shadow: 0 0 0 rgba(0, 0, 0, 1);
    border: 1px solid #999;
  }
  &_details {
    width: 100%;
    padding-left: 10px;
    &_data {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: row;
      position: relative;
      margin-top: 10px;
      width: 100%;
      &__img {
        background: transparent;
        min-width: 60px;
        max-width: 60px;
        min-height: 60px;
        max-height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        object-fit: cover;
        border-radius: 100px;
        margin-right: 25px;
        margin-left: 5px;
        margin-top: 10px;
        overflow: hidden;
        & img {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          object-fit: cover;
          border-radius: 100px;
          align-self: center;
        }
      }
      &__bell {
        background: #e2e3e5;
        min-width: 60px;
        max-width: 60px;
        min-height: 60px;
        max-height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        object-fit: cover;
        border-radius: 100px;
        margin-right: 25px;
        margin-left: 5px;
        margin-top: 10px;
        &:hover {
          & svg {
            color: #5c0089;
            transform: scale(1.2);
          }
        }
        & svg {
          font-size: 25px;
          color: #444;
          transition: all .3s;
          cursor: pointer;
          margin: 0 auto;
        }
      }
      &_rows {
        width: 100%;
        padding-right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
      &_row {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid #999;
        width: 100%;
        padding: 4px 0;
        position: relative;
        &:last-child {
          border-bottom: none;
        }
        &_status {
          display: flex;
          align-items: center;
          justify-content: center;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 12px;
          position: absolute;
          top: 8px;
          right: -3px;
          &::before {
            content: '';
            margin-right: 3px;
            width: 8px;
            height: 8px;
            display: block;
            border-radius: 100px;
          }
          &--online {
            &::before {
              background-color: green;
            }
          }
          &--offline {
            &::before {
              background-color: #e0a500;
            }
          }
        }
        &_title {
          font-size: 13px;
          font-weight: 300;
          min-width: 70px;
          color: #000;
        }
        & p {
          font-size: 14px;
          font-weight: 400;
          color: #000;
          margin-left: 10px;
          width: 150px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  &_money {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    background: transparent;
    width: 100%;
    padding: 5px 20px 20px 20px;
    &__col {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      &_count {
        min-width: 20px;
        min-height: 20px;
        max-width: 20px;
        max-height: 20px;
        box-sizing: border-box;
        font-size: 10px;
        border-radius: 100px;
        font-weight: 600;
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        color: #000;
        border: 1px solid #000;
        background: transparent;
      }
    }
  }
  &_tasks {
    display: flex;
    align-content: center;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;
    padding: 5px 15px 5px 15px;
  }
}

@media only screen and (max-width: 400px) {
  .agent_info {
    overflow: hidden;
    .agent_status {
      span {
        font-size: 11px;
      }
    }
  }
  .agent_info_details_data_row p {
    width: 100px;
  }
  .agent_info_details_data_row p {
    font-size: 12px;
  }
  .agent_info_details_data_row_title {
    font-size: 10px;
  }
  .agent_info_tasks {
    padding: 8px 15px 5px 15px;
  }
}
@media only screen and (max-width: 330px) {
  .agent_info_details_data_row_title {
    display: none;
  }
  .agent_info_details_data_row p {
    width: 120px;
  }
}