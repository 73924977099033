.forgot_pass {
  width: 600px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  &_verify {
    width: 600px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
  }
}
