.action_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: max-content;
  background: #5C0089;
  cursor: pointer;
  transition: all .3s;
  padding: 5px;
  border-radius: 100px;
  border: 1px solid transparent;
  width: 35px;
  height: 35px;
  line-height: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  &_refresh {
    background: #fff !important;
    box-shadow: none !important;
    border-radius: 100px;
    width: 27px;
    height: 27px;
    padding: 5px;
    border: 1px solid #000;
    transition: all .5s;
    margin-left: 5px;
    & svg {
      font-size: 16px !important;
      color: #000 !important;
      transition: all .3s;
      stroke-width: 2px;
    }
    &:hover {
      transform: scale(1.1) rotate(360deg) !important;
      border: 1px solid #000 !important;
      & svg {
        color: #000 !important;
      }
    }
  }
  &:active, {
    box-shadow: 0 0 0 rgba(0, 0, 0, .5);
    border: 1px solid rgba(0, 0, 0, .1);
  }
  &:hover {
    border: 1px solid #f0b101;
    transition: all .3s;
    transform: scale(1.1);
    background: #f0b101;
    color: #fff;
  }
  &_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    & svg {
      margin-left: 10px !important;
    }
  }
  & svg {
    font-size: 20px;
    color: #fff;
    cursor: pointer;
  }
  & button {
    background: transparent;
    border: none;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 10px;
  }
  &--lg {
    font-size: 18px !important;
  }
  &--sm {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1024px) {
  .action_btn {
    margin-left: 5px;
  }
}