.update_status_form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  padding: 20px 15px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  z-index: 7;
  .form_header {
    padding: 15px 0;
  }
  &_loading {
    width: 60px;
    height: 60px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-self: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 8;
    background: #fff;
    border-radius: 6px;
  }
  &_col {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
    .drop_down {
      width: 400px;
    }
    &--fit {
      .drop_down {
        width: 100%;
      }
    }
  }
  &_in {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    &_custom_dropdown_menu {
      width: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .3s;
      & ul {
        display: flex;
        width: 100%;
        & li {
          display: flex;
          align-items: center !important;
          justify-content: center;
        }
      }
      &__descrip {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 12px;
        font-weight: 600;
        margin-left: 5px;
        padding: 5px 0;
        width: 100%;
        flex-wrap: wrap;
      }
      &__tasks {
        font-size: 12px;
        margin-left: auto;
        &_total {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 12px;
          font-weight: 600;
          margin: 0 10px 0 5px;
        }
      }
      &__status {
        text-transform: uppercase;
        border-radius: 100px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600 !important;
        font-size: 7px;
        padding: 1px 3px;
        width: 30px;
        height: 22px;
        margin-left: auto;
        margin-right: 0;
        &--online {
          background: green;
          color: #fff;
        }
        &--offline {
          background: #f0b101;
          color: #fff;
        }
      }
      & svg {
        font-size: 16px;
        color: #5c0089;
        transition: all .3s;
        margin-right: 10px;
      }
      &__icon {
        margin-top: 4px;
        & svg {
          font-size: 16px;
          color: #5c0089;
          transition: all .3s;
        }
      }
    }
    &_col {
      display: flex;
      width: 100%;
      align-items: center;
    }
    &_data_message {
      width: 100%;
      display: inline;
      text-align: end;
      padding: 5px 10px;
      & p {
        font-size: 12px !important;
        font-weight: 500 !important;
        width: 100% !important;
        color: red !important;
      }
      &--success {
        & p {
          color: green;
        }
      }
    }
    &_actions {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      &_cancel {
        display: flex;
        height: 100%;
        line-height: 30px;
        font-size: 14px;
        margin-right: 14px;
        text-decoration: none;
        text-transform: lowercase;
        transition: all .3s;
        color: #444;
        background: none;
        border: none;
        cursor: pointer;
        margin-bottom: 20px;
        &:hover {
          color: #5c0089;
        }
      }
      &_submit {
        margin-bottom: 20px;
        background: #5C0089;
        color: #fff;
        border: 1px solid #5C0089;
        font-size: 14px;
        text-transform: lowercase;
        padding: 7px 10px;
        transition: all .3s ease;
        border-radius: 18px;
        height: 35px;
        cursor: pointer;
        width: 100%;
        &--pending {
          .loading-circle {
            padding: 0;
            height: 100%;
          }
          background: #fff;
          color: #000;
          border: 1px solid #A39F9F;
        }
        &:hover {
          background: #f0b101;
          color: #fff;
          border: 1px solid #f0b101;
        }
      }
    }
  }
  &_status {
    &--started, &--assigned, &--accepted, &--delivered, &--failed, &--canceled, &--picked_up, &--on_way {
      height: 24px !important;
      color: #fff;
      padding: 0 10px;
      text-transform: lowercase;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid transparent;
      border-radius: 6px;
      font-weight: 300;
      & span {
        font-size: 10px;
        margin-left: 5px;
      }
    }
    &--unassigned {
      background: rgba(128, 128, 128, 1);
      color: #fff !important;
      border: 1px solid rgba(128, 128, 128, 1);
    }
    &--created {
      background: rgba(128, 128, 128, 1);
      border: 1px solid rgba(128, 128, 128, 1);
      color: #fff !important;
    }
    &--assigned{
      background: #f0b101;
      color: #fff !important;
      border: 1px solid #f0b101;
    }
    &--accepted {
      background: darkorange;
      color: #fff !important;
      border: 1px solid darkorange;
    }
    &--started {
      background: rgba(92, 0, 137, 1);
      border: 1px solid rgba(92, 0, 137, 1);
      color: #fff !important;
    }
    &--picked_up {
      background: rgba(0, 0, 139, 1);
      border: 1px solid rgba(0, 0, 139, 1);
      color: #fff !important;
    }
    &--on_way {
      background: rgb(0, 0, 255);
      border: 1px solid rgba(0, 0, 255, 1);
      color: #fff !important;
    }
    &--delivered {
      background: rgba(0, 128, 0, 1);
      border: 1px solid rgba(0, 128, 0, 1);
      color: #fff !important;
    }
    &--canceled, &--failed {
      background: rgba(255, 0, 0, 1);
      color: #fff !important;
      border: 1px solid rgba(255, 0, 0, 1);
    }
  }
}

@media only screen and (max-width: 800px) {
  .update_status_form {
    width: 80%;
  }
}
@media only screen and (max-width: 600px) {
  .update_status_form__submit_btn {
    font-size: 12px;
  }
  .update_status_form__cancel_btn {
    font-size: 12px;
  }
}
@media only screen and (max-width: 400px) {
  .update_status_form {
    width: 95%;
  }
}