.account {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #f1f3f7;
  &_in {
    display: flex;
    align-items: center;
    width: 100%;
    height: calc(100% - 70px);
  }
  &_menu {
    width: 300px;
    height: 100%;;
    background: #f1f3f7;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    & ul {
      width: 100%;
      background: #f1f3f7;
      margin-bottom: 20px;
    }
    &_list {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      background: #f1f3f7;
      margin-top: 4px;
      & a {
        text-decoration: none !important;
        padding: 10px 0;
        width: 100%;
        height: 100%;
        display: inline-flex;
        align-items: center;
        text-align: start;
        background: #f1f3f7;
        transition: all .3s;
        &:hover, &:active, &:focus {
          background: rgba(92, 0, 137, 0.1);
          border-radius: 6px;
          color: #5c0089;
          outline: none;
          svg {
            color: #5c0089;
          }
        }
        span {
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          color: #000;
        }
        & svg {
          font-size: 19px;
          color: #000;
          margin: 0 10px;
          display: flex;
          align-items: center;
          transition: all .3s;
        }
      }
      &--active {
        a {
          background: rgba(92, 0, 137, 0.1);
          border-radius: 6px;
          outline: none;
          span {
            color: #5c0089;
          }
          svg {
            color: #5c0089;
          }
        }
      }
    }

  }
  &_main {
    width: calc(100% - 300px);
    height: calc(100% - 20px);
    margin-bottom: 20px;
    background-color: #fff;
    box-shadow: 0 20px 30px -18px rgba(4, 15, 21, 0.1);
    border-radius: 16px;
    -webkit-backdrop-filter: blur(20px);
    padding: 15px 30px 0 30px;
    overflow-y: auto;
  }
}
.billing {
  width: 70%;
  &_margin {
    margin-top: 30px;
    .billing_modal_in {
      padding: 10px 0;
    }
  }
  &_nav {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #e2e3e5;
    &_item {
      cursor: pointer;
      margin-bottom: 20px;
      min-width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      .menu_tab_container:nth-child(1) {
        margin-left: 0;
      }
    }
  }
  &_wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #e2e3e5;
    margin-bottom: 10px;
    padding-bottom: 10px;
    &_in {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      width: 100%;
    }
  }
  &_account {
    width: 100%;
    p {
      padding: 4px 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 13px;
      font-weight: 300;
      color: #000;
      span {
        margin-right: 5px;
        width: 120px;
        font-size: 12px;
        font-weight: 300;
        color: #000;
      }
    }
  }
  &_access {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(#e2e3e5, .5);
    border-radius: 16px;
    border: 1px solid #A39F9F;
    &_p {
      padding: 7px 14px;
      font-size: 13px;
      font-weight: 300;
      text-align: center;
      line-height: 22px;
    }
  }
  &_no_data {
    font-size: 14px;
    font-weight: 300;
    color: #000;
    margin-top: 10px;
    margin-bottom: 25px;
  }
  &_plan {
    margin-bottom: 5px;
    &_btn {
      border: 1px solid #5c0089;
      color: #fff;
      transition: all .3s;
      font-size: 14px;
      font-weight: 400;
      padding: 8px 0;
      cursor: pointer;
      box-sizing: border-box;
      width: 154px;
      background: #5c0089;
      border-radius: 26px;
      margin-left: 15px;
      text-transform: lowercase;
      margin-right: auto !important;
      &:hover {
        color: #fff;
        background: #f0b101;
        border: 1px solid #f0b101;
      }
    }
    &_status {
      height: 24px !important;
      color: #fff;
      padding: 0 10px;
      text-transform: lowercase;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      margin-top: 0;
      font-size: 12px !important;
      transition: all .3s ease;
      &--plan {
        background: #5C0089;
        border: 1px solid #5C0089;
        color: #fff !important;
        cursor: pointer;
        &:hover {
          background: #f0b101;
          border: 1px solid #f0b101;
          color: #fff !important;
        }
      }
      &--active {
        background: rgba(0, 128, 0, 1);
        border: 1px solid rgba(0, 128, 0, 1);
        color: #fff !important;
        cursor: default;
        &:hover {
          background: rgba(0, 128, 0, 1);
          border: 1px solid rgba(0, 128, 0, 1);
          color: #fff !important;
        }
      }
      &--past_due {
        background: rgb(128, 0, 0);
        border: 1px solid rgb(128, 0, 34);
        color: #fff !important;
        cursor: default;
        &:hover {
          background: rgb(128, 0, 30);
          border: 1px solid rgb(128, 0, 30);
          color: #fff !important;
        }
      }
    }
  }
  &_card {

  }
  &_payment {
    margin-bottom: 20px;
    &_in {
      max-height: 500px;
      overflow-y: auto;
      &_file {
        width: 100%;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        color: #000;
        transition: all .3s ease;
        font-size: 13px !important;
        font-weight: 300 !important;
        text-transform: lowercase;
        svg {
          font-size: 20px;
          color: #000;
          transition: all .3s ease;
          margin-right: 5px !important;
        }
        &:hover {
          color: #5c0089;
          svg {
            color: #5c0089;
          }
        }
      }
    }
    &_search {
      margin-bottom: 10px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      border-radius: 16px;
      background: #f1f3f7;
      .search_input {
        width: 100%;
      }
    }
  }
  .page_header p {
    width: unset !important;
  }
  .table_header tr {
    border-radius: 0 !important;
    box-shadow: none;
  }
  .table_header {
    height: 50px !important;
  }
  .table_body td p {
    min-height: 60px !important;
  }
  .table_body tr {
    background: #f1f3f7 !important;
    border-bottom:  1px solid #e2e3e5 !important;
  }
}

@media only screen and (max-width: 1024px) {
  .account_menu ul {
    margin-bottom: 0;
  }
  .account_menu {
    width: 40px;
    margin-right: 10px;
  }
  .account_main {
    width: calc(100% - 40px);
  }
  .account_menu_list a span {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .account_main {
    padding: 15px 15px 0 15px;
  }
}