.footer {
  position: fixed;
  width: 100%;
  height: 30px;
  //display: flex;
  display: none;
  bottom: 0;
  background-color: #000;
  align-items: center;
  justify-content: center;
  z-index: 7;
  & p {
    margin-right: 5px;
  }
  & p, & span  {
    font-size: 1px;
    font-weight: 500;
    color: #999;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
  }
  & span {
    padding: 0 5px 0 1px;
  }
}
