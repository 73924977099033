.delete_branch_form {
  width: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 15px;
  padding: 0 25px 30px 25px;
  z-index: 8;
  &_title {
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-bottom: 25px;
    color: #444444;
  }
  &_btn {
    &_submit {
      background: #5C0089;
      color: #fff;
      border: 1px solid #5C0089;
      font-size: 13px;
      font-weight: 400;
      text-transform: lowercase;
      padding: 7px 14px;
      transition: all .3s ease;
      border-radius: 18px;
      height: 35px;
      cursor: pointer;
      margin-left: auto;
      &--freeze {
        background: red;
        border: 1px solid red;
      }
      &--unfreeze {
        background: green;
        border: 1px solid green;
      }
      &:hover {
        background: #f0b101;
        color: #fff;
        border: 1px solid #f0b101;
      }
    }
    &_cancel {
      display: flex;
      margin-right: auto;
      font-size: 13px;
      font-weight: 400;
      text-decoration: none;
      transition: all .3s;
      color: #000;
      background: none;
      border: none;
      cursor: pointer;
      text-transform: lowercase;
      &:hover {
        color: #5c0089;
      }
    }
  }
  &__error {
    color: red;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 13px;
    font-weight: 600;
  }
  .form {
    width: 100%;
  }
}

@media only screen and (max-width: 800px) {
  .delete_branch_form {
    width: 95%;
    padding: 15px 20px;
  }
}