.create_agent {
  width: 750px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  z-index: 7;
  &_in {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    &_body {
      padding: 0 25px;
      width: 100%;
      height: calc(100% - 50px - 50px);
      overflow-y: auto;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
    }
    &_actions {
      width: 100%;
      height: 60px;
      padding: 0 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &_switcher {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      box-sizing: border-box;
      margin-right: 10px;
      border: none;
      margin-top: 15px;
      width: 100%;
      padding: 10px 0 0 0;
      &_icon {
        border-radius: 100px;
        background: #f1f3f7;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        transition: all .3s ease;
        cursor: pointer;
        margin-right: 15px;
        &:hover {
          background: #5C0089;
          svg {
            color: #fff !important;
          }
        }
        &--selected {
          background: #5C0089;
          svg {
            color: #fff !important;
          }
        }
        svg {
          font-size: 24px;
          color: #000;
        }
      }
      label {
        text-align: left;
        position: relative;
        margin-right: auto;
        width: 100%;
        font-style: normal;
        font-size: 12px;
        padding-left: 2px;
        color: #000000;
        font-weight: 400;
        margin-bottom: 7px;
      }
      .tgl span svg {
        font-size: 20px;
      }
      .tgl input[type="checkbox"]:not(:checked) + span {
        border: 1px solid #A39F9F;
        background: #fff;
      }
      .tgl input[type="checkbox"]:checked + span {
        border: 1px solid #A39F9F;
        background: #fff;
      }
    }
    &_data_message {
      width: 100%;
      display: inline;
      text-align: end;
      padding: 5px 10px;
      & p {
        font-size: 12px !important;
        font-weight: 500 !important;
        width: 100% !important;
        color: red !important;
      }
      &--success {
        & p {
          color: green;
        }
      }
    }
  }
  &__cancel_btn {
    display: flex;
    height: 100%;
    margin-right: auto;
    font-size: 13px;
    text-decoration: none;
    transition: all .3s;
    color: #444;
    background: none;
    border: none;
    cursor: pointer;
    text-transform: lowercase;
    &:hover {
      color: #5c0089;
    }
  }
  &__submit_btn {
    background: #5C0089;
    color: #fff;
    border: 1px solid #5C0089;
    font-size: 13px;
    font-weight: 400;
    text-transform: lowercase;
    margin-left: auto;
    padding: 8px 14px;
    transition: all .3s ease;
    border-radius: 18px;
    height: 35px;
    cursor: pointer;
    width: 250px;
    &--pending {
      width: 250px;
      background: #f0b101 !important;
      border: 1px solid #f0b101;
      .loading-circle {
        padding: 0;
        height: 100%;
      }
    }
    &:hover, &:active, &:focus {
      background: #f0b101 !important;
      color: #fff;
      border: 1px solid #f0b101;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .create_agent {
    padding: 0 15px;
  }
  .create_agent_in_body {
    padding: 0 10px;
  }
  .create_agent_in_actions {
    padding: 0 10px;
  }
}
@media only screen and (max-width: 800px) {
  .create_agent {
    padding: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    align-items: flex-start;
  }
  .create_agent_in {
    height: 100%;
    padding-bottom: 0;
    padding-top: 0;
    .form {
      height: 100%;
    }
  }
}
@media only screen and (max-width: 600px) {
  .create_agent_in_actions {
    padding: 0 15px;
  }
  .create_agent__submit_btn {
    width: 100%;
  }
}