.tgl {
  position: relative;
  outline: 0;
  display: inline-block;
  cursor: pointer;
  user-select: none;
  width: 100px;
  font-size: 21px;;

  // add default box-sizing for this scope
  &, &:after, &:before,
  & *, & *:after, & *:before,
  & + .tgl-btn {
    box-sizing: border-box;
    &::selection{
      background: none;
    }
  }

  span {
    position: relative;
    display: block;
    height: 35px;
    line-height: 1.2em;
    overflow: hidden;
    font-weight: normal;
    text-align: center;
    border-radius: 2em;
    padding: .5em 1em;
    border: 1px solid #000;
    //box-shadow:
    //        inset 0 2px 0 rgba(0,0,0,.2),
    //        0 2px 0 rgba(255,255,255,0.7);
    transition:
            color 0.3s ease,
            padding 0.3s ease-in-out,
            border 0.3s ease,
            background 0.3s ease-in-out;
    &:hover {
      border: 1px solid #5C0089;
    }
    &:before {
      position: relative;
      display: block;
      line-height: 1.3em;
      padding: 0 0.2em;
      font-size: 1em;
    }
    &:after {
      position: absolute;
      display: block;
      content: '';
      border-radius: 2em;
      width: 32px;
      height: 32px;
      margin: 0 -32.5px;
      top: 50%;
      transform: translateY(-50%);
      background: #fff;
      transition:
              left 0.4s cubic-bezier( 0.2, 0.7, 0.4, 0.97 ),
              background 0.4s ease-in-out;

    }
    svg {
      color: #777;
    }
  }
  input[type="checkbox"]{
    display: none !important;

    // Off
    &:not(:checked) {
      + span {
        background: #f1f3f7;
        padding-left: 1.6em;
        padding-right: 0.4em;
        display: flex;
        align-items: center;
        justify-content: center;
        &:before {
          content: "";
        }
        &:after {
          background: #fff;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          left: 33px;
        }
      }
    }

    // On
    &:checked {
      + span {
        background: #f1f3f7;
        padding-left: 0.4em;
        padding-right: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
        &:before {
          content: "";
        }
        &:after {
          background: #fff;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          left: 100%;
        }
      }
    }
  }
}