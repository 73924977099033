.agent_money {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #000;
  margin-right: auto;
  text-transform: lowercase;
  & span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    font-weight: 400;
    font-size: 13px;
    color: #000;
  }
  &_tooltip {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    li {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 5px;
      width: 100%;
      padding: 3px 0;
      font-size: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        font-size: 10px;
        color: #fff;
        margin-right: 5px;
      }
    }
    &_count {
      font-size: 10px;
      color: #fff;
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
@media only screen and (max-width: 400px) {
  .agent_money {
    font-size: 12px;
    span {
      font-size: 12px;
    }
  }
  .agent_info_money {
    padding: 0 20px 5px 20px;
  }
}