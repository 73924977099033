.billing_credit_card {
  width: calc(50% - 10px);
  min-height: 200px;
  margin-right: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  padding: 10px 25px 20px 25px;
  &:nth-child(2n) {
    margin-right: 0;
  }
  img {
    width: 100px;
    height: 100px;
  }
  &_name {
    width: 100%;
    padding-top: 38px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    text-transform: uppercase;
    padding-bottom: 45px;
  }
  &_in {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 15px;
    &_btn {
      border: none;
      background: none;
      cursor: pointer;
      color: #007aff;
      transition: all .3s ease;
      font-weight: 400;
      font-size: 13px;
      margin-right: 5px;
      text-align: left;
      &--label {
        margin-right: 0 !important;
        color: #5C0089;
        background: rgba(#5C0089, 0.1);
        border-radius: 6px;
        padding: 5px 15px;
        cursor: default;
        &:hover {
          color: #5C0089;
        }
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        color: #5C0089;
      }
    }
  }
  &_info {
    width: 100%;
    margin-top: auto;
    &_num {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 20px;
      font-weight: 300;
      color: #666;
    }
  }
}

@media only screen and (max-width: 600px) {
  .billing_credit_card {
    width: 100%;
    min-height: 200px;
    margin-right: 0;
    padding: 10px 25px 0 25px;
  }
  .billing_credit_card_info_num {
    font-size: 16px;
  }
}