.tracking_link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  background: #f8faff;
  &_map {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .map {
      height: 100%;
      top: 0;
    }
    &_header, &_footer {
      position: absolute;
      z-index: 3;
      background: transparent;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &_header {
      top: 0;
      left: 0;
      padding: 10px 5px 30px 10px;
      display: flex;
      align-items: center;
      background: rgb(255,255,255);
      background: linear-gradient(180deg, rgba(250,250,250,.6) 70%, rgba(250,250,250,0) 100%);
      &_img {
        display: flex;
        box-shadow: 0px 0px 15px -4px rgba(0,0,0,0.2);
        align-items: center;
        justify-content: center;
        padding: 4px 5px 4px 10px;
        border-radius: 100px;
        margin-right: auto;
        cursor: pointer;
        background: rgba(#fff, 1);
        span {
          font-size: 10px;
          color: #000;
          font-weight: 300;
          margin-right: 5px;
        }
        & img {
          width: 90px;
          height: 100%;
          object-fit: contain;
        }
      }
      & p {
        font-size: 13px;
        color: #000;
        font-weight: 400;
        text-transform: lowercase;
      }
      &_refresh {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        & svg {
          font-size: 28px;
          color: #5c0089 !important;
          cursor: pointer;
          transition: all .3s;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
    &_footer {
      //bottom: 0;
      right: 0;
      width: 400px;
      padding: 0;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      background: rgba(#fff, 0.95);
      min-height: 200px;
      bottom: -180px;
      &--animate-up {
        animation: trackingLinkAnimationUp .3s;
        bottom: 0;
        min-height: 200px;
      }
      &--animate-down {
        animation: trackingLinkAnimationDown .6s;
        min-height: 200px;
        bottom: -180px;
      }
      &_main {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        min-height: 100px;
      }
      &_arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 5px 0;
        svg {
          font-size: 22px;
          color: #999;
        }
      }
      & img {
        align-items: center;
        align-self: center;
        margin-left: 10px;
        border: 2px solid rgba(92,0,137,.5);
        border-radius: 100px;
        display: flex;
        justify-content: center;
        object-fit: cover;
        box-sizing: border-box;
        z-index: 4;
        max-width: 65px;
        max-height: 65px;
        min-width: 65px;
        min-height: 65px;
        margin-bottom: 5px;
      }
      &_title {
        width: 100%;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        padding: 10px 10px 5px 10px;
      }
      &_car {
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 300;
        font-size: 12px;
        text-transform: capitalize;
        margin-bottom: 10px;
        padding: 0 10px;
        span {
          margin-left: 5px;
          background: #e7e8ec;
          color: #000;
          font-weight: 300;
          font-size: 12px;
          padding: 3px 8px;
          border-radius: 6px;
        }
        svg {
          font-size: 16px;
          margin-right: 5px;
        }
      }
      &_driver {
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        padding: 0 10px;
        &_name {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-weight: 300;
          font-size: 13px;
          text-transform: capitalize;
        }
        &_actions {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-left: 5px;
          a {
            text-decoration: none;
            color: #000;
            transition: all .3s ease;
            cursor: pointer;
            margin-right: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:last-child {
              margin-right: 0;
            }
            &:hover {
              color: #5C0089;
              svg {
                color: #5C0089;
              }
            }
            svg {
              transition: all .3s ease;
              font-size: 18px;
              color: #444;
            }
          }
        }
      }
      &_order {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        padding: 0 0 10px 0;
        &_in {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          margin-bottom: 5px;
          border-bottom: 1px solid #e7e8ec;
          padding: 0 10px 10px 10px;
          &:last-child {
            border-bottom: none;
          }
          &_icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            &--pin {
              svg {
                font-size: 20px;
                color: #5C0089;
              }
            }
            &--money {
              svg {
                font-size: 20px;
                color: #f0b101;
                path {
                  stroke: #f0b101;
                }
              }
            }
          }
          &_details {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            span {
              width: 100%;
              text-align: left;
              overflow-wrap: anywhere;
              font-size: 13px;
              color: #999;
              font-weight: 300;
              padding-bottom: 2px;
            }
            p {
              width: 100%;
              text-align: left;
              overflow-wrap: anywhere;
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
        &_actions {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          a {
            text-decoration: none;
            color: #000;
            transition: all .3s ease;
            cursor: pointer;
            margin-right: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #e7e8ec;
            border-radius: 100px;
            padding: 8px;
            &:last-child {
              margin-right: 0;
            }
            &:hover {
              color: #5C0089;
              svg {
                color: #5C0089;
              }
            }
            svg {
              transition: all .3s ease;
              font-size: 25px;
              color: #444;
            }
          }
        }
      }
    }
    &_location {
      font-size: 14px;
      color: #000;
      font-weight: 400;
      width: 100%;
      text-align: center;
      padding: 0 10px;
      line-height: 25px;
    }
  }
  &_rating {
    width: 50%;
    min-height: 25%;
    position: fixed;
    z-index: 4;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 20px 0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &_success {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      padding: 15px 10px;
      border-bottom: 1px solid rgba(#999, 0.2);
      margin-bottom: 10px;
      svg {
        color: green;
        font-size: 20px;
        padding-right: 5px;
      }
    }
    &_success {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      padding: 15px 10px;
      border-bottom: 1px solid rgba(#999, 0.2);
      margin-bottom: 10px;
      svg {
        color: green;
        font-size: 25px !important;
        padding-right: 10px;
      }
      &--end {
        font-size: 18px;
        border-bottom: none;
        svg {
          font-size: 30px !important;
        }
      }
    }
    &_img {
      border-radius: 100px;
      border: 2px solid rgba(92, 0, 137, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 10px auto;
      max-width: 65px;
      max-height: 65px;
      min-width: 65px;
      min-height: 65px;
      overflow: hidden;
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-position: center;
      }
    }
    &_logo {
      width: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-position: center;
      }
    }
    &_label {
      text-align: center;
      width: 100%;
      font-size: 18px;
      color: #000;
      font-weight: 300;
      margin-bottom: 25px;
    }
    &_in {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    &_stars {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .3s;
      margin-right: 15px;
      margin-bottom: 20px;
      &:last-child {
        margin-right: 0;
      }
      & svg {
        font-size: 50px;
        color: #dadada;
        cursor: pointer;
        transition: all .3s;
        margin: 0 5px;
      }
      &--active {
        & svg {
          color: #f0b101;
          transition: all .3s;
        }
      }
    }
    &_review {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      & textarea {
        display: block;
        transition: all .3s ease;
        box-sizing: border-box;
        width: calc(100% - 20px);
        padding: 5px 10px;
        line-height: 22px;
        border: 1px solid rgba(#999, 0.6);
        background: transparent;
        border-radius: 5px;
        height: 100px;
        font-weight: 300;
        font-size: 14px;
        color: #000;
        margin: 10px 0 20px 0;
        &:focus, &:hover, &:active {
          border: 1px solid #5C0089;
        }
        &::placeholder {
          color: #bbb6b6;
          font-weight: 200;
          font-size: 13px;
        }
      }
    }
    button {
      border: 1px solid #5C0089;
      color: #fff;
      transition: all .3s;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: .055em;
      padding: 15px 12px;
      cursor: pointer;
      box-sizing: border-box;
      width: 154px;
      background: #5C0089;
      border-radius: 26px;
      margin: 0 auto 30px auto;
      &:hover {
        transform: translateY(-2px);
        background: #f0b101;
        color: #fff;
        border: 1px solid #f0b101;
      }
    }
    &_overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(250, 250, 250, 0.02); /* Adjust the color and opacity as needed */
      backdrop-filter: blur(2px); /* Adjust the blur radius as needed */
    }
  }
  &_loading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    background: #f8faff;
  }
  &__marker_time {
    padding: 6px;
    font-size: 11px;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@keyframes trackingLinkAnimationUp {
  0% {
    min-height: 200px;
    bottom: -180px;
  }
  100% {
    min-height: 200px;
    bottom: 0;
  }
}
@keyframes trackingLinkAnimationDown {
  0% {
    min-height: 200px;
    bottom: 0;
  }
  40% {
    bottom: -280px;
  }
  100% {
    min-height: 200px;
    bottom: -180px;
  }
}

@media only screen and (max-width: 1024px) {
  .tracking_link_rating {
    width: calc(100% - 20px);
    padding: 0 0;
  }
  .tracking_link_rating_stars {
    margin-right: 5px;
    svg {
      font-size: 35px;
    }
  }
  .tracking_link_rating_review input {
    width: 80%;
  }
}
@media only screen and (max-width: 600px) {
  .tracking_link_map_footer_title {
    padding-top: 0;
  }
  .tracking_link_rating_label {
    margin-bottom: 15px;
  }
  .tracking_link_map_footer {
    left: 50%;
    transform: translateX(-50%);
    width: 97%;
    right: unset;
  }
  .tracking_link_map_footer_car {
    font-size: 14px;
  }
  .tracking_link_rating_review input {
    width: 95%;
    margin-top: 0;
  }
  .tracking_link_rating_label {
    font-size: 14px;
    font-weight: 400;
  }
}
