.create_branch_form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  z-index: 8;
  background: #fff;
  padding: 20px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 700px;
  .form_header {
    padding: 0;
  }
  &_submit_btn {
    background: #5C0089;
    color: #fff;
    border: 1px solid #5C0089;
    font-size: 12px;
    text-transform: lowercase;
    padding: 7px 10px;
    transition: all .3s ease;
    border-radius: 18px;
    height: 35px;
    cursor: pointer;
    margin-top: 20px;
    width: 100%;
    &--pending {
      background: #f0b101 !important;
      border: 1px solid #f0b101;
      .loading-circle {
        padding: 0;
        height: 100%;
      }
    }
    &:hover, &:active, &:focus {
      background: #f0b101 !important;
      color: #fff;
      border: 1px solid #f0b101;
    }
  }
  &_cancel_btn {
    display: flex;
    height: 100%;
    margin-top: 20px;
    margin-right: auto;
    font-size: 13px;
    text-decoration: none;
    transition: all .3s;
    color: #444;
    background: none;
    border: none;
    cursor: pointer;
    text-transform: lowercase;
    &:hover {
      color: #5c0089;
    }
  }
  &__error {
    font-size: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: red;
    font-weight: 600;
  }
  .form {
    width: 100%;
  }
}

@media only screen and (max-width: 800px) {
  .create_branch_form {
    width: 95%;
    padding: 15px 20px;
  }
}