.settings_integrations_maps {
  width: 100%;
  height: 100%;
  &_in {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    &_left {
      width: 60%;
      &_descrip {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin-top: 20px;
        p {
          font-size: 14px;
          color: #000;
          font-weight: 300;
          text-align: left;
          line-height: 22px;
          span {
            cursor: pointer;
            margin-left: 6px;
            text-decoration: none;
            font-size: 14px;
            color: #6E8AEF;
            font-weight: 400;
            text-align: justify;
            transition: all .3s ease;
            &:hover {
              color: #5c0089;
            }
          }
        }
      }
    }
    &_right {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 50px;
      &_img {
        border: 1px solid #000;
        overflow: hidden;
        border-radius: 16px;
        img {
          width: 100%;
          height: auto;
          background-position: center;
          background-size: cover;
          object-position: center;
          object-fit: fill;
        }
      }
    }
    &_drop_down {
      width: 100%;
      margin-top: 20px;
      &:first-child {
        margin-top: 0;
      }
      .drop_down_search input {
        width: 100%;
        text-transform: unset;
      }
    }
    &_list {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      &_item {
        width: calc(50% - 5px);
        margin-right: 10px;
        list-style: none;
        border: 1px solid #999;
        padding: 10px 15px;
        cursor: pointer;
        transition: all .3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 12px;
        margin-bottom: 10px;
        &:nth-child(2n) {
          margin-right: 0 !important;
        }
        &--active {
          border: 1px solid #5C0089;
          background: rgba(#5C0089, 0.05);
        }
        &:hover {
          border: 1px solid #5C0089;
          background: rgba(#5C0089, 0.05);
        }
        img {
          width: 150px;
          height: 50px;
          background-size: cover;
          background-position: center;
          object-fit: contain;
        }
        h3 {
          font-size: 16px;
          font-weight: 400;
          color: #000;
          width: 100%;
          text-align: left;
          margin-top: 10px;
          text-transform: capitalize;
        }
        a {
          text-decoration: none;
          font-size: 12px;
          font-weight: 300;
          color: #999;
          text-align: left;
          margin-top: 5px;
          margin-right: auto;
          cursor: pointer;
          transition: all .3s ease;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          svg {
            color: #999;
            cursor: pointer;
            transition: all .3s ease;
          }
          &:hover {
            color: #007aff;
            svg {
              color: #007aff;
            }
          }
        }
      }
    }
    &_options {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &_loading {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    }
    &_form {
      width: 100%;
      .form_header {
        display: none;
      }
      .form_col {
        position: relative;
      }
      .create_order_submit_btn {
        width: 150px;
        margin: 20px auto 20px auto;
      }
      &_success {
        font-size: 12px;
        font-weight: 600;
        color: green;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
      }
      &_error {
        font-size: 12px;
        font-weight: 600;
        color: red;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
      }
      &__btn {
        background: #5C0089 !important;
        color: #fff;
        border: 1px solid #5C0089;
        font-size: 12px;
        text-transform: lowercase;
        padding: 8px 14px;
        transition: all .3s ease;
        border-radius: 18px;
        height: 35px;
        cursor: pointer;
        margin: 0 auto;
        &:hover, &:active, &:focus {
          background: #f0b101 !important;
          color: #fff;
          border: 1px solid #f0b101;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .settings_integrations_maps_in_right {
    display: none;
  }
  .settings_integrations_maps_in_left {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .settings_integrations_maps_in_left_descrip p, .settings_integrations_maps_in_left_descrip p span {
    font-size: 12px;
    text-align: center;
  }
  .settings_integrations_maps_in_list_item {
    margin-right: 0;
    width: 100%;
    &:last-child {
      margin-bottom: 0 !important;
    }
    img {
      width: 100px;
    }
    h3 {
      font-size: 14px;
      margin-top: 0;
    }
  }
}