.marker_overlay {
  padding: 6px;
  font-size: 10px;
  font-weight: 300;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: #FFFFFF;
  transform: translate(-50%, -60px);
  user-select: none;
  box-shadow: 0 5px 10px #9f9f9f;
  z-index: 9999;
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
}
