.account_profile {
  width: 100%;
  height: 100%;
  &_in {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    &_form {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      &_row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 25px;
        &:first-child {
          margin-top: 0;
        }
        &_span {
          transition: all .3s;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: .055em;
          margin-top: 20px;
          padding: 6px 16px;
          box-sizing: border-box;
          width: 200px;
          border-radius: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            margin-right: 5px;
          }
          &--true {
            color: #666 !important;
            font-size: 12px;
            svg {
              color: #666 !important;
            }
          }
          &--false {
            color: red !important;
            font-size: 12px;
            svg {
              color: red !important;
              font-size: 18px;
            }
          }
        }
      }
      &_col {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 0 2px;
        width: 100%;
        label {
          text-align: left;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          font-style: normal;
          font-size: 12px;
          line-height: 15px;
          padding-left: 2px;
          color: #000000;
          font-weight: 400;
          margin: 0 0 7px 0;

          span {
            font-size: 14px;
            color: red;
            height: 12px;
            display: flex;
            align-self: flex-start;
            margin-right: 2px;
          }
        }
        input {
          display: block;
          transition: all .3s ease;
          box-sizing: border-box;
          width: 100%;
          padding: 15px 10px;
          line-height: 15px;
          border: 1px solid #A39F9F;
          background: transparent;
          border-radius: 16px;
          height: 35px;
          font-weight: 300;
          font-size: 13px;
          color: #000;
          &:focus, &:hover, &:active {
            border: 1px solid #5C0089;
          }
          &::placeholder {
            color: #bbb6b6;
            font-weight: 200;
            font-size: 13px;
          }
          &:disabled {
            background: #e2e3e5;
            color: #666 !important;
            border: 1px solid #666 !important;
          }
        }
      }
      &_left {
        width: 60%;
        &--full {
          width: 100%;
          .account_profile_in_form_col {
            width: 60% !important;
          }
        }
        p {
          font-size: 13px;
          color: #000;
          text-align: left;
          font-weight: 400;
          line-height: 22px;
        }
      }
      &_right {
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: auto;
        &_img {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          p {
            font-weight: 400;
            color: #000;
            text-align: center;
            font-size: 12px;
          }
          &_btn {
            color: #147efb;
            font-weight: 400;
            transition: all .3s;
            font-size: 12px;
            letter-spacing: .055em;
            cursor: pointer;
            box-sizing: border-box;
            width: 200px;
            border: none;
            background: transparent;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              margin-left: 5px;
              font-size: 16px;
              color: #147efb;
              transition: all .3s ease;
            }

            &:hover {
              color: #5C0089;

              svg {
                color: #5C0089
              }
            }
          }
          input[type="file"] {
            display: none;
          }
          img {
            width: 150px;
            height: 150px;
            border-radius: 100px;
            background-position: center;
            object-fit: contain;
            object-position: center;
            margin: 10px 0;
          }
        }
      }
      &_btn {
        transition: all .3s;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: .055em;
        padding: 6px 0;
        cursor: pointer;
        box-sizing: border-box;
        border-radius: 26px;
        margin-top: 20px;
        background: none;
        border: none;
        color: #147efb;
        &:hover {
          color: #5C0089;
        }
        &--edit {
          padding: 6px 16px !important;
          width: 200px;
          background: none;
          border: none;
          color: #147efb;
          &:hover {
            color: #5C0089;
          }
        }
        &--delete {
          background: #fff;
          border: none;
          color: #666;
          margin-bottom: 20px;
          padding: 6px 0;
          &:hover {
            color: red;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .account_profile_in_form_left {
    width: 100%;
    order: 2;
    margin-bottom: 20px;
  }
  .account_profile_in_form_right {
    margin: 0 auto;
  }
  .account_profile_in_form_right_img {
    width: 100%;
  }
  .account_profile_in_form_left--full .account_profile_in_form_col {
    width: 100% !important;
  }
  .account_profile_in_form_row_span--true {
    display: none;
  }
  .account_profile_in_form_btn--edit {
    padding: 6px 0 !important;
    width: unset;
  }
  .account_profile_in_form_row_span {
    justify-content: flex-start;
    padding: 0;
  }
}