.driver_app_qr {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  min-height: 500px;
  padding: 20px 40px;
  border-radius: 16px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 8;
  &_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: auto;
    position: relative;
    &_close {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      & svg {
        font-size: 22px;
        cursor: pointer;
        transition: all .3s;
        color: #000;
        &:hover {
          transform: rotate(180deg);
        }
      }
    }
    &_logo {
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
        background-position: center;
        object-position: center;
        object-fit: contain;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
  &_title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 10px;
    p {
      font-size: 14px;
      font-weight: 400;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 0;
      line-height: 100%;
      span {
        margin-right: 5px;
        svg {
          font-size: 20px;
          color: #07bc0c;
        }
      }
    }
  }
  &_img {
    width: 180px;
    height: 180px;
    margin-bottom: 20px;
    img {
      width: 100%;
      height: 100%;
      background-position: center;
      object-position: center;
      object-fit: contain;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  &_description {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin-top: 5px;
  }
  &_app_icons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 10px 0;
    transition: all .3s ease;
    a {
      width: 130px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .3s ease;
      img {
        width: 100%;
        height: 100%;
        background-position: center;
        object-fit: contain;
        transition: all .3s ease;
      }
      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
  &_download_btn {
    border: 1px solid #5C0089;
    color: #fff;
    transition: all .3s;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .055em;
    padding: 7px 14px;
    cursor: pointer;
    box-sizing: border-box;
    width: 154px;
    background: #5C0089;
    border-radius: 26px;
    margin: 10px auto 0 auto;
    &:hover {
      transform: translateY(-2px);
      background: #f0b101;
      color: #fff;
      border: 1px solid #f0b101;
    }
  }
  &_copy_btn {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 15px;
    cursor: pointer;
    transition: all .3s ease;
    &:hover {
      color: #f0b101;
      svg {
        color: #f0b101;
      }
    }
    svg {
      margin-right: 5px;
      transition: all .3s ease;
      font-size: 20px;
      color: #000;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: #000;
    }
  }
}

@media only screen and (max-width: 600px) {
  .driver_app_qr {
    width: 95%;
  }
  .driver_app_qr_title p {
    text-align: center;
    line-height: 22px;
  }
}
@media only screen and (max-width: 400px) {
  .driver_app_qr {
    padding: 20px;
    min-height: auto;
  }
  .driver_app_qr_wrapper_logo {
    width: 150px;
  }
  .driver_app_qr_img {
    width: 150px;
    height: 150px;
  }
  .driver_app_qr_download_btn {
    font-size: 12px;
  }
  .driver_app_qr_wrapper {
    margin-bottom: 20px;
  }
  .driver_app_qr_download_btn {
    margin: 0 auto;
  }
}