.more_popup {
  width: 200px;
  color: black;
  position: absolute;
  background: #fff;
  padding: 2px;
  z-index: 7;
  cursor: pointer;
  border-radius: 16px;
  border: 1px solid #A39F9F;
  box-shadow: 0 0 0 rgba(0, 0, 0, 1);
  overflow: hidden;
  top: 0;
  right: 200px;
  &__item {
    width: 100%;
    font-size: 13px;
    list-style: none;
    cursor: pointer;
    text-align: left;
    font-weight: 300;
    padding: 10px 12px;
    color: #000;
    transition: all .3s ease;
    &:last-child {
      border-bottom: transparent;
    }
    &:hover {
      background: #f1f3f7;
    }
  }
  &__alert {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 300px;
    display: flex;
    padding: 20px 10px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 5px;
    z-index: 7;
    &_title {
      font-size: 14px;
      text-align: center;
      font-weight: 500;
      color: #444;
      margin-bottom: 25px;
      line-height: 20px;
    }
    &_actions {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
    }
    &_btn {
      cursor: pointer;
      transition: all .3s;
      font-size: 13px;
      margin-left: 10px;
      padding: 5px 2px;
      border-radius: 5px;
      &--submit {
        background: #5c0089;
        border: 1px solid #5c0089;
        color: #fff;
        font-weight: 600;
        text-transform: uppercase;
        padding: 5px 10px;
        &:hover {
          background: #fff;
          color: #5c0089;
        }
      }
      &--cancel {
        text-transform: capitalize;
        margin-right: auto;
        background: transparent;
        border: none;
        &:hover {
          color: #5c0089;
        }
      }
    }
  }
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 6;
    &--transparent {
      background: transparent;
    }
    &--dark {
      background: rgba(0, 0, 0, .3);
    }
  }
}
