.create_customer {
  width: 700px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  z-index: 7;
  .form_header {
    padding: 15px 0;
  }
  &_in {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 25px;
    overflow: auto;
    &_data_message {
      width: 100%;
      display: inline;
      text-align: end;
      padding: 5px 10px;
      & p {
        font-size: 12px !important;
        font-weight: 500 !important;
        width: 100% !important;
        color: red !important;
      }
      &--success {
        & p {
          color: green;
        }
      }
    }
  }
  &__cancel_btn {
    display: flex;
    height: 100%;
    font-size: 13px;
    text-decoration: none;
    transition: all .3s;
    color: #444;
    background: none;
    border: none;
    cursor: pointer;
    text-transform: lowercase;
    margin: 20px 0;
    &:hover {
      color: #5c0089;
    }
  }
  &__submit_btn {
    margin: 20px 0;
    background: #5C0089;
    color: #fff;
    border: 1px solid #5C0089;
    font-size: 13px;
    font-weight: 400;
    text-transform: lowercase;
    padding: 8px 14px;
    transition: all .3s ease;
    border-radius: 18px;
    height: 35px;
    cursor: pointer;
    width: 100%;
    &--pending {
      width: 100%;
      background: #f0b101 !important;
      border: 1px solid #f0b101;
      .loading-circle {
        padding: 0;
        height: 100%;
      }
    }
    &:hover, &:active, &:focus {
      background: #f0b101 !important;
      color: #fff;
      border: 1px solid #f0b101;
    }
  }
}

@media only screen and (max-width: 800px) {
  .create_customer {
    width: 95%;
  }
}
@media only screen and (max-width: 600px) {
  .create_customer__submit_btn {
    font-size: 12px;
  }
  .create_customer__cancel_btn {
    font-size: 12px;
  }
}