.orders {
  width: 100%;
  &_date_time_picker {
    width: 100%;
    border-radius: 16px;
    height: 35px;
    border: 1px solid #A39F9F;
    background: #fff;
    transition: all .3s ease;
    &:hover {
      border: 1px solid #5C0089;
    }
    label {
      margin-right: 10px !important;
    }
  }
  &_in {
    background: #fff;
    box-shadow: 0 20px 30px -18px rgba(4, 15, 21, 0.1);
    border-radius: 16px;
    padding: 20px 20px 10px 20px;
    margin-bottom: auto;
    &_actions {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      margin-bottom: 25px;
      border-radius: 16px;
      background: #f1f3f7;
      button {
        border: 1px solid #5C0089;
        color: #fff;
        transition: all .3s;
        font-size: 13px;
        font-weight: 300;
        height: 34px;
        letter-spacing: .055em;
        padding: 4px 16px;
        min-width: 180px;
        cursor: pointer;
        box-sizing: border-box;
        background: #5C0089;
        border-radius: 26px;
        margin-left: 15px;
        text-transform: lowercase;
        &:hover {
          background: #f0b101;
          color: #fff;
          border: 1px solid #f0b101;
        }
      }
      &_calendar {
        min-width: 230px;
        margin-left: 15px;
        .date_time_picker_wrapper {
          margin-top: 13px;
        }
        .date_time_picker {
          background: transparent;
        }
        .orders_date_time_picker {
          border: 1px solid #000 !important;
          transition: all .3s ease;
          &:hover {
            border: 1px solid #5C0089 !important;
          }
        }
        .date_time_picker.orders__date_time_picker {
          height: 40px !important;
          border-radius: 16px;
          background: transparent;
          & input {
            font-size: 16px !important;
            font-weight: 600;
          }
        }
      }
    }
    .date_time_picker.orders__date_time_picker {
      height: 40px !important;
      border-radius: 16px !important;
      background: #fff;
      border: 1px solid #aaa;
      & input {
        font-size: 16px !important;
        font-weight: 600;
      }
    }
  }
  &_popup {
    width: 400px;
    position: fixed;
    background: #f1f3f7;
    top: 50px;
    left: 0;
    z-index: 7;
    padding: 10px 0;
    height: calc(100% - 40px);
    &--animate {
      animation: animate-to-right .5s ease;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .orders_in_actions {
    margin-bottom: 10px;
  }
  .orders_in {
    padding: 10px;
  }
  .orders_popup {
    top: 39px;
    height: calc(100% - 40px);
  }
}
@media only screen and (max-width: 600px) {
  .orders_date_time_picker {
    height: 30px;
  }
  .orders_in_actions {
    flex-wrap: wrap;
  }
  .orders_in_actions_calendar {
    margin-left: auto;
  }
  .date_time_picker_in input {
    font-size: 12px;
  }
  .orders_popup {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .orders_in_actions_calendar {
    margin: 0 auto;
  }
}