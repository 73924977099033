.analytics_info {
  width: 100%;
  display: flex;
  min-height: 100px;
  &_in {
    width: 100%;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    padding: 40px 40px;
    border-radius: 20px;
    &--pending {
      background: rgba(#666, 0.5);
      color: #fff;
    }
    &--ongoing {
      background: rgba(#5c0089, 0.5);
      color: #fff;
    }
    &--finished {
      background: rgba(green, 0.5);
      color: #fff;
    }
    &--canceled {
      background: rgba(red, 0.5);
      color: #fff;
    }
    &--delayed {
      background: rgba(#f0b101, 0.5);
      color: #fff;
    }
  }
  &__count {
    width: 100%;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-items: flex-start;
    height: 40px;
    .loading-circle {
      height: 40px !important;
      justify-content: flex-start !important;
      padding: 0 !important;
    }
    p {
      font-size: 30px;
      font-weight: 400;
      color: #fff;
    }
  }
  &__title {
    font-weight: 400;
    font-size: 14px;
    color: #fff;
  }
}

@media only screen and (max-width: 600px) {
  .analytics_info_in {
    padding: 20px;
  }
  .analytics_info__title {
    font-size: 12px;
  }
  .analytics_info__count p {
    font-size: 26px;
  }
}

