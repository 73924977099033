.order_info {
  width: 100%;
  padding: 10px 20px 0 20px;
  margin-bottom: auto;
  &_title {
    font-size: 14px;
    font-weight: 400;
    color: #000;
    text-transform: capitalize;
    margin: 20px 0;
    &:nth-child(1) {
      margin-top: 0;
    }
  }
  &_in {
    width: 100%;
    &_row {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      border-bottom: 1px solid rgba(0,0,0,.1);
      padding: 0 0 10px 0;
      & svg {
        font-size: 18px;
        color: #000;
        margin-right: 8px;
        margin-top: 10px;
      }
      &_wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
      }
      &__label {
        font-size: 12px;
        font-weight: 300;
        color: #000;
        margin-top: 10px;
      }
      &__link {
        font-size: 13px;
        font-weight: 400;
        color: #000;
        margin-top: 5px;
        margin-left: 27px;
        transition: all .3s ease;
        cursor: pointer;
        text-decoration: none;
        text-transform: lowercase;
        &:hover {
          color: #5C0089;
        }
      }
      &__descrip {
        font-size: 13px;
        font-weight: 400;
        color: #000;
        margin-top: 5px;
        margin-left: 27px;
      }
      &__location {
        font-size: 13px;
        font-weight: 400;
        color: #000;
        margin-top: 5px;
        margin-left: 27px;
        line-height: 20px;
      }
      &__status {
        margin-left: auto;
        margin-top: 5px;
        height: 24px !important;
        color: #fff;
        padding: 0 10px;
        text-transform: lowercase;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        min-width: 80px;
        font-size: 12px;
        &--unassigned {
          background: rgba(128, 128, 128, 1);
          color: #fff !important;
          border: 1px solid rgba(128, 128, 128, 1);
        }
        &--created {
          background: rgba(128, 128, 128, 1);
          border: 1px solid rgba(128, 128, 128, 1);
          color: #fff !important;
        }
        &--assigned{
          background: #f0b101;
          color: #fff !important;
          border: 1px solid #f0b101;
        }
        &--accepted {
          background: darkorange;
          color: #fff !important;
          border: 1px solid darkorange;
        }
        &--started {
          background: rgba(92, 0, 137, 1);
          border: 1px solid rgba(92, 0, 137, 1);
          color: #fff !important;
        }
        &--picked_up {
          background: rgba(0, 0, 139, 1);
          border: 1px solid rgba(0, 0, 139, 1);
          color: #fff !important;
        }
        &--on_way {
          background: rgba(0, 0, 255, 1);
          border: 1px solid rgba(0, 0, 255, 1);
          color: #fff !important;
        }
        &--delivered {
          background: rgba(0, 128, 0, 1);
          border: 1px solid rgba(0, 128, 0, 1);
          color: #fff !important;
        }
        &--canceled, &--failed {
          background: rgba(255, 0, 0, 1);
          color: #fff !important;
          border: 1px solid rgba(255, 0, 0, 1);
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .order_info_in_row__status {
    font-size: 11px;
  }
}