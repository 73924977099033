.dashboard {
  width: 100%;
  height: calc(100% - 60px);
  position: relative;
  &--short {
    height: calc(100% - 115px);
  }
  .date_time_picker.dashboard__date_time_picker {
    height: 40px !important;
    border-radius: 5px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    background: #fff;
    & input {
      font-size: 16px !important;
      font-weight: 600;
    }
    & label {
      display: none;
    }
  }
  &_container {
    width: 100%;
    height: 100%;
    background: #f1f3f7;
    display: flex;
    overflow: hidden;
    &_main {
      display: flex;
      min-width: calc(100% - 800px);
      max-width: calc(100% - 800px);
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      position: relative;
      overflow: hidden;
      border-radius: 16px;
      border: 1px solid #000;
      margin: 10px 0;
      &_wrapper {
        color: #000;
        background: transparent;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 14px;
        z-index: 1;
        &_in {
          background: rgba(#5C0089, .9);
          display: flex;
          align-content: center;
          justify-content: center;
          margin-bottom: auto;
          border: 1px solid #000;
          border-radius: 18px;
          height: 30px;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        }
        &_calendar {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
          &_menu {
            background: #fff !important;
          }
          & svg {
            font-size: 13px;
            margin-right: 8px;
          }
          p {
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
          }
          .date_time_picker_in {
            input {
              color: #fff;
              font-size: 12px;
              order: 1;
              text-indent: initial;
              font-weight: 400;
              border-radius: unset;
              text-align: center;
              padding: 0 10px;
            }
            label {
              order: 0;
              display: none;
              svg {
                color: #fff;
                background: none;
                font-size: 13px;
                margin-right: 0;
              }
            }
          }
        }
        &_branch {
          min-width: 150px;
          border-left: 1px solid rgba(#000, 1);
          .drop_down_filter {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .drop_down_filter_in {
            justify-content: center;
            height: 100%;
          }
          .drop_down_filter__label {
            display: none;
          }
          .drop_down_filter__input {
            font-weight: 400;
            text-align: center;
            height: unset;
            color: #fff;
          }
        }
        .date_time_picker {
          width: initial;
          font-weight: 400;
        }
      }
    }
    &_pages {
      display: none;
      width: 100%;
      height: 50px;
      align-items: center;
      justify-content: space-between;
      background: #fff;
      border-top-left-radius: 22px;
      border-top-right-radius: 22px;
      overflow: hidden;
      &_btn {
        border: none;
        cursor: pointer;
        height: 100%;
        width: calc(100% / 3);
        background: transparent;
        color: #000;
        text-transform: lowercase;
        transition: all .3s;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 15px;
        &:hover {
          color: #5c0089 !important;
          background: rgba(92, 0, 137, 0.1);
          cursor: default;
          box-shadow: 0 0 0 rgba(240, 177, 1, .5);
        }
        &--active {
          color: #5c0089 !important;
          background: rgba(92, 0, 137, 0.1);
          cursor: default;
          box-shadow: 0 0 0 rgba(240, 177, 1, .5);
        }
        svg {
          font-size: 18px;
          margin-bottom: 3px;
        }
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          margin-bottom: 3px;
        }
      }
    }
  }
  &_filter {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-left: auto;
    .action_btn {
      background: rgba(#5C0089, .9);
    }
    &_container {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 50px;
      right: 15px;
      background: #fff;
      width: max-content;
      border-radius: 5px;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
      z-index: 4;
      &_overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0);
        transition: all 0.3s;
        z-index: 3;
      }
      & ul {
        width: 100%;
        padding: 5px 0;
        & li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          list-style: none;
          padding: 7px 14px;
          width: 100%;
          border-bottom: 1px solid #e2e3e5;
          &:last-child {
            border-bottom: none;
          }
          & label {
            font-size: 12px;
            color: #000;
            font-weight: 300;
          }
          & input[type="checkbox"] {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            cursor: pointer;
            display: inline-block;
            width: 15px !important;
            height: 15px !important;
            padding: 1px;
            background-clip: content-box;
            border: 2px solid rgb(200, 200, 200);
            background-color: rgb(200, 200, 200);
            border-radius: 100px;
            margin-left: 20px;
          }
          & input[type="checkbox"]:checked {
            background-color: #5c0089;
          }
        }
      }
    }
  }
  &_actions {
    margin-top: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 14px;
    z-index: 1;
    &_btn {
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: rgba(#5C0089, .9);
      line-height: 100%;
      color: #fff;
      border: none;
      font-size: 13px;
      font-weight: 400;
      text-transform: lowercase;
      padding: 0 10px;
      transition: all .3s ease;
      border-radius: 18px;
      cursor: pointer;
      height: 30px;
      &:hover {
        transform: scale(1.05);
        background: #f0b101;
        color: #fff;
      }
      svg {
        font-size: 18px;
        color: #fff;
        margin-right: 10px;
      }
    }
  }
  &_form {
    padding: 0 20px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 8;
  }
}

@media only screen and (max-width: 1024px) {
  .dashboard {
    height: calc(100% - 80px);
    &--short {
      height: calc(100% - 80px - 55px);
    }
  }
  .dashboard_container {
    flex-direction: column;
  }
  .dashboard_container_pages {
    display: flex;
  }
  .dashboard_container_main {
    min-width: 95%;
    max-width: 85%;
    height: calc(100% - 50px);
    margin: 5px auto 5px auto;
  }
}
@media only screen and (max-width: 400px) {
  .dashboard_container_pages_btn {
    p {
      font-size: 10px;
    }
    svg {
      font-size: 18px;
    }
  }
}
@media only screen and (max-width: 330px) {
  .dashboard_container_main_wrapper_in {
    flex-wrap: wrap;
  }
  .dashboard_container_main_wrapper_branch {
    border: none;
  }
  .dashboard_container_main_wrapper_in {
    height: unset;
    padding: 10px 0 5px 0;
    background: rgba(#5C0089, 0.9);
  }
  .dashboard_container_main_wrapper .date_time_picker {
    margin-bottom: 5px;
  }
  .dashboard_container_main_wrapper_branch .drop_down_filter {
    height: 30px;
  }
}