.update_order {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f1f3f7;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 7;
  .drop_down_search input {
    text-indent: 35px !important;
  }
  &_in {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    &_data_message {
      width: 100%;
      display: inline;
      text-align: end;
      padding: 5px 10px;
      & p {
        font-size: 12px !important;
        font-weight: 500 !important;
        width: 100% !important;
        color: red !important;
      }
    }
    &_calendar {
      width: 100%;
      height: 30px;
      border: 1px solid #A39F9F;
      background: #fff;
      border-radius: 5px;
      & label {
        padding: 0 !important;
        width: max-content !important;
      }
      .date-time-picker__wrapper .date-time-picker__wrapper_calendar .react-calendar__navigation button span {
        font-size: 9px !important;
      }
    }
    &_body {
      padding: 0 25px ;
      width: 100%;
      height: calc(100% - 50px - 70px - 10px);
      overflow-y: auto;
      background: #f1f3f7;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      &_wrapper {
        width: 100%;
        background: #fff;
        margin-bottom: 10px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
        padding: 0 10px 10px 10px;
        border-radius: 16px;
        label {
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
        &_icon {
          margin-left: 5px;
          border-radius: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: all .3s ease;
          &:hover {
            transform: rotate(180deg);
          }
          svg {
            color: #5C0089;
            font-size: 16px;
          }
        }
      }
    }
    &_fixed {
      position: fixed;
      bottom: 0;
      left: 0;
      flex-wrap: wrap;
      z-index: 15;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 60%;
      height: 70px;
      background: #fff;
      padding: 10px 25px 10px 25px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      border-top-left-radius: 22px;
      border-top-right-radius: 22px;
      &_assignBtn {
        font-size: 14px;
        color: #007aff;
        font-weight: 400;
        cursor: pointer;
        transition: all .3s ease;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        p {
          font-size: 14px;
          max-width: 150px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &--close {
          margin-left: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #007aff;
          font-size: 12px;
          font-weight: 300;
          cursor: pointer;
          transition: all .3s ease;
          &:hover {
            color: #f0b101;
          }
        }
        &--active {
          color: #5C0089 !important;
          cursor: default !important;
        }
        &--assigned {
          color: #f0b101 !important;
          cursor: default !important;
        }
      }
    }
  }
  &_date_time_picker {
    width: 100%;
    border-radius: 16px;
    height: 35px;
    border: 1px solid #A39F9F;
    background: #fff;
    transition: all .3s ease;
    &:hover {
      border: 1px solid #5C0089;
    }
    label {
      padding: 0;
    }
  }
  &_show_coords {
    padding-left: 6px;
    cursor: pointer;
    margin-right: 20px;
    margin-top: 3px;
    & svg {
      font-size: 18px;
      color: #666;
      transition: all .3s;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  &_error {
    width: 100%;
    display: flex;
    align-self: center;
    justify-content: flex-end;
    text-align: end;
    padding-top: 2px;
    & p {
      font-size: 12px !important;
      font-weight: 600 !important;
      width: 100% !important;
      color: red !important;
    }
  }
  &_customer_status {
    font-size: 12px !important;
    margin: 0 0 0 7px;
    text-transform: lowercase;
    font-weight: 400;
    &--exist {
      color: #5C0089 !important;
    }
    &--new {
      color: green !important;
    }
  }
  &_map {
    height: 100%;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 900;
    background: #fff;
    position: relative;
    border-left: 1px solid #000;
  }
  &_custom_dropdown_menu {
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s;
    color: #000 !important;
    padding: 5px 5px !important;
    svg {
      width: 20px;
    }
    &_txt {
      width: calc(100% - 20px);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 12px;
      font-weight: 300;
      color: #000;
    }
    &__descrip {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 12px;
      font-weight: 400;
      margin-left: 5px;
      text-transform: lowercase;
      width: 100%;
      flex-wrap: wrap;
    }
    &__tasks {
      font-size: 12px;
      margin-left: auto;
      text-transform: lowercase;
      font-weight: 400;
      &_total {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 12px;
        text-transform: lowercase;
        font-weight: 400;
        margin: 0 10px 0 5px;
      }
    }
    &__status {
      text-transform: uppercase;
      border-radius: 100px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400 !important;
      font-size: 7px;
      padding: 0 3px;
      min-width: 20px;
      min-height: 20px;
      max-width: 20px;
      max-height: 20px;
      margin-left: auto;
      margin-right: 0;
      &--online {
        background: green;
        color: #fff;
      }
      &--offline {
        background: #f0b101;
        color: #fff;
      }
    }
    & svg {
      font-size: 16px;
      color: #999;
      transition: all .3s;
      margin-right: 10px;
    }
    &__icon {
      min-width: 20px;
      min-height: 20px;
      max-width: 20px;
      max-height: 20px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        border-radius: 100px;
        background-position: center;
        object-position: center;
        background-size: cover;
        object-fit: contain;
      }
      & svg {
        font-size: 16px;
        color: #000;
        transition: all .3s;
      }
    }
  }
  &_submit_btn {
    background: #5C0089;
    color: #fff;
    border: 1px solid #5C0089;
    font-size: 13px;
    font-weight: 400;
    text-transform: lowercase;
    padding: 7px 10px;
    transition: all .3s ease;
    border-radius: 18px;
    height: 35px;
    cursor: pointer;
    min-width: 150px;
    margin-left: auto;
    &--pending {
      background: #f0b101 !important;
      border: 1px solid #f0b101;
      .loading-circle {
        padding: 0;
        height: 100%;
      }
    }
    &--round {
      min-width: 30px;
      min-height: 30px;
      max-width: 30px;
      max-height: 30px;
      background: #5C0089;
      transition: all .3s ease;
      cursor: pointer;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color: #fff;
        font-size: 22px;
      }
      &:hover {
        background: #f0b101;
      }
    }
    &:hover, &:active, &:focus {
      background: #f0b101 !important;
      color: #fff;
      border: 1px solid #f0b101;
    }
  }
  &_lat_lng {
    width: 400px !important;
    margin-top: 10px;
    display: flex;
    align-self: center;
    justify-content: space-between;
    &_input {
      background: #e2e3e5 !important;
    }
  }
  &_delivery_address_col {
    display: flex;
    width: 100%;
    align-items: center;
    .drop_down {
      width: 400px;
    }
    &--fit {
      .drop_down {
        width: 100%;
      }
    }
  }
  &__agent_icon {
    padding-left: 6px;
    & svg {
      font-size: 20px;
      color: #999;
      transition: all .3s;
    }
  }
  &_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    transition: all .3s ease;
    box-sizing: border-box;
    padding: 15px 0;
    line-height: 15px;
    border: 1px solid #A39F9F;
    background: transparent;
    border-radius: 16px;
    height: 35px;
    font-weight: 300;
    font-size: 13px;
    color: #000;
    position: relative;
    overflow: hidden !important;
    &:hover {
      border: 1px solid #5C0089;
    }
    &--disable {
      background: #eeeeef;
      &:hover {
        border: 1px solid #A39F9F;
      }
     input {
       border: none !important;
       color: #666 !important;
     }
    }
    &_input {
      overflow: hidden !important;
      margin: 0 !important;
      width: 100%;
      padding: 0 !important;
      border: none !important;
      input[disabled] {
        border: none !important;
      }
    }
    &_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      margin-top: 1px;
      svg {
        font-size: 17px;
        color: #666;
      }
      &--disable {
        svg {
          color: #666;
        }
      }
    }
  }
  &_radio {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto 0;
    &_label {
      margin: 0 !important;
      padding-right: 10px;
    }
  }
  .form_col {
    label {
      margin: 20px 0 7px 0;
    }
  }
  .form {
    height: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .update_order_in_body {
    padding: 0 10px;
  }
  .update_order_show_coords {
    margin-right: 0;
  }
}
@media only screen and (max-width: 800px) {
  .update_order_map {
    width: 0;
  }
  .update_order_in {
    width: 100%;
  }
  .update_order_in_fixed {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .update_order_submit_btn {
    font-size: 12px;
    padding: 4px 8px;
  }
}