.user_profile{
  min-width: 400px;
  max-width: 400px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  background: #f8faff;
  right: 0;
  top: 0;
  z-index: 7;
  transition: all .3s;
  position: fixed;
  transform: translateX(calc(50% + 450px));
  &--animate {
    animation: fromRight .3s;
    transform: translateX(0);
  }
  &__overlay {
    position: fixed;
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 6;
  }
  &_in {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background: #f8faff;
    padding: 20px 5px 20px 10px;
    &_actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      margin-left: auto;
    }
    &_lng {
      display: flex;
      align-items: center;
      justify-content: center;
      &_btn {
        cursor: pointer;
        transition: all .3s;
        color: #000;
        font-size: 13px;
        padding: 3px 6px;
        border-radius: 5px !important;
        font-weight: 300;
        border: 1px solid transparent;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 4px;
        &:hover {
          color: #000;
          background: #f1f3f7;
          border: 1px solid #A39F9F;
        }
        &--active {
          color: #000;
          background: rgba(#f1f3f7, 1);
          border: 1px solid #A39F9F;
        }
      }
    }
    &_logout {
      margin-left: 10px;
      border-radius: 5px;
      border: none;
      background: transparent;
      color: #000;
      cursor: pointer;
      display: block;
      font-size: 13px;
      font-weight: 400;
      padding: 3px 4px;
      transition: all .3s;
      text-align: left;
      &:hover {
        color: #5c0089;
      }
    }
  }
  &_header {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding:  0 10px 10px 10px;
    border-radius: 15px;
    margin-right: 5px;
    box-sizing: border-box;
    position: relative;
    &__info {
      display: flex;
      width: 100%;
      align-items: center;
      position: relative;
      margin-bottom: 15px;
      &_status {
        font-size: 13px;
        font-weight: 300;
        color: #000;
        text-transform: lowercase;
        padding-left: 15px;
        position: relative;
        &--online {
          &::after {
            content: "";
            position: absolute;
            top: 4px;
            left: 0;
            width: 10px;
            height: 10px;
            border-radius: 100px;
            background: green;
          }
        }
        &--offline {
          &::after {
            content: "";
            position: absolute;
            top: 4px;
            left: 0;
            width: 10px;
            height: 10px;
            border-radius: 100px;
            background: #f0b101;
          }
        }
      }
      &_close {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
        svg {
          color: #000;
          font-size: 20px;
        }
      }
    }
    &__img {
      width: 150px;
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      & img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-size: cover;
        background-position: center;
        object-fit: cover;
        border-radius: 100px;
      }
    }
    &__data {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 15px;
      width: 100%;
      padding: 5px 5px;
      margin-top: 10px;
      overflow-y: auto;
      height: calc(100vh - 250px);
      &_title {
        background: #F0F1F5;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        border-radius: 8px;
        padding: 12px 14px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: start;
        margin: 10px 0;
        color: #1A1926;
        width: 100%;
      }
      p {
        width: 100%;
        color: #000;
        text-align: start;
        padding: 2px 14px 12px 14px;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 5px;
        border-bottom: 1px solid rgba(lightgray, 0.5);
      }
      span {
        width: 100%;
        text-transform: uppercase;
        color: #666;
        text-align: start;
        font-weight: 400;
        font-size: 10px;
        padding: 6px 14px 2px 14px;
      }
    }
  }
}

@keyframes fromRight {
  0% {
    transform: translateX(calc(50% + 450px));
  }
  100% {
    transform: translateX(0);
  }
}

@media only screen and (max-width: 600px) {
  .user_profile {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
}