.drop_down_filter {
  width: 100%;
  height: 100%;
  position: relative;
  margin-right: 5px;
  &--disabled {
    pointer-events: none;
    opacity: 0.6;
  }
  &_in {
    height: 100%;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all .3s;
    border-radius: 5px;
    padding: 2px;
    overflow: hidden;
    background: transparent;
  }
  &_in--noHover {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #fff !important;
    transition: all .3s;
    border-radius: 5px;
    padding: 2px;
    overflow: hidden;
    &:hover {
      border: 1px solid #fff !important;
    }
  }
  &__input {
    font-size: 12px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    background: transparent;
    text-transform: capitalize;
    white-space: nowrap;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 30px;
    &--active {
      z-index: 6;
    }
    &--search {
      height: 35px !important;
      margin: 0 !important;
      text-indent: unset;
    }
    &:focus {
      outline: none;
      cursor: text;
      border: none;
    }
    &:hover {
      border: none;
    }
  }
  &__label {
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    & svg {
      color: #5c0089;
      font-size: 20px;
      cursor: pointer;
      background: transparent;
      height: 30px;
    }
  }
  &_menu {
    min-width: 200px;
    max-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: #000;
    padding: 0;
    background: #fff;
    border: 1px solid #000;
    box-shadow: 0 0 0 rgba(0, 0, 0, 1);
    border-radius: 16px;
    overflow: hidden;
    overflow-y: auto;
    position: fixed;
    top: 35px;
    left: 0;
    text-transform: capitalize;
    z-index: 8;
    & p {
      font-size: 10px;
      font-weight: 300;
      color: #000;
      width: 100%;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 10px;
      cursor: default;
      margin-top: 0;
      margin-bottom: 0 !important;
    }
    & ul {
      text-decoration: none;
      list-style: none;
      width: 100%;
      background: #fff;
      & li {
        font-size: 13px;
        font-weight: 300;
        width: 100%;
        height: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 15px 10px;
        transition: all .3s;
        color: #000;
        &:hover {
          background: #f1f3f7;
          color: #000;
          & svg {
            color: #fff;
          }
        }
      }
    }
    &__item {
      &--active {
        background: #f1f3f7;
        color: #000;
        font-weight: 400;
        & svg {
          color: #fff !important;
        }
      }
    }
  }
  &_search {
    height: 100%;
    display: flex;
  }
  &__overlay {
    width: 100%;
    height: 100%;
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 6;
  }
}
