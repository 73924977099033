.settings_company_notifications {
  width: 100%;
  height: 100%;
  &_in {
    width: 100%;
    .table {
      thead {
        height: 40px !important;
        background-color: #fff !important;
        color: #000 !important;
        font-weight: 300 !important;
        font-size: 12px !important;
        border-bottom: 1px solid #f1f3f7 !important;
        box-shadow: none;
        tr {
          box-shadow: none !important;
        }
        th {
          background-color: #fff !important;
        }
      }
    }
    &__title {
      width: 100%;
      font-size: 14px;
      color: #000;
      font-weight: 400;
      text-align: left;
      padding-bottom: 10px;
    }
    &__descrip {
      width: 100%;
      font-size: 14px;
      color: #000;
      font-weight: 300;
      text-align: left;
      padding-bottom: 10px;
    }
    &_options {
      margin-bottom: 50px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &_error {
        width: 100%;
        font-size: 13px;
        font-weight: 300;
        color: red;
        margin-top: 20px;
      }
      &_loading {
        min-height: 300px;
      }
      & svg {
        cursor: pointer;
      }
      &_switcher {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        min-height: 50px;
        & input[type="checkbox"] {
          -webkit-appearance: none;
          background: #f1f3f7;
          width: 45px;
          height: 7px;
          border-radius: 20px;
          box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
          transition: all .3s;
          position: relative;
          left: -7px;
          cursor: pointer;
          margin-left: 20px;
        }
        & input:checked[type="checkbox"] {
          background: #f1f3f7;
        }
        & input[type="checkbox"]:before {
          content: '';
          position: absolute;
          width: 15px;
          height: 15px;
          border-radius: 20px;
          top: 50%;
          left: -5px;
          transform: translateY(-50%);
          background: #f0b101;
          transition: all .5s;
          box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        }
        & input:checked[type="checkbox"]::before {
          left: 30px;
          background: green;
        }
      }
      &_status_name {
        font-size: 13px !important;
        font-weight: 600 !important;
        color: #000;
        text-transform: uppercase;
      }
      &_popup {
        svg {
          cursor: pointer;
        }
      }
    }
  }
  &_footer {
    height: 5px;
  }
}

@media only screen and (max-width: 500px) {
  .settings_company_notifications_in__descrip {
    font-size: 12px;
  }
}