.content_loading {
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  &_img {
    margin-right: 25px;
    width: 50px;
    height: 50px;
    overflow: hidden;
    background-color: #e2e3e5;
    background: linear-gradient(
                    100deg,
                    rgba(255, 255, 255, 0) 40%,
                    rgba(255, 255, 255, .5) 50%,
                    rgba(255, 255, 255, 0) 60%
    ) #e2e3e5;
    border-radius: 100px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    &--single {
      margin: 0;
    }
  }
  &_content {
    width: calc(100% - 50px - 25px);
    font-size: 14px;
    &_line {
      padding: 5px 2px;
      background-color: #e2e3e5;
      background: linear-gradient(
                      100deg,
                      rgba(255, 255, 255, 0) 40%,
                      rgba(255, 255, 255, .5) 50%,
                      rgba(255, 255, 255, 0) 60%
      ) #e2e3e5;
      border-radius: 100px;
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
      color: transparent;
      height: 10px;
    }
    &_line1 {
      margin-bottom: 10px;
      width: 100%;
    }
    &_line2 {
      margin-top: 10px;
      width: 60%;
    }
  }
  &_card {
    width: 100%;
    background-color: transparent;
    border-radius: 6px;
    overflow: hidden;
    &__image {
      height: 100px;
    }
    &__image img {
      display: block;
      width: 100%;
      height: inherit;
      object-fit: cover;
    }
    &__content {
      padding: 10px 0 ;
      margin: 0 0 10px;
    }
    p {
      margin: 0 0 10px;
      font-size: 15px;
      line-height: 15px;
    }
    &__description {
      font-size: 10px;
      line-height: 14px;
    }
    &_loading &__image,
    &_loading p,
    &_loading &__description {
      background-color: #e2e3e5;
      background: linear-gradient(
                      100deg,
                      rgba(255, 255, 255, 0) 40%,
                      rgba(255, 255, 255, .5) 50%,
                      rgba(255, 255, 255, 0) 60%
      ) #e2e3e5;
      background-size: 200% 100%;
      background-position-x: 180%;
      animation: 1s loading ease-in-out infinite;
    }
    &_loading p {
      min-height: 16px;
      border-radius: 4px;
      animation-delay: .05s;
    }
    &_loading &__description {
      min-height: 40px;
      border-radius: 4px;
      animation-delay: .06s;
    }
  }
}

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

@media only screen and (max-width: 600px) {
  .content_loading {
    &_img {
      margin-right: 15px;
      width: 35px;
      height: 35px;
    }
    &_content {
      width: calc(100% - 35px - 15px);
    }
  }
}