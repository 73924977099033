.form {
  display: flex;
  flex-direction: column;
  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 25px;
    min-height: 50px;
    &__title {
      color: #000;
      font-size: 16px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: left;
      margin-right: 15px;
      &--error {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        p {
          font-weight: 400;
          font-size: 13px;
          color: red;
          width: calc(100% - 25px);
          overflow-wrap: break-word;
        }
        svg {
          width: 25px;
          font-size: 20px !important;
          color: red;
          margin-right: 5px;
          display: flex;
          align-items: center;
          justify-self: flex-start;
        }
      }
    }
    &__description {
      font-size: 13px;
      color: #666;
      font-weight: 400;
      padding: 5px 0;
      text-align: center;
      line-height: 20px;
      margin-bottom: 10px;
    }
    &__close_icon {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      & svg {
        font-size: 20px;
        width: 25px;
        cursor: pointer;
        transition: all .3s;
        color: #000;
        &:hover {
          transform: rotate(180deg);
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .form_header {
    padding: 0 10px;
    min-height: 50px;
  }
}
@media only screen and (max-width: 600px) {
  .form_header__error p {
    font-size: 11px;
  }
}
