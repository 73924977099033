.menu_tab_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 8px 4px;
  transition: all .3s ;
  cursor: pointer;
  border: 1px solid transparent;
  color: #000;
  background: #fff;
  margin-top: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 16px;
  font-weight: 200;
  &:nth-child(1) {
    margin-right: 5px;
    margin-left: 5px;
  }
  &:nth-child(2) {
    margin-right: 5px;
  }
  &:nth-child(3) {
    margin-right: 5px;
  }
  &:hover {
    background: rgba(240, 177, 1, 0.3);
    color: #5c0089 !important;
    box-shadow: 0 0 0 rgba(240, 177, 1, .5);
    border: 1px solid transparent;
    .menu_tab_container_btn, .menu_tab_container_count {
      color: #5c0089;
    }
  }
  &:active, &:focus {
    outline: none;
  }
  &--active {
    color: #5c0089;
    background: rgba(92, 0, 137, 0.1);
    cursor: default;
    box-shadow: 0 0 0 rgba(240, 177, 1, 1);
    border: 1px solid transparent;
    &:hover {
      color: #5c0089 !important;
      background: rgba(92, 0, 137, 0.1);
      cursor: default;
      box-shadow: 0 0 0 rgba(240, 177, 1, .5);
      border: 1px solid transparent;
    }
  }
  &_btn {
    border: none;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    height: 100%;
    background: transparent;
    color: #000;
    text-transform: lowercase;
    transition: all .3s;
    &--active {
      cursor: default;
      color: #5c0089 !important;
    }
  }
  &_count {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-left: 5px;
    cursor: pointer;
    &--active {
      cursor: default;
      color: #5c0089 !important;
    }
  }
}

@media only screen and (max-width: 400px) {
  .menu_tab_container_btn {
    font-size: 10px;
  }
}