.update_partner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 70%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  z-index: 7;
  .assign_agent_form--show {
    animation: none;
    width: 100%;
    height: 100%;
  }
  &_loading {
    width: 60px;
    height: 60px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-self: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 8;
    background: #fff;
    border-radius: 6px;
  }
  &_in {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 0 20px;
    &_fixed {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 15;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      height: 70px;
      background: #fff;
      padding: 10px 15px 10px 15px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px -5px 15px;
      border-radius: 12px;
      &_actions {
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        &_cancel {
          margin-right: 5px;
          display: flex;
          font-size: 12px;
          text-decoration: none;
          text-transform: lowercase;
          color: #444;
          background: none;
          border: none;
          cursor: pointer;
          transition: all .3s ease;
          &:hover {
            color: #147efb;
          }
        }
        &_submit {
          background: #5C0089;
          color: #fff;
          border: 1px solid #5C0089;
          font-size: 13px;
          font-weight: 400;
          text-transform: lowercase;
          padding: 7px 10px;
          transition: all .3s ease;
          border-radius: 18px;
          height: 35px;
          cursor: pointer;
          min-width: 150px;
          margin-left: auto;
          &--pending {
            background: #f0b101 !important;
            border: 1px solid #f0b101;
            .loading-circle {
              padding: 0;
              height: 100%;
            }
          }
          &:hover, &:active, &:focus {
            background: #f0b101 !important;
            color: #fff;
            border: 1px solid #f0b101;
          }
        }
      }
      &_assignBtn {
        font-size: 14px;
        color: #007aff;
        font-weight: 400;
        cursor: pointer;
        transition: all .3s ease;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        p {
          font-size: 14px;
          max-width: 150px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &--close {
          margin-left: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #007aff;
          font-size: 12px;
          font-weight: 400;
          cursor: pointer;
          transition: all .3s ease;

          &:hover {
            color: #f0b101;
          }
        }
        &--active {
          color: #5C0089 !important;
          cursor: default !important;
        }
        &--assigned {
          color: #f0b101 !important;
          cursor: default !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .update_partner {
    min-width: 95%;
  }
}
@media only screen and (max-width: 600px) {
  .update_partner_in_fixed_actions_submit {
    font-size: 12px;
  }
  .update_partner_in_fixed_actions_cancel {
    font-size: 12px;
  }
}