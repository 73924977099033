.agent_timeline {
  width: 100%;
  position: relative;
  &_no_data {
    width: 100%;
    height: 100%;
    margin-top: calc(50% + 80px);
  }
  &_list {
    width: 100%;
    margin-top: 20px;
    overflow-y: auto;
  }
  &_btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px 15px;
    margin-bottom: 10px;
    transition: all .3s ease;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(0, 0, 0, 1);
    animation: animate-to-left .5s ease;
    border: 1px solid #e2e3e5;
    background: rgba(#e2e3e5, .5);
    border-radius: 16px;
    &:hover {
      background: #fff;
      box-shadow: 0 0 0 rgba(0, 0, 0, 1);
    }
    h2 {
      font-size: 13px;
      font-weight: 400;
      color: #000;
      padding-bottom: 8px;
    }
    p {
      font-size: 12px;
      font-weight: 300;
      color: #000;
    }
  }
}
