.partners {
  width: 100%;
  &_in {
    background: #fff;
    box-shadow: 0 20px 30px -18px rgba(4, 15, 21, 0.1);
    border-radius: 16px;
    padding: 20px 20px 10px 20px;
    &_icon {
      svg {
        transition: all .3s;
        color: #000 !important;
      }
      &--delete {
        &:hover {
          svg {
            transform: scale(1.1);
            color: red !important;
          }
        }
      }
      &--restore {
        &:hover {
          svg {
            transform: scale(1.1);
            color: green !important;
          }
        }
      }
    }
    &_actions {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      margin-bottom: 25px;
      border-radius: 16px;
      background: #f1f3f7;
      button {
        border: 1px solid #5C0089;
        color: #fff;
        transition: all .3s;
        font-size: 13px;
        font-weight: 400;
        height: 34px;
        letter-spacing: .055em;
        padding: 4px 16px;
        min-width: 180px;
        cursor: pointer;
        box-sizing: border-box;
        background: #5C0089;
        border-radius: 26px;
        margin-left: 15px;
        text-transform: lowercase;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          color: #fff;
          font-size: 22px;
        }
        &:hover {
          background: #f0b101;
          color: #fff;
          border: 1px solid #f0b101;
        }
      }
      &__switcher {
        //display: flex;
        //align-items: center;
        //justify-content: center;
        //position: relative;
        //padding: 4px;
        //width: 120px;
        //height: 48px;
        //background: #E8EAED;
        //border-radius: 14px;
        //margin-left: 15px;

        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        margin-left: 15px;
        &_btn {
          cursor: pointer;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          display: flex;
          justify-content: center;
          align-items: center;
          width: 48px;
          height: 40px;
          border-radius: 14px;
          padding: 0;
          margin: 0 5px;
          border: 0.5px solid transparent;
          &--first {
            left: 0;
          }
          &--second {
            right: 0;
          }
          & svg {
            color: #000;
            font-size: 20px;
          }
          &--active {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background: linear-gradient(180deg, #FFFFFF 83.86%, #F5F6F7 100%);
            border: 0.5px solid rgba(0, 0, 0, 0.03);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12), 0px 2px 1px rgba(0, 0, 0, 0.02);
            border-radius: 12px;
            & svg {
              color: #5c0089;
            }
          }
        }
      }
    }
    &_freeze_alert {
      width: 300px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      border-radius: 15px;
      padding: 20px 10px;
      z-index: 7;
      &_title {
        font-size: 15px;
        text-transform: capitalize;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        color: #444444;
        margin-bottom: 20px;
      }
      &_actions {
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
      &_btn {
        transition: all .3s;
        padding: 8px 5px;
        border-radius: 5px;
        cursor: pointer;
        &--cancel {
          background: transparent;
          color: #444;
          font-weight: 500;
          font-size: 13px;
          border: none;
          text-transform: capitalize;
          margin-right: auto;
          &:hover {
            color: #5c0089;
          }
        }
        &--submit {
          width: 100px;
          border: 1px solid #5c0089;
          background: #5c0089;
          color: #fff;
          font-weight: 600;
          font-size: 13px;
          text-transform: uppercase;
          &:hover {
            background: #fff;
            color: #5c0089;
          }

        }
      }
      &_overlay {
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.3);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 6;
      }
    }
  }
  &_loading_wrapper {
    width: 50px;
    height: 50px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 5px;
    z-index: 7;
    &_overlay {
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.3);
      position: fixed;
      top: 0;
      left: 0;
      z-index: 6;
    }
  }
  &_footer {
    height: 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .partners_in_actions {
    margin-bottom: 10px;
  }
  .partners_in {
    padding: 10px;
  }
}
@media only screen and (max-width: 600px) {
  .partners_in_actions {
    flex-wrap: wrap;
    .search {
      width: calc(100% - 35px);
    }
  }
  .partners_in_actions__switcher {
    display: none;
  }
  .partners_in_actions button {
    font-size: 16px;
    min-width: 30px;
    height: 30px;
    border-radius: 100px;
    padding: 0;
    margin-left: 0;
  }
  .more_popup__item {
    font-size: 12px;
    padding: 8px 12px;
  }
  .partners_in_freeze_alert {
    width: 95%;
  }
}