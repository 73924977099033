.delete_role_form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  z-index: 8;
  background: #fff;
  padding: 20px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
  &__error {
    font-size: 12px;
    color: red;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__submit_btn {
    background: #5c0089;
    border: 1px solid #5c0089;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 8px;
    text-transform: uppercase;
    transition: all .3s;
    margin-left: auto;
    min-width: 150px;
    &:hover {
      background: #fff;
      color: #5c0089;
    }
  }
  &__cancel_btn {
    background: transparent;
    border: none;
    border-radius: 5px;
    color: #444;
    cursor: pointer;
    display: block;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 0;
    text-transform: lowercase;
    transition: all .3s;
    &:hover {
      color: #5c0089;
    }
  }
  .form {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .delete_role_form {
    width: 95%;
  }
}