.order_list {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0 10px 0 10px;
  &--animate {
    animation: animate-to-right .5s ease;
  }
  &__loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px 0;
  }
  &__item {
    width: 100%;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &__load_more_btn {
    background: #fff;
    border: none;
    padding: 14px;
    cursor: pointer;
    color: #000;
    font-size: 13px;
    transition: all .3s;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    &:hover {
      color: #5c0089;
    }
  }
  &__load_more_loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 1024px) {
  .order_list__load_more_btn {
    padding: 10px 10px 0px 10px;
  }
  .order_list {
    padding: 0 10px 10px 10px;
  }
}