.agents_container {
  width: 400px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #f1f3f7;
  height: 100%;
  transition: all 0.3s;
  position: relative;
  &--animate {
    animation: animate-to-left .5s ease;
  }
  & ul {
    width: 100%;
    overflow: auto;
    padding: 0 10px 0 10px;
    height: 100%;
    position: relative;
  }
  &__list {
    width: 100%;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    &_btn {
      position: absolute;
      top: 30px;
      transform: translateX(-50%);
      left: 50%;
      letter-spacing: .055em;
      box-sizing: border-box;
      animation: pulse 2s infinite;
      background: rgba(92, 0, 137, 0.1);
      color: #5C0089;
      border: none;
      font-size: 13px;
      font-weight: 400;
      text-transform: lowercase;
      padding: 10px 12px;
      transition: all .3s ease;
      border-radius: 18px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      svg {
        font-size: 18px;
        color: #5C0089;
        margin-right: 10px;
        transition: all .3s ease;
      }
      &:hover {
        background: #f0b101;
        animation: none;
        transform: translate(-50%, -50%) scale(1);
        color: #fff;
        svg {
          color: #fff;
        }
      }
    }
    &--animate {
      animation: animate-to-left .5s ease;
    }
  }
  &__search {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    background-color: #f1f3f7;
    min-height: 53px;
    padding: 10px 10px 5px 7px;
    .menu_tab_container {
      margin-top: 0;
    }
    &_close {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      & svg {
        font-size: 30px;
        color: #5c0089;
        cursor: pointer;
        transition: all .3s;
        &:hover {
          transform: rotate(180deg);
        }
      }
    }
    & input, p, a {
      font-weight: 300;
      font-size: 14px;
      color: #000;
      height: 40px;
      width: 100%;
      padding: 7px 5px;
      text-indent: 5px;
      border-radius: 16px;
      border: none;
      background: #e2e3e5;
      &::placeholder {
        color: #bbb6b6;
        font-weight: 200;
        font-size: 13px;
      }
      &:focus {
        outline: none;
      }
    }
    & a {
      text-decoration: none;
      cursor: pointer;
      width: 100%;
      display: flex;
      align-items: center;
      transition: all .3s;
      background: rgba(92, 0, 137, 0.1);
      color: #5c0089;
      margin-right: auto;
      border: 1px solid transparent;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      &:hover {
        background: rgba(240, 177, 1, 0.3);
        border-radius: 16px;
        color: #5c0089;
        outline: none;
        border: 1px solid rgba(240, 177, 1, 0.3);
      }
    }
    & label {
      font-size: 30px;
      position: absolute;
      padding: 7px 5px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      color: #000;
      margin-right: 10px;
      display: none;
      & svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 45px;
        color: #5c0089 !important;
        margin-left: 10px;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
  &__tab {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    padding: 2.5px 10px 10px 5px;
    button {
      &:nth-child(1) {
        border-left: none;
      }
    }
  }
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
    box-shadow: 0 0 0 0 rgba(#5C0089, 0.7);
  }

  70% {
    transform: translate(-50%, -50%) scale(1);
    box-shadow: 0 0 0 10px rgba(#5C0089, 0);
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
    box-shadow: 0 0 0 0 rgba(#5C0089, 0);
  }
}

@keyframes animate-to-left {
  0% {
    transform: translateX(450px);
  }
  100% {
    transform: translateX(0);
  }
}

@media only screen and (max-width: 1024px) {
  .agents_container {
    width: 100%;
    height: calc(100% - 50px);
  }
  .agents_container ul {
    padding: 0 10px;
  }
  .agents_container__search {
    padding: 5px 10px 5px 10px;
  }
}
@media only screen and (max-width: 400px) {
  .agents_container__search {
    padding: 10px 10px 5px 10px;
    min-height: 35px;
    height: 35px;
  }
  .agents_container__search input, .agents_container__search p, .agents_container__search a {
    height: 30px;
  }
  .agents_container__list_btn {
    font-size: 12px;
  }
}

