.assign_role_form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 500px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  z-index: 8;
  padding: 0 25px;
  .form_header {
    padding: 15px 0;
  }
  &_in {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  &_drop_down {
    width: 100%;
    margin-bottom: 20px;
  }
  &_table {
    width: 100%;
    .table {
      thead {
        height: 0 !important;
      }
    }
    .table_body td p {
      min-height: 60px !important;
    }
    input[type="checkbox"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      cursor: pointer;
      display: inline-block;
      width: 20px !important;
      height: 20px !important;
      color: #fff;
      padding: 1px;
      background-clip: content-box;
      border: 2px solid rgb(200, 200, 200);
      background-color: rgb(200, 200, 200);
      border-radius: 100px;
      margin-right: 10px;
    }
    input[type="checkbox"]:checked {
      background-color: #5c0089;
    }
  }
  &_wrapper {
    width: 100%;
    li {
      padding: 10px 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      list-style: none;
      border-bottom: 1px solid rgba(#5c0089, .3);
      &:last-child {
        border-bottom: none;
      }
      p {
        font-size: 15px;
        color: #000;
        font-weight: 500;
      }
      & input[type="checkbox"] {
        -webkit-appearance: none;
        background: #f1f3f7;
        width: 45px;
        height: 15px;
        border-radius: 20px;
        box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        transition: all .3s;
        position: relative;
        left: 0;
        cursor: pointer;
      }
      & input:checked[type="checkbox"] {
        background: #f1f3f7;
      }
      & input[type="checkbox"]:before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        background: #f0b101;
        transition: all .5s;
        box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
      }
      & input:checked[type="checkbox"]::before {
        left: 30px;
        background: rgba(92, 0, 137, 1);
      }
    }
  }
  &__cancel_btn {
    display: flex;
    height: 100%;
    line-height: 30px;
    font-size: 14px;
    text-decoration: none;
    text-transform: lowercase;
    transition: all .3s;
    color: #444;
    background: none;
    border: none;
    cursor: pointer;
    margin: 20px 0;
    &:hover {
      color: #5c0089;
    }
  }
  &__submit_btn {
    margin: 20px 0;
    background: #5C0089;
    color: #fff;
    border: 1px solid #5C0089;
    font-size: 14px;
    text-transform: lowercase;
    padding: 7px 10px;
    transition: all .3s ease;
    border-radius: 18px;
    height: 35px;
    cursor: pointer;
    width: 100%;
    &--pending {
      .loading-circle {
        padding: 0;
        height: 100%;
      }
      background: #f0b101;
      color: #fff;
      border: 1px solid #f0b101;
    }
    &:hover {
      background: #f0b101;
      color: #fff;
      border: 1px solid #f0b101;
    }
  }
  .form {
    width: 100%;
  }
}

@media only screen and (max-width: 800px) {
  .assign_role_form {
    min-width: 80%;
  }
}
@media only screen and (max-width: 600px) {
  .assign_role_form__submit_btn {
    font-size: 12px;
  }
  .assign_role_form__cancel_btn {
    font-size: 12px;
  }
}
@media only screen and (max-width: 400px) {
  .assign_role_form {
    min-width: 95%;
  }
}