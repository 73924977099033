.edit_role_form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  padding: 0 20px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  z-index: 8;
  .form_header {
    padding: 15px 0;
    height: 50px;
  }
  &_in {
    width: 100%;
    height: calc(100% - 50px - 82px - 75px);
    overflow: auto;
  }
  &_actions {
    width: 100%;
    padding: 20px 50px 0 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
  }
  &_input {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }
  &_wrapper {
    width: 100%;
    &--close {
      height: 0;
      visibility: hidden;
      display: none;
    }
    &--open {
      visibility: unset;
      height: 100%;
      animation: openRoles 1s;
      display: block;
      li {
        display: flex;
        animation: openRoles 1s;
      }
      input[type="checkbox"] {
        display: block;
      }
    }
    &_title {
      text-align: left;
      position: relative;
      font-style: normal;
      font-size: 14px;
      line-height: 15px;
      color: #000000;
      width: 100%;
      font-weight: 400;
      margin: 14px 0 7px 0;
      transition: all .3s ease;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &--active {
        color: #5c0089;
        svg {
          color: #5c0089 !important;
        }
      }
      svg {
        font-size: 18px;
        color: #000;
        margin-right: 10px;
        transition: all .3s ease;
      }
      &:hover {
        color: #5c0089;
        svg {
          color: #5c0089;
        }
      }
    }
    li {
      padding: 10px 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      list-style: none;
      border-bottom: 1px solid lightgrey !important;
      &:last-child {
        border-bottom: none;
      }
      p {
        font-size: 13px;
        color: #000;
        font-weight: 300;
      }
      & input[type="checkbox"] {
        -webkit-appearance: none;
        background: #f1f3f7;
        width: 45px;
        height: 7px;
        border-radius: 20px;
        box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        transition: all .3s;
        position: relative;
        left: 0;
        cursor: pointer;
        margin-left: 10px;
      }
      & input:checked[type="checkbox"] {
        background: #f1f3f7;
      }
      & input[type="checkbox"]:before {
        content: '';
        position: absolute;
        width: 15px;
        height: 15px;
        border-radius: 20px;
        top: 50%;
        left: -5px;
        transform: translateY(-50%);
        background: #f0b101;
        transition: all .5s;
        box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
      }
      & input:checked[type="checkbox"]::before {
        left: 30px;
        background: green;
      }
    }
  }
  &__cancel_btn {
       margin: 20px 0;
       display: flex;
       height: 100%;
       line-height: 30px;
       font-size: 14px;
       text-decoration: none;
       text-transform: lowercase;
       transition: all .3s;
       color: #444;
       background: none;
       border: none;
       cursor: pointer;
       &:hover {
         color: #5c0089;
       }
     }
  &__submit_btn {
    margin: 20px 0;
    background: #5C0089;
    color: #fff;
    border: 1px solid #5C0089;
    font-size: 14px;
    text-transform: lowercase;
    padding: 7px 10px;
    transition: all .3s ease;
    border-radius: 18px;
    height: 35px;
    cursor: pointer;
    width: 100%;
    &--pending {
      .loading-circle {
        padding: 0;
        height: 100%;
      }
      background: #f0b101;
      color: #fff;
      border: 1px solid #f0b101;
    }
    &:hover {
      background: #f0b101;
      color: #fff;
      border: 1px solid #f0b101;
    }
  }
  .form {
    width: 100%;
  }
}

@keyframes openRoles {
  0% {
    height: 0;
    visibility: hidden;
  }
  100% {
    height: 100%;
    visibility: unset;
  }
}

@media only screen and (max-width: 800px) {
.edit_role_form {
  width: 80%;
}
}
@media only screen and (max-width: 600px) {
  .edit_role_form__submit_btn {
    font-size: 12px;
  }
  .edit_role_form__cancel_btn {
    font-size: 12px;
  }
}
@media only screen and (max-width: 400px) {
  .edit_role_form {
    width: 95%;
  }
}