.order_item {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: #fff;
  transition: all .3s;
  position: relative;
  cursor: pointer;
  border-radius: 35px;
  background: rgba(#e2e3e5, .5);
  box-shadow: 0 0 0 rgba(0, 0, 0, 1);
  border: 1px solid #e2e3e5;
  &:hover {
    background: #fff;
    box-shadow: 0 0 0 rgba(0, 0, 0, 1);
    border: 1px solid #999;
  }
  &_agent {
    position: absolute;
    top: 10px;
    right: 12px;
    &_in {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      &_img {
        min-width: 35px;
        min-height: 35px;
        max-width: 35px;
        max-height: 35px;
        overflow: hidden;
        border-radius: 100px;
        margin-left: 5px;
        img {
          width: 100%;
          height: 100%;
          background-position: center;
          object-position: center;
          background-size: contain;
          object-fit: fill;
        }
      }
      button {
        width: 35px;
        border-radius: 100px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        background: #5C0089;
        transition: all .3s ease;
        border: 1px solid #5C0089;
        cursor: pointer;
        svg {
          font-size: 30px;
          color: #fff;
          stroke-width: .1px;
        }
        &:hover {
            transform: scale(1.05);
          svg {

          }
        }
      }
    }
  }
  &_update {
    position: absolute;
    top: 9px;
    right: 11px;
    background: #e2e3e5;
    min-width: 37px;
    min-height: 37px;
    max-width: 37px;
    max-height: 37px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e2e3e5;
    z-index: 10;
    overflow: hidden !important;
    &:hover {
      & svg {
        color: #5c0089;
        transform: scale(1.2);
      }
    }
    svg {
      font-size: 20px;
      color: #444;
      transition: all .3s ease;
    }
  }
  &_heading {
    width: 100%;
    padding: 15px 15px 0 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &_id {
      font-size: 12px;
      text-transform: lowercase;
      color: #676565;
      font-weight: 400;
      margin-right: 5px;
    }
  }
  &_statuses {
    width: 100%;
    flex-wrap: wrap;
    overflow-x: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 15px 0 15px;
    &_status {
      margin: 0 15px 10px 15px;
      position: relative;
      &:first-child {
        margin-left: 0;
        &::before {
          display: none;
        }
      }
      &:last-child {
        margin-right: 0;
        &::after {
          display: none;
        }
      }
      &::after {
        height: 1px;
        width: 30px;
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translateY(calc(-50% - .5px));
        content: "";
        z-index: 1;
        display: block;
      }
      &::before {
        height: 1px;
        z-index: 1;
        width: 30px;
        border-bottom: 1px dashed #000;
        position: absolute;
        top: 50%;
        right: 100%;
        transform: translateY(calc(-50% - .5px));
        content: "";
        display: block;
      }
      &--small {
        width: 10px;
        height: 10px;
        border-radius: 100px;
        p {
          display: none;
        }
      }
      &--active {
        height: 24px !important;
        color: #fff;
        padding: 0 10px;
        text-transform: lowercase;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        min-width: 80px;
        margin-top: 0;
        &::after {
          display: none;
        }
        p {
          display: block;
          width: 100%;
          font-size: 11px;
          font-weight: 300;
          text-align: center;
        }
      }
      &--unassigned {
        background: rgba(128, 128, 128, 1);
        color: #fff !important;
        border: 1px solid rgba(128, 128, 128, 1);
      }
      &--created {
        background: rgba(128, 128, 128, 1);
        border: 1px solid rgba(128, 128, 128, 1);
        color: #fff !important;
      }
      &--assigned{
        background: #f0b101;
        color: #fff !important;
        border: 1px solid #f0b101;
      }
      &--accepted {
        background: darkorange;
        color: #fff !important;
        border: 1px solid darkorange;
      }
      &--started {
        background: rgba(92, 0, 137, 1);
        border: 1px solid rgba(92, 0, 137, 1);
        color: #fff !important;
      }
      &--picked_up {
        background: rgba(0, 0, 139, 1);
        border: 1px solid rgba(0, 0, 139, 1);
        color: #fff !important;
      }
      &--on_way {
        background: rgba(0, 0, 255, 1);
        border: 1px solid rgba(0, 0, 255, 1);
        color: #fff !important;
      }
      &--delivered {
        background: rgba(0, 128, 0, 1);
        border: 1px solid rgba(0, 128, 0, 1);
        color: #fff !important;
      }
      &--canceled, &--failed {
        background: rgba(255, 0, 0, 1);
        color: #fff !important;
        border: 1px solid rgba(255, 0, 0, 1);
      }
    }
  }
  &_info {
    width: 100%;
    padding: 0 25px;
    &_wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 3px 0;
      span {
        font-size: 13px;
        font-weight: 300;
        color: #000;
        text-transform: lowercase;
        display: flex;
        align-items: center;
        justify-self: flex-start;
        margin-right: 10px;
      }
      p {
        font-size: 13px;
        font-weight: 400;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
      }
    }
  }
  &_times {
    width: 100%;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
    flex-wrap: wrap;
    &_time {
      min-height: 24px;
      padding: 2px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #676565;
      border-radius: 6px;
      margin-right: 5px;
      &--pre {
        background: red !important;
        border: 1px solid red !important;
        p {
          color: #fff !important;
        }
      }
      span {
        font-size: 11px;
        text-transform: lowercase;
        color: #676565;
        font-weight: 300;
        margin-right: 5px;
      }
      p {
        font-size: 12px;
        text-transform: lowercase;
        color: #676565;
        font-weight: 400;
      }
    }
  }
  &_menu {
    position: absolute;
    cursor: pointer;
    border-radius: 16px;
    background: #fff;
    border: 1px solid #A39F9F;
    box-shadow: 0 0 0 rgba(0, 0, 0, 1);
    z-index: 8;
    overflow: hidden;
    ul {
      li {
        list-style: none;
        width: 100%;
        text-align: left;
        font-weight: 300;
        font-size: 13px;
        padding: 10px 12px;
        color: #000;
        transition: all .3s ease;
        &:last-child {
          border-bottom: transparent;
        }
        &:hover {
          background: #f1f3f7;
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .order_item_times_time {
    margin-bottom: 5px;
    p {
      font-size: 11px;
    }
  }
  .order_item_heading_id {
    margin-bottom: 5px;
  }
  .order_item_info_wrapper p {
    font-size: 12px;
  }
  .order_item_info {
    padding: 0 15px;
  }
  .order_item_statuses {
    padding: 8px 15px 0 15px;
  }
  .order_item_statuses_status {
    margin: 0 15px 5px 15px;
  }
  .order_item_times {
    padding: 5px 15px;
  }
}
@media only screen and (max-width: 350px) {
  .order_item_info_wrapper p {
    font-size: 11px;
  }
  .order_item_heading_id {
    font-size: 11px;
  }
  .order_item_info_wrapper:first-child {
    display: none;
  }
}