.stripe_form_sms {
  position: fixed;
  padding: 20px 30px;
  background: #fff;
  width: 700px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9 !important;
  &_logo {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    img {
      width: 100%;
      height: 100%;
      background-position: center;
      object-position: center;
      object-fit: contain;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  &_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &_item {
      width: 20%;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: #fff;
      border: 1px solid #5C0089;
      color: #000;
      padding: 5px 0;
      cursor: pointer;
      transition: all .3s ease;
      border-radius: 6px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      &:hover {
        background: rgba( #5C0089, 0.1);
        border: 1px solid #5C0089;
        box-shadow: none;
      }
      p {
        font-size: 14px;
        font-weight: 600;
        transition: all .3s ease;
      }
      span {
        font-weight: 400;
        font-size: 13px;
        padding-top: 8px;
        transition: all .3s ease;
      }
    }
  }
  &_actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
    &_cancel {
      display: flex;
      margin-right: auto;
      font-size: 13px;
      text-decoration: none;
      transition: all .3s;
      color: #444;
      background: none;
      border: none;
      cursor: pointer;
      text-transform: lowercase;
      &:hover {
        color: #5c0089;
      }
    }
    &_submit {
      background: #5C0089;
      color: #fff;
      border: 1px solid #5C0089;
      font-size: 13px;
      width: 200px;
      font-weight: 400;
      text-transform: lowercase;
      padding: 7px 10px;
      transition: all .3s ease;
      border-radius: 8px;
      height: 35px;
      cursor: pointer;
      &--pending {
        background: #f0b101 !important;
        border: 1px solid #f0b101;
        .loading-circle {
          padding: 0;
          height: 100%;
        }
      }
      &:hover, &:active, &:focus {
        background: #f0b101 !important;
        color: #fff;
        border: 1px solid #f0b101;
      }
    }
  }
  &_info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 8px;
    background: rgba(#5C0089, 0.1);
    padding: 12px 16px;
    &_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: auto;
      border: 1px solid rgba(#5C0089, 0.4);
      border-radius: 6px;
      padding: 2px 8px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      span {
        font-size: 13px;
        font-weight: 300;
        text-align: left;
        color: #000;
        margin-right: 5px;
      }
    }
    &_margin_bottom {
      margin-bottom: 30px;
    }
    &_margin_top {
      margin-top: 30px;
    }
    p {
      font-size: 13px;
      font-weight: 300;
      text-align: left;
      color: #000;
      line-height: 20px;
      margin-right: 5px;
    }
    &_img {
      width: 60px;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: center;
        object-position: center;
        object-fit: contain;
      }
    }
    &_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      svg {
        font-size: 22px;
      }
      &--lock {
        svg {
          color: #5C0089;
        }
      }
      &--warning {
        svg {
          color: #f0b101;
        }
      }
    }
  }
  &_error {
    width: 100%;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    p {
      font-size: 12px;
      color: red;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color: red;
        margin-right: 5px;
        font-size: 18px;
      }
    }
  }
  &_alert {
    width: 400px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 15px;
    padding: 25px 25px;
    z-index: 11;
    &_title {
      font-size: 14px;
      font-weight: 400;
      line-height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      color: #444444;
      margin-bottom: 20px;
    }
    &_actions {
      display: flex;
      align-items: center;
      justify-content: center;
      &_btn {
        &--submit {
          background: #5C0089;
          color: #fff;
          border: 1px solid #5C0089;
          font-size: 13px;
          font-weight: 400;
          text-transform: lowercase;
          padding: 7px 14px;
          transition: all .3s ease;
          border-radius: 18px;
          height: 35px;
          cursor: pointer;
          &--freeze {
            background: red;
            border: 1px solid red;
          }
          &--unfreeze {
            background: green;
            border: 1px solid green;
          }
          &:hover {
            background: #f0b101;
            color: #fff;
            border: 1px solid #f0b101;
          }
        }
        &--cancel {
          display: flex;
          margin-right: auto;
          font-size: 13px;
          font-weight: 400;
          text-decoration: none;
          transition: all .3s;
          color: #000;
          background: none;
          border: none;
          cursor: pointer;
          text-transform: lowercase;
          &:hover {
            color: #5c0089;
          }
        }
      }
    }
    &_overlay {
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.3);
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
    }
  }
}

@media only screen and (max-width: 700px) {
  .stripe_form_sms {
    width: 95%;
    padding: 30px 20px;
  }
  .stripe_form_sms_info_img {
    width: 40px;
  }
  .stripe_form_sms_alert {
    width: 95%;
  }
}
@media only screen and (max-width: 400px) {
  .stripe_form_sms_info_icon {
    display: none;
  }
  .stripe_form_sms_info p {
    font-size: 11px;
  }
}
