.burger {
  min-height: 30px;
  min-width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  background-color: #f1f3f7;
  border-radius: 100px;
  transition: all .3s;
  &:hover {
    transform: scale(1.07);
  }
  &__close {
    width: 18px;
    height: 2px;
    background-color: #000;
    border-radius: 100px;
    &:nth-child(1) {
      width: 15px;
      margin-bottom: 2px;
    }
    &:nth-child(2) {
      width: 15px;
      margin-top: 2px;
    }
    &--clicked {
      &:nth-child(1) {
        transition: all .3s ease-in-out;
        transform: translate(0, 3px) rotate(180deg + 45deg);
      }
      &:nth-child(2) {
        transition: all .3s ease-in-out;
        transform: translate(0, -3px) rotate(-180deg - 45deg);
      }
    }
    &--unclicked {
      &:nth-child(1) {
        transition: all .3s ease-in-out;
        transform: translate(0, 0) rotate(0deg);
      }
      &:nth-child(2) {
        transition: all .3s ease-in-out;
        transform: translate(0, 0) rotate(0deg);
      }
    }
  }
}
