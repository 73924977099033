.branches {
  width: 100%;;
  &_in {
    width: 100%;
    border-radius: 16px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: auto;
    &_actions {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      margin-bottom: 25px;
      border-radius: 16px;
      background: #f1f3f7;
      button {
        border: 1px solid #5C0089;
        color: #fff;
        transition: all .3s;
        font-size: 13px;
        font-weight: 400;
        height: 34px;
        letter-spacing: .055em;
        padding: 4px 16px;
        min-width: 180px;
        cursor: pointer;
        box-sizing: border-box;
        background: #5C0089;
        border-radius: 26px;
        margin-left: 15px;
        text-transform: lowercase;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          color: #fff;
          font-size: 22px;
        }
        &:hover {
          background: #f0b101;
          color: #fff;
          border: 1px solid #f0b101;
        }
      }
      &__switcher {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        margin-left: 15px;
      }
    }
    &_loading {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .branches_in_actions {
    margin-bottom: 10px;
  }
  .branches_in_actions__switcher {
    display: none;
  }
  .branches_in_actions button {
    font-size: 16px;
    min-width: 35px;
    height: 35px;
    border-radius: 100px;
    padding: 0;
  }
}
@media only screen and (max-width: 600px) {
  .branches_in_actions {
    flex-wrap: wrap;
    .search {
      width: calc(100% - 35px);
    }
    .orders_in_actions_calendar {
      order: 4;
    }
  }
  .branches_in_actions button {
    margin-left: 0;
    order: 3;
    min-width: 30px;
    height: 30px;
  }
}