.assign_users_form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  z-index: 8;
  background: #fff;
  padding: 30px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 700px;
  &__title {
    width: 100%;
    font-weight: 400;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    padding-bottom: 15px;
    line-height: 28px;
    margin-bottom: 40px;
  }
  &__overlay {
    background: rgba(0, 0, 0, .3);
    z-index: 7;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    svg {
      font-size: 20px;
      transition: all .3s;
      color: black;
      cursor: pointer;
      &:hover {
        color: #5c0089;
        transform: rotate(180deg);
      }
    }
  }
  &__submit_btn {
    background: #5C0089;
    color: #fff;
    border: 1px solid #5C0089;
    font-size: 12px;
    text-transform: lowercase;
    padding: 7px 10px;
    transition: all .3s ease;
    border-radius: 18px;
    height: 35px;
    cursor: pointer;
    width: 100%;
    &--pending {
      background: #f0b101 !important;
      border: 1px solid #f0b101;
      .loading-circle {
        padding: 0;
        height: 100%;
      }
    }
    &:hover, &:active, &:focus {
      background: #f0b101 !important;
      color: #fff;
      border: 1px solid #f0b101;
    }
  }
  .form {
    width: 100%;
  }
  .form_header {
    padding: 0;
  }
}

@media only screen and (max-width: 800px) {
  .assign_users_form {
    width: 95%;
    padding: 15px 20px;
  }
}