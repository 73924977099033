.timeline_marker {
  width: 100%;
  max-width: 90%;
  height: 100%;
  max-height: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  z-index: 7;
  border: 1px solid #000;
  overflow: hidden;
  &_in {
    position: absolute;
    bottom: 0;
    left: 0;
    background: #ffff;
    width: 100%;
    z-index: 1;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &_actions {
      width: 100%;
      padding: 10px 10px 5px 10px;
      display: flex;
      align-self: center;
      justify-content: center;
    }
    .tracking_link_map_footer_order {
      padding: 0;
    }
    .tracking_link_map_footer_order_in {
      border-bottom: none;
    }
    .tracking_link_map_footer {
      width: 400px;
    }
  }
  &_btn {
    background: #FFFFFF;
    box-shadow: 0 0 0 #f0b101;
    border: 1px solid transparent;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
    border-radius: 100px;
    padding: 2px;
    transition: all .3s ease;
    svg {
      background: #333;
      font-size: 28px;
      color: #fff;
      border-radius: 100px;
      padding: 2px;
      transition: all .3s ease;
    }
    &:hover {
      svg {
        background: #f0b101;
      }
    }
    &--active {
      svg {
        background: #f0b101;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .timeline_marker_in {
    padding: 10px 20px;
  }
  .timeline_marker_in_actions {
    margin-left: auto;
  }
  .timeline_marker_in_info_wrapper h3 {
    font-size: 12px;
  }
  .timeline_marker_in_info_wrapper span {
    font-size: 11px;
  }
  .timeline_marker_in .tracking_link_map_footer {
    width: 100%;
  }
}