.manager_details {
  width: 100%;
  margin-bottom: auto;
  &_in {
    background: #fff;
    box-shadow: 0 20px 30px -18px rgba(4, 15, 21, 0.1);
    border-radius: 16px;
    padding: 20px 20px 10px 20px;
    margin-bottom: auto;
    &_actions {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      margin-bottom: 30px;
      border-radius: 16px;
      background: #f1f3f7;
      .orders_date_time_picker {
        border: 1px solid #000;
      }
      button {
        min-width: 40px;
        max-width: 40px;
        min-height: 40px;
        max-height: 40px;
        color: #fff;
        padding: 5px 10px;
        border-radius: 16px;
        margin-left: 15px;
        border: 1px solid #5c0089;
        background: #5c0089;
        cursor: pointer;
        transition: all .3s;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &:hover {
          background: #fff;
          color: #5c0089;
          &::after, &::before {
            background: #5c0089;
          }
        }
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 15px;
          height: 2px;
          background: #fff;
          border-radius: 100px;
        }
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(90deg);
          width: 15px;
          height: 2px;
          background: #fff;
          border-radius: 100px;
        }
      }
      &_calendar {
        min-width: 230px;
        margin-left: 15px;
        .date_time_picker.orders__date_time_picker {
          height: 40px !important;
          border-radius: 16px;
          background: #fff;
          border: 1px solid #aaa;
          & input {
            font-size: 16px !important;
            font-weight: 600;
          }
        }
      }
    }
    .date_time_picker.orders__date_time_picker {
      height: 40px !important;
      border-radius: 16px;
      background: #fff;
      border: 1px solid #aaa;
      & input {
        font-size: 16px !important;
        font-weight: 600;
      }
    }
  }
  &_dashboard {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 0;
    margin-bottom: 30px;
    &__info {
      width: calc(25% - 9px) !important;
      margin-right: 12px;
      &:nth-child(4) {
        margin-right: 0 !important;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .manager_details_in_actions, .manager_details_dashboard {
    margin-bottom: 10px;
  }
  .manager_details_in {
    padding: 10px;
  }
}
@media only screen and (max-width: 800px) {
  .manager_details_dashboard__info {
    width: calc(50% - 6px) !important;
    margin-left: 0;
    margin-right: 12px;
    margin-bottom: 0;
    &:nth-child(2) {
      margin-right: 0 !important;
      margin-bottom: 12px;
    }
    &:nth-child(1) {
      margin-bottom: 12px;
    }
    &:nth-child(4) {
      margin-right: 0 !important;
    }
  }
}
@media only screen and (max-width: 600px) {
  .manager_details_in_actions {
    flex-wrap: wrap;
  }
  .manager_details_in_actions button {
    font-size: 16px;
    min-width: 30px;
    height: 30px;
    border-radius: 100px;
    padding: 0;
    margin-left: 0;
  }
}

