.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 0;
  margin-top: auto;
  .drop_down_search input {
    text-indent: 0 !important;
    background: transparent !important;
  }
  .drop_down_in {
    border-radius: 5px !important;
    width: 35px;
    height: 35px;
  }
  &_limit {
    width: 35px;
    margin-right: 15px;
  }
  & p {
    font-size: 12px;
    color: #000;
    margin-right: 15px;
    font-weight: 300;
    text-transform: lowercase;
  }
  &_list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    & svg {
      font-size: 14px;
      cursor: pointer;
      color: #444;
    }
    &_page {
      list-style: none;
      cursor: pointer;
      transition: all .3s;
      color: #000;
      font-size: 13px;
      padding: 3px 6px;
      border-radius: 5px !important;
      font-weight: 300;
      border: 1px solid transparent;
      width: 34px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 3px;
      margin-left: 3px;
      background: transparent;
      &:hover {
        color: #000;
        background: #f1f3f7;
        border: 1px solid #A39F9F;
      }
      &--active {
        color: #000;
        background: rgba(#f1f3f7, 1);
        border: 1px solid #A39F9F;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .pagination p {
    font-size: 10px;
    padding-top: 5px;
  }
  .pagination_limit {
    margin-right: 5px;
  }
  .pagination {
    flex-wrap: wrap;
    justify-content: center;
  }
  .pagination_list, .pagination .drop_down {
    padding-top: 5px;
  }
  .pagination_list_page {
    font-size: 11px;
    min-width: 25px;
    height: 25px;
  }
  .pagination .drop_down_in {
    min-width: 25px;
    height: 25px;
  }
  .pagination .drop_down_search input {
    font-size: 11px;
  }
}