.drop_down {
  width: 100%;
  height: 100%;
  position: relative;
  &--disabled {
    pointer-events: none;
    opacity: 0.6;
    .drop_down_in {
      background: #e2e3e5;
      color: #666 !important;
      border: 1px solid #666 !important;
    }
    .drop_down__label {
      display: none;
    }
  }
  &_in {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all .3s;
    padding: 2px;
    overflow: hidden;
    border: 1px solid #A39F9F;
    border-radius: 16px;
    height: 35px;
    &:hover {
      border: 1px solid #5c0089;
    }
    &--disable_hover {
      &:hover {
        border: 1px solid rgb(200, 200, 200);
      }
    }
  }
  &_in--noHover {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #fff !important;
    transition: all .3s;
    border-radius: 5px;
    padding: 2px;
    overflow: hidden;
    &:hover {
      border: 1px solid #fff !important;
    }
  }
  &__input {
    text-indent: 10px !important;
    border: none !important;
    cursor: pointer !important;
    padding: 0 !important;
    position: absolute !important;
    background: transparent !important;
    line-height: 100%;
    top: 50%;
    left: 0 !important;
    transform: translateY(-50%) !important;
    margin: 0 !important;
    font-weight: 300;
    font-size: 14px;
    color: #000;
    z-index: 6;
    &::placeholder {
      color: #bbb6b6;
      font-weight: 200;
      font-size: 13px;
    }
    &--active {
      z-index: 6;
    }
    &--pagination {
      text-indent: 0 !important;
      width: 100%;
      text-align: center;
      top: 50% !important;
    }
    &--search {
      height: 35px !important;
      margin: 0 !important;
      text-indent: unset;
    }
    &:focus {
      outline: none;
      cursor: text;
      border: none;
    }
    &:hover {
      border: none;
    }
  }
  &__pagination {
    text-indent: 10px !important;
    border: none !important;
    cursor: pointer !important;
    padding: 0 !important;
    position: absolute !important;
    background: transparent !important;
    top: 50% !important;
    left: 0 !important;
    text-transform: capitalize !important;
    transform: translateY(-50%) !important;
    margin: 0 !important;
    font-weight: 300;
    font-size: 14px;
    color: #000;
    &::placeholder {
      color: #bbb6b6;
      font-weight: 200;
      font-size: 13px;
    }
    &:focus {
      outline: none;
      cursor: text;
      border: none;
    }
    &:hover {
      border: none;
    }
  }
  &__label {
    padding: 0 5px 0 0 !important;
    height: 100% !important;
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 4;
    & svg {
      color: #666;
      font-size: 18px;
      cursor: pointer;
      background: transparent;
      margin: 0 !important;
      margin-left: auto !important;
      padding: 0 !important;
    }
  }
  &_menu {
    width: 100%;
    max-width: 200px;
    max-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: #000;
    padding: 0 !important;
    background: #fff;
    border: 1px solid #A39F9F;
    box-shadow: 0 0 0 rgba(0, 0, 0, 1);
    border-radius: 5px;
    overflow: hidden;
    overflow-y: auto;
    position: fixed;
    top: 35px;
    left: 0;
    z-index: 9;
    &--pagination {
      li {
        text-indent: unset;
      }
    }
    & p {
      font-size: 10px;
      font-weight: 300;
      color: #000;
      width: 100%;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 10px;
      cursor: default;
      margin-top: 0;
      margin-bottom: 0 !important;
    }
    & ul {
      text-decoration: none;
      list-style: none;
      width: 100%;
      & li {
        font-size: 12px;
        width: 100%;
        min-height: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 5px 10px;
        transition: all .3s;
        font-weight: 400;
        color: #000;
        &:hover {
          background: #f1f3f7;
          color: #000;
          & svg {
            color: #5C0089;
          }
        }
      }
    }
    &--pagination {
      ul {
        padding: 0;
        li {
          height: unset;
          padding: 10px 0;
          display: block;
          text-align: center;
        }
      }
    }
    &__item {
      &--active {
        background: #f1f3f7;
        color: #000;
        & svg {
          color: #5C0089 !important;
        }
      }
    }
  }
  &_search {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    input {
      text-indent: 35px !important;
      margin: 0;
      line-height: 100%;
      width: 100%;
    }
    &_icon {
      margin-right: 10px;
      margin-left: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        font-size: 17px;
        color: #666;
      }
    }
  }
  &__overlay {
    width: 100%;
    height: 100%;
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
  }
}
