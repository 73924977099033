.billing_history {
  width: 100%;
  height: 100%;
  position: relative;
  &_in {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    .table {
      thead {
        height: 40px !important;
        background-color: #fff !important;
        color: #000 !important;
        font-weight: 300 !important;
        font-size: 12px !important;
        border-bottom: 1px solid #f1f3f7 !important;
        box-shadow: none;
        tr {
          box-shadow: none !important;
        }
        th {
          background-color: #fff !important;
        }
      }
      .table_body {
        td p {
          min-height: unset;
          line-height: unset;
          margin-bottom: 6px;
          padding-left: 10px;
          font-size: 13px;
        }
        tr {
          height: 70px;
        }
      }
    }
    &_invoice {
      width: 100%;
      a {
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 13px;
        color: #007aff;
        transition: all .3s ease;
        cursor: pointer;
        text-align: left;
        padding-left: 10px;
        &:hover {
          color: #5C0089;
        }
      }
    }
    &_actions {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      margin-bottom: 25px;
      border-radius: 16px;
      background: #fff;
      button {
        border: 1px solid #5C0089;
        color: #fff;
        transition: all .3s;
        font-size: 13px;
        font-weight: 300;
        height: 34px;
        letter-spacing: .055em;
        padding: 4px 16px;
        min-width: 180px;
        cursor: pointer;
        box-sizing: border-box;
        background: #5C0089;
        border-radius: 26px;
        margin-left: 15px;
        text-transform: lowercase;
        &:hover {
          background: #f0b101;
          color: #fff;
          border: 1px solid #f0b101;
        }
      }
      .search_input {
        width: 100%;
      }
      &_calendar {
        min-width: 230px;
        margin-left: 15px;
        .date_time_picker_wrapper {
          margin-top: 13px;
        }
        .date_time_picker {
          background: transparent;
        }
        .orders_date_time_picker {
          border: 1px solid #000 !important;
          transition: all .3s ease;
          &:hover {
            border: 1px solid #5C0089 !important;
          }
        }
        .date_time_picker.orders__date_time_picker {
          height: 40px !important;
          border-radius: 16px;
          background: transparent;
          & input {
            font-size: 16px !important;
            font-weight: 600;
          }
        }
      }
      &_export {
        min-width: 35px;
        min-height: 35px;
        max-width: 30px;
        max-height: 30px;
        margin-left: 15px;
        background: #5C0089;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all .3s ease;
        &:hover {
          background: #f0b101;
        }
        svg {
          color: #fff;
        }
      }
    }
    .date_time_picker.orders__date_time_picker {
      height: 40px !important;
      border-radius: 16px !important;
      background: #fff;
      border: 1px solid #aaa;
      & input {
        font-size: 16px !important;
        font-weight: 600;
      }
    }
    &_center {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      text-align: center;
      font-size: 13px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .billing_history_in_actions {
    margin-bottom: 10px;
  }
  .billing_history_in {
    padding: 10px;
  }
}
@media only screen and (max-width: 600px) {
  .billing_history_in_actions {
    flex-wrap: wrap;
    .search {
      width: 100%
    }
  }
  .billing_history_in_actions_calendar {
    margin-left: 0;
  }
  .billing_history_in_actions button {
    font-size: 16px;
    min-width: 30px;
    height: 30px;
    border-radius: 100px;
    padding: 0;
    margin-left: 0;
  }
  .billing_history_in_actions_export {
    min-width: 30px;
    min-height: 30px;
    max-width: 30px;
    max-height: 30px;
    margin-left: 0;
    margin-top: 2px;
  }
}

@media only screen and (max-width: 800px) {
  .billing_history_in_invoice a {
    font-size: 12px;
  }
  .billing_history_in .table .table_body td p {
    padding-left: 0;
  }
  .billing_history_in_invoice a {
    padding-left: 0;
  }
}
@media only screen and (max-width: 600px) {
  .billing_history_in_invoice a {
    font-size: 12px;
  }
}