.subscriptions {
  width: 100%;
  height: 100%;
  position: relative;
  .table_body tr svg {
    height: 18px;
  }
  &_btn {
    color: #147efb;
    transition: all .3s;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .055em;
    padding: 17px 0;
    cursor: pointer;
    box-sizing: border-box;
    background: none;
    width: 300px;
    border: none;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &--bg {
      padding: 17px 24px;
      width: unset !important;
      text-align: center !important;
      color: #fff;
      background: rgba(#5c0089, 0.5);
      border-radius: 26px;
      &:hover {
        background: #f0b101;
        color: #fff !important;
      }
    }
    &:hover {
      color: #5C0089;
    }
  }
  &_cancelsAt {
    width: 100%;
    padding: 5px 0 15px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    p {
      font-weight: 400;
      color: red;
      font-size: 13px;
      line-height: 22px;
      text-align: left;
    }
    span {
      margin-right: 5px;
      background: rgba(red, 0.3) !important;
      color: red !important;
      padding: 5px 16px;
      border-radius: 6px;
      font-size: 13px;
    }
  }
  &_in {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 20px;
    .table {
      thead {
        height: 40px !important;
        background-color: #fff !important;
        color: #000 !important;
        font-weight: 300 !important;
        font-size: 12px !important;
        border-bottom: 1px solid #f1f3f7 !important;
        box-shadow: none;
        tr {
          box-shadow: none !important;
        }
        th {
          background-color: #fff !important;
        }
      }
    }
    .table_body {
      td p {
        min-height: unset;
        line-height: unset;
        margin-bottom: 6px;
        padding-left: 10px;
        font-size: 13px;
      }
      tr {
        height: 70px;
      }
    }
    &_status {
      min-height: 24px !important;
      padding:  4px 12px !important;
      text-transform: lowercase;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      border: none !important;
      margin-top: 0;
      font-size: 12px !important;
      transition: all .3s ease;
      font-weight: 400;
      &--plan {
        background: rgba(#5C0089, 0.1) !important;
        color: #5C0089 !important;
      }
      &--active, &--paid {
        background: rgba(green, .3) !important;
        color: green !important;
      }
      &--pending {
        background: rgba(128, 128, 128, 1) !important;
        color: #fff !important;
      }
      &--past_due {
        background: rgba(red, 0.3) !important;
        color: red !important;
      }
      &--pay {
        background: none !important;
        color: #007aff !important;
        cursor: pointer;
        transition: all .3s ease;
        width: 150px;
        padding: 0;
        &:hover {
          color: #5C0089 !important;
        }
      }
      &--canceled {
        background: none !important;
        color: #666 !important;
        cursor: pointer;
        &:hover {
          color: red !important;
        }
      }
    }
    &_center {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      text-align: center;
      font-size: 13px;
    }
    &_payment {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &_name {
        font-size: 13px;
        color: #000;
        font-weight: 300;
        padding-left: 5px;
      }
      &_img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        img {
          background-position: center;
          object-position: center;
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .subscriptions_btn {
    font-size: 12px;
    height: 30px;
  }
  .subscriptions_cancelsAt {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: 0;
    span {
      margin-bottom: 5px;
      font-size: 12px;
    }
    p {
      font-size: 12px;
    }
  }
}
@media only screen and (max-width: 600px) {
  .subscriptions_btn {
    font-size: 12px;
    height: 30px;
    justify-content: flex-start;
    padding: 25px 0 10px 0;
    width: unset;
    &--bg {
      padding: 17px 24px !important;
    }
  }
}
