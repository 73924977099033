.table {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  display: block;
  border-collapse: collapse;
  &_loading {
    min-height: 160px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .content_loading {
      padding: 10px;
    }
  }
  &_title {
    width: 100%;
    font-size: 15px;
    font-weight: 600;
    padding: 20px 0 5px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #f8faff;
    text-transform: uppercase;
    color: #f0b101;
  }
  &_header {
    width: 100%;
    height: 100px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
    background: transparent;
    tr {
      background: transparent;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
      border-radius: 26px;
    }
    th {
      background: #f1f3f7;
      color: #000;
      font-weight: 400;
      font-size: 12px;
      line-height: 19px;
      letter-spacing: 0.02em;
      text-align: left;
      padding-left: 15px;
      padding-right: 15px;
      &:first-child {
        border-top-left-radius: 26px;
        border-bottom-left-radius: 26px;
      }
      &:last-child {
        border-top-right-radius: 26px;
        border-bottom-right-radius: 26px;
      }
      svg {
        font-size: 16px;
        cursor: pointer;
        justify-content: center;
        margin-bottom: -4px;
        padding: 1px;
        color: #999;
        margin-left: 5px;
      }
    }
    &--sorted {
      color: #5c0089 !important;
    }
  }
  &_body {
    width: 100%;
    &_menu {
      cursor: pointer;
    }
    tr {
      background-color: #fff;
      color: #000;
      font-weight: 300;
      font-size: 12px;
      border-bottom: 1px solid #f1f3f7 !important;
      &:last-child {
        border-bottom: transparent !important;
      }
      & svg {
        font-size: 17px;
        margin-left: 5px;
      }
    }
    tr.table--has_route {
      transition: all .3s ease;
      td > * {
        cursor: pointer;
      }
      td:first-child {
        border-top-left-radius: 26px;
        border-bottom-left-radius: 26px;
      }
      td:last-child {
        border-top-right-radius: 26px;
        border-bottom-right-radius: 26px;
      }
      &:hover {
        cursor: pointer;
        background: #f1f3f7;
        border-bottom: 1px solid transparent;
      }
    }
    td {
      position: relative;
      padding: 2px 5px;
      & p {
        min-height: 100px;
        cursor: default;
        display: flex;
        align-items: center;
        padding-left: 15px;
        color: #000;
        font-weight: 300;
        font-size: 12px;
        line-height: 20px;
      }
    }
    &_no_data {
      width: 100%;
    }
  }
  &__avatar {
    display: flex;
    align-items: center;
    padding: 0 10px 0 8px;
    font-size: 12px;
    color: #28263B;
    font-weight: 400;
    img {
      min-width: 40px;
      max-width: 40px;
      min-height: 40px;
      max-height: 40px;
      border-radius: 50%;
      margin-right: 12px;
      object-fit: cover;
    }
  }
  &__rating {
    display: flex;
    align-items: center;
    p {
      padding-left: 0 !important;
    }
    & svg {
      font-size: 22px !important;
      margin-top: 2px;
      color: #f0b101;
      margin-right: 10px;
      margin-left: 10px !important;
    }
  }
}

@media only screen and (max-width: 800px) {
  .table {
    th {
      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
    &_body {
      tr.table--has_route {
        td:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        td:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
  .table_header {
    height: 58px;
    th {
      padding: 0 5px;
    }
  }
  .table_header th {
    font-size: 10px;
    //padding: 10px 5px 10px 10px;
  }
  .table_body tr {
    font-size: 10px;
    padding: 10px 5px 10px 10px;
  }
  .table_body td p {
    font-size: 10px;
    padding-left: 5px;
  }
  .table__rating svg {
    font-size: 16px !important;
  }
}
