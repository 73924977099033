.account_payment_methods {
  width: 100%;
  height: 100%;
  position: relative;
  &_btn {
    border: 1px solid #5C0089;
    color: #fff;
    transition: all .3s;
    font-size: 13px;
    font-weight: 400;
    height: 34px;
    letter-spacing: .055em;
    padding: 4px 16px;
    min-width: 180px;
    cursor: pointer;
    box-sizing: border-box;
    background: #5C0089;
    border-radius: 26px;
    margin-left: 15px;
    text-transform: lowercase;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: #fff;
      font-size: 22px;
    }
    &:hover {
      background: #f0b101;
      color: #fff;
      border: 1px solid #f0b101;
    }
  }
  &_in {
    width: 100%;
    &_card_btn {
      width: calc(50% - 10px);
      min-height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
      transition: all .3s ease;
      outline: 1px dashed #5C0089;
      outline-offset: -1px;
      border: 0;
      background-color: #f1f3f7;
      border-radius: 8px;
      &:hover {
        background: rgba(#5C0089, .1);
        transform: scale(1.01);
      }
      p {
        font-size: 13px;
        color: #5C0089;
        font-weight: 300;
        transition: all .3s ease;
        margin-bottom: 10px;
      }
      svg {
        font-size: 30px;
        color: #5C0089;
        margin-bottom: 5px;
        transition: all .3s ease;
      }
    }
    &_wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    &_descrip {
      width: 100%;
      font-size: 14px;
      color: #000;
      font-weight: 300;
      text-align: left;
      padding-bottom: 20px;
    }
    &_center {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      text-align: center;
      font-size: 13px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .account_payment_methods_btn {
    font-size: 16px;
    min-width: 30px;
    height: 30px;
    border-radius: 100px;
    padding: 0;
    margin-left: 0;
  }
  .account_payment_methods_in_descrip {
    padding-bottom: 20px;
  }
  .billing_credit_card {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 600px) {
  .account_payment_methods_in_descrip {
    font-size: 12px;
  }
  .account_payment_methods_in_card_btn {
    width: 100%;
    margin: 0;
  }
}
