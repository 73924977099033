.single_marker {
  width: 100%;
  max-width: 500px;
  height: 100%;
  max-height: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  z-index: 7;
  overflow: hidden;
  border: 1px solid #000;
}

@media only screen and (max-width: 1024px) {
  .single_marker {
    width: 95%;
    height: 400px;
  }
}