.settings_company_roles {
  width: 100%;
  height: 100%;
  &_btn {
    border: 1px solid #5C0089;
    color: #fff;
    transition: all .3s;
    font-size: 13px;
    font-weight: 400;
    height: 34px;
    letter-spacing: .055em;
    padding: 4px 16px;
    min-width: 180px;
    cursor: pointer;
    box-sizing: border-box;
    background: #5C0089;
    border-radius: 26px;
    margin-left: 15px;
    text-transform: lowercase;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: #fff;
    }
    &:hover {
      background: #f0b101;
      color: #fff;
      border: 1px solid #f0b101;
    }
  }
  &_in {
    width: 100%;
    .table {
      thead {
        height: 0 !important;
      }
    }
    &__descrip {
      width: 100%;
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      p {
        font-size: 14px;
        color: #000;
        font-weight: 300;
        text-align: justify;
      }
      svg {
        cursor: pointer;
        margin-left: 10px;
        font-size: 16px;
        color: #000;
        transition: all .3s ease;
        &:hover {
          transform: scale(1.1);
          color: #5c0089;
        }
      }
    }
    &_options {
      margin-top: 10px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &_status {
        .status.status--sm {
          p {
            min-height: 24px !important;
          }
        }
      }
      .table_body td p {
        min-height: 60px !important;
      }
      &_loading {
        min-height: 300px;
      }
      & svg {
        cursor: pointer;
      }
      &_name {
        font-size: 13px !important;
        font-weight: 300 !important;
        color: #000;
      }
      &_delete {
        svg {
          transition: all .3s;
          color: #000 !important;
        }
        &:hover {
          svg {
            transform: scale(1.1);
            color: red !important;
          }
        }
      }
    }
  }
  &_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 600px) {
  .settings_company_roles_btn {
    font-size: 16px;
    min-width: 30px;
    height: 30px;
    border-radius: 100px;
    padding: 0;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 500px) {
  .settings_company_roles_in__descrip p {
    font-size: 12px;
  }
}