.settings_integrations_sms {
  width: 100%;
  &_in {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    &_left {
      width: 60%;
      &_wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-bottom: 1px solid #f1f3f7;
        padding: 20px 0;
        &:first-child {
          padding-top: 0;
        }
      }
      ul {
        li {
          list-style: none;
          margin-top: 20px;
        }
      }
      &_branch {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        flex-wrap: wrap;
        span {
          margin-right: 5px;
          background: rgba(#5C0089, 1);
          padding: 3px 10px;
          border-radius: 6px;
          font-size: 12px;
          color: #fff;
          font-weight: 300;
        }
        p {
          font-size: 13px;
          font-weight: 400;
        }
      }
      &_descrip {
        width: 100%;
        font-size: 14px;
        color: #000;
        font-weight: 300;
        text-align: left;
        line-height: 22px;
      }
    }
    &_right {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &_drop_down {
      width: 100%;
      margin-top: 20px;
      &:first-child {
        margin-top: 0;
      }
      .drop_down_search input {
        width: 100%;
        text-transform: unset;
      }
    }
    &_list {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      &_item {
        width: calc(50% - 5px);
        margin-right: 10px;
        list-style: none;
        border: 1px solid #999;
        padding: 10px 15px;
        cursor: pointer;
        transition: all .3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 12px;
        &:nth-child(2n) {
          margin-right: 0 !important;
        }
        &--active {
          border: 1px solid #5C0089;
          background: rgba(#5C0089, 0.05);
        }
        &:hover {
          border: 1px solid #5C0089;
          background: rgba(#5C0089, 0.05);
        }
        img {
          width: 150px;
          height: 50px;
          background-size: cover;
          background-position: center;
          object-fit: contain;
        }
        h3 {
          font-size: 16px;
          font-weight: 400;
          color: #000;
          width: 100%;
          text-align: left;
          margin-top: 10px;
          text-transform: capitalize;
        }
        a {
          text-decoration: none;
          font-size: 12px;
          font-weight: 300;
          color: #999;
          text-align: left;
          margin-top: 5px;
          margin-right: auto;
          cursor: pointer;
          transition: all .3s ease;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          svg {
            color: #999;
            cursor: pointer;
            transition: all .3s ease;
          }
          &:hover {
            color: #007aff;
            svg {
              color: #007aff;
            }
          }
        }
      }
    }
    &_options {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &_loading {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    }
    &_form {
      width: 100%;
      position: relative;
      .create_order_submit_btn {
        width: 150px;
        margin: 20px auto 20px auto;
      }
      .form_header {
        display: none;
      }
      .form_col {
        position: relative !important;
      }
      &_success {
        font-size: 12px;
        font-weight: 600;
        color: green;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        text-align: center;
      }
      &_error {
        font-size: 12px;
        font-weight: 600;
        color: red;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        text-align: center;
      }
      &__btn {
        background: #5C0089 !important;
        color: #fff;
        border: 1px solid #5C0089;
        font-size: 12px;
        width: 100px;
        text-transform: lowercase;
        padding: 8px 14px;
        transition: all .3s ease;
        border-radius: 18px;
        height: 35px;
        cursor: pointer;
        margin: 0 auto;
        &:hover, &:active, &:focus {
          background: #f0b101 !important;
          color: #fff;
          border: 1px solid #f0b101;
        }
      }
    }
  }
  &_image {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &_in {
      width: 250px;
      height: auto;
      position: relative;
      &_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 100px;
        right: 0;
      }
      &_title {
        position: absolute;
        top: 43px;
        left: 50%;
        width: 100%;
        text-transform: capitalize;
        transform: translateX(-50%);
        font-size: 14px;
        font-weight: 400;
        color: #fff;
        cursor: default;
        display: flex;
        align-items: center;
        justify-content: center;
        &_icon {
          margin-left: 5px;
          cursor: pointer;
          svg {
            color: #fff;
          }
        }
      }
      img {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        object-fit: contain;
      }
      &_msg {
        width: 220px;
        margin-bottom: 10px;
        position: relative;
        max-width: 400px;
        padding: 7px 14px;
        border-radius: 10px;
        color: #ffffff;
        background-color: #147efb;
        border-color: #147efb;
        &:before {
          position: absolute;
          content: " ";
          border-radius: 50% 50% 50% 50%;
          border-style: solid;
          border-width: 0 20px;
          bottom: 0;
          clip: rect(20px, 35px, 42px, 0px);
          height: 40px;
          width: 30px;
          left: -50px;
          z-index: 1;
          transform: rotateY(180deg);
          color: #ffffff;
          background-color: transparent;
          border-color: #147efb;
        }
        p {
          color: #fff;
          font-weight: 300;
          font-size: 13px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          position: relative;
          z-index: 2;
        }
      }
    }
  }
}
#tooltip-notification-title {
  width: 250px;
}

@media only screen and (max-width: 800px) {
  .settings_integrations_sms_in_right {
    display: none;
  }
  .settings_integrations_sms_in_left {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .settings_integrations_sms_in_left_descrip {
    font-size: 12px;
  }
  .settings_integrations_sms_in_list_item {
    margin-right: 0;
    width: 100%;
    img {
      width: 100px;
    }
    h3 {
      font-size: 14px;
      margin-top: 0;
    }
  }
}
