.assign_agent_form_item {
  width: 100%;
  padding: 5px 20px;
  background: #f1f3f7;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  border-bottom: 1px solid #d5d2d2;
  cursor: pointer;
  transition: background .3s ease;
  &:hover {
    background: rgba(#5C0089, .1);
  }
  &--hovered {
    background: rgba(#5C0089, .1) !important;
  }
  &:last-child {
    border-bottom: none;
  }
  &--inactive {
    background: rgba(0,0,0, 0.05);
  }
  &--noHover {
    cursor: default !important;
    &:hover {
      background: #f1f3f7 !important;
    }
  }
  &_img {
    min-width: 35px;
    min-height: 35px;
    max-width: 35px;
    max-height: 35px;
    border-radius: 100px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      background-position: center;
      object-fit: contain;
    }
  }
  &_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: auto;
  }
  &_name {
    font-size: 13px;
    font-weight: 300;
    padding-left: 5px;
    margin-right: auto;
  }
  &_username {
    font-size: 13px;
    font-weight: 300;
    padding-left: 5px;
    &_status {
      font-weight: 400;
      padding: 0 8px;
      border-radius: 16px;
      font-size: 10px;
      text-transform: lowercase;
      margin-left: 5px;
      &--free {
        background: rgba(green, .3) !important;
        color: green !important;
      }
      &--busy{
        background: rgba(#5C0089, 0.3) !important;
        color: #5C0089 !important;
      }
    }
  }
  &_noAgents {
    font-weight: 400;
    font-size: 13px;
    width: 100%;
    text-align: center;
    line-height: 22px;
  }
  &_tasks {
    font-size: 12px;
    font-weight: 300;
    padding-left: 10px;
    color: #000;
    line-height: 30px;
    text-transform: lowercase;
    span {
      font-weight: 400;
      padding-left: 5px;
    }
    &--ongoing {
      span {
        font-weight: 400;
        color: #5C0089;
      }
    }
    &--completed {
      span {
        font-weight: 400;
        color: green;
      }
    }
  }
  &_distance {
    font-size: 12px;
    font-weight: 300;
    padding-left: 10px;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span {
      font-weight: 400;
      padding-left: 5px;
    }
    &--time {
      font-weight: 300 !important;
      color: #666 !important;
      font-size: 11px !important;
      padding-left: 5px !important;
    }
  }
  &_status {
    width: 8px;
    height: 8px;
    border-radius: 100px;
    margin-left: 5px;
    &--online {
      background: green;
    }
    &--offline {
      background: #f0b101;
    }
  }
}