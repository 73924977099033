.export {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &_in {
    width: 500px;
    padding: 30px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 8px;
    img {
      width: 150px;
      object-fit: contain;
      background-position: center;
    }
    h2 {
      font-size: 16px;
      color: #000;
      font-weight: 400;
      text-align: center;
      width: 100%;
      padding-top: 20px;
      line-height: 28px;
    }
    &_loading {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      margin: 15px 0 0 0;
    }
    &_wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 3px 10px;
      margin: 15px 0 0 0;
      height: 30px;
      &_txt {
        font-size: 14px;
        font-weight: 400;
        margin-right: 10px;
      }
      &--success {
        svg {
          font-size: 25px;
          color: green;
        }
      }
      &--fail {
        svg {
          font-size: 25px;
          color: red;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .export_in {
    width: 95%;
  }
}