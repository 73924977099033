.managers {
  width: 100%;
  &_in {
    background: #fff;
    box-shadow: 0 20px 30px -18px rgba(4, 15, 21, 0.1);
    border-radius: 16px;
    padding: 20px 20px 10px 20px;
    margin-bottom: auto;
    &_actions {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      margin-bottom: 25px;
      border-radius: 16px;
      background: #f1f3f7;
      button {
        border: 1px solid #5C0089;
        color: #fff;
        transition: all .3s;
        font-size: 13px;
        font-weight: 400;
        height: 34px;
        letter-spacing: .055em;
        padding: 4px 16px;
        min-width: 180px;
        cursor: pointer;
        box-sizing: border-box;
        background: #5C0089;
        border-radius: 26px;
        margin-left: 15px;
        text-transform: lowercase;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          color: #fff;
          font-size: 22px;
        }
        &:hover {
          background: #f0b101;
          color: #fff;
          border: 1px solid #f0b101;
        }
      }
      &__switcher {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        margin-left: 15px;
      }
    }
    &_freeze_alert {
      width: 500px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      border-radius: 15px;
      padding: 30px 25px;
      z-index: 7;
      &_title {
        font-size: 14px;
        font-weight: 400;
        line-height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        color: #444444;
        margin-bottom: 20px;
      }
      &_actions {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &_btn {
        &_submit {
          background: #5C0089;
          color: #fff;
          border: 1px solid #5C0089;
          font-size: 13px;
          font-weight: 400;
          text-transform: lowercase;
          padding: 7px 14px;
          transition: all .3s ease;
          border-radius: 18px;
          height: 35px;
          cursor: pointer;
          &--freeze {
            background: red;
            border: 1px solid red;
          }
          &--unfreeze {
            background: green;
            border: 1px solid green;
          }
          &:hover {
            background: #f0b101;
            color: #fff;
            border: 1px solid #f0b101;
          }
        }
        &_cancel {
          display: flex;
          margin-right: auto;
          font-size: 13px;
          font-weight: 400;
          text-decoration: none;
          transition: all .3s;
          color: #000;
          background: none;
          border: none;
          cursor: pointer;
          text-transform: lowercase;
          &:hover {
            color: #5c0089;
          }
        }
      }
      &_overlay {
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.3);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 6;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .managers_in_actions {
    margin-bottom: 10px;
  }
  .managers_in {
    padding: 10px;
  }
}
@media only screen and (max-width: 600px) {
  .managers_in_actions {
    flex-wrap: wrap;
    .search {
      width: calc(100% - 35px);
    }
  }
  .managers_in_actions__switcher {
    display: none;
  }
  .managers_in_actions button {
    font-size: 16px;
    min-width: 30px;
    height: 30px;
    border-radius: 100px;
    padding: 0;
    margin-left: 0;
  }
  .more_popup__item {
    font-size: 12px;
    padding: 8px 12px;
  }
  .managers_in_freeze_alert {
    width: 95%;
  }
}
