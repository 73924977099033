.agent_card {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: calc(20% - 8px);
  min-height: 260px;
  border-radius: 34px;
  background: #FFFFFF;
  border: 1px solid #000;
  box-shadow: 0 0 0 rgba(0, 0, 0, 1);
  padding: 20px 0 15px 0;
  position: relative;
  margin-bottom: 10px;
  margin-right: 10px;
  &:nth-child(5n) {
    margin-right: 0 !important;
  }
  &_more {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 5px;
    right: 20px;
    cursor: pointer;
    svg {
      position: relative;
      font-size: 20px;
      color: #000;
      transform: rotate(90deg);
    }
    .more_popup {
      top: 20px !important;
      right: -3px !important;
    }
  }
  &_status {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 12px;
    left: 20px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 11px;
    margin-left: auto;
    z-index: 2;
    &::before {
      content: '';
      margin-right: 5px;
      width: 8px;
      height: 8px;
      display: block;
      border-radius: 100px;
    }
    &--online {
      &::before {
        background-color: green;
      }
    }
    &--offline {
      &::before {
        background-color: #e0a500;
      }
    }
  }
  &_state {
    z-index: 1;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 11px;
    margin-left: auto;
  }
  &_rating {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(calc(-50%));
    font-size: 12px;
    color: #28263B;
    font-weight: 600;
    line-height: 22px;
    & svg {
      font-size: 20px !important;
      color: #f0b101;
      margin-right: 5px;
      margin-left: 5px;
    }
  }
  &_img {
    min-width: 70px;
    min-height: 70px;
    max-width: 70px;
    max-height: 70px;
    overflow: hidden;
    border-radius: 100px;
    margin-top: 20px;
    & img {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      object-fit: fill;
    }
  }
  &_name {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #000;
    margin-top: 10px;
  }
  &_username {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    font-size: 13px;
    text-align: center;
    margin-top: 5px;
    color: #000;
  }
  &_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    p {
      width: 100%;
      font-weight: 300;
      font-size: 13px;
      text-align: center;
      color: #000;
    }
  }
  &_btn {
    border: 1px solid #000;
    color: #000;
    transition: all .3s;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: .055em;
    padding: 6px 12px;
    cursor: pointer;
    box-sizing: border-box;
    background: transparent;
    border-radius: 26px;
    margin-top: 20px;
    text-transform: lowercase;
    &:hover {
      border: 1px solid #f0b101;
      background: #f0b101;
      color: #fff;
    }
  }
  &_loading {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: calc(20% - 12px);
    box-shadow: 0 0 0 rgba(0, 0, 0, 1);
    margin-bottom: 15px;
    margin-right: 15px;
    &:nth-child(5n) {
      margin-right: 0 !important;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .agent_card_state {
    .status--sm p {
      font-size: 10px !important;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .agent_card {
    width: calc(50% - 5px);
    &:nth-child(5n) {
      margin-right: 10px !important;
    }
    &:nth-child(2n) {
      margin-right: 0 !important;
    }
  }
  .agent_card_loading {
    width: calc(50% - 8px);
    &:nth-child(5n) {
      margin-right: 15px !important;
    }
    &:nth-child(2n) {
      margin-right: 0 !important;
    }
  }
}
@media only screen and (max-width: 600px) {
  .more_popup__item {
    font-size: 12px;
    padding: 8px 12px;
  }
}
@media only screen and (max-width: 400px) {
  .agent_card {
    width: calc(100%);
    &:nth-child(5n) {
      margin-right: 10px !important;
    }
    &:nth-child(2n) {
      margin-right: 10px !important;
    }
    &:nth-child(1n) {
      margin-right: 0 !important;
    }
  }
  .agent_card_loading {
    width: calc(100%);
    &:nth-child(5n) {
      margin-right: 10px !important;
    }
    &:nth-child(2n) {
      margin-right: 10px !important;
    }
    &:nth-child(1n) {
      margin-right: 0 !important;
    }
  }
}