.notifications {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  align-self: center;
  justify-self: center;
  justify-items: center;
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
  &_in {
    position: relative;
    margin-left: 5px;
    border: none !important;
    &_label {
      position: absolute;
      top: 0px;
      right: -5px;
      width: 20px;
      height: 20px;
      border-radius: 100px;
      font-size: 10px;
      padding: 7px;
      color: #000;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      background: #fff;
      z-index: 2;
  }
    & svg {
      margin-top: 4px;
      font-size: 37px;
      margin-left: 2px;
      color: #5c0089;
      transition: all .3s;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  &_container {
    min-width: 400px;
    max-width: 400px;
    height: 100%;
    margin-right: auto;
    margin-left: 18px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    background: #f8faff;
    position: fixed;
    right: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: 5px 0;
    top: 0;
    z-index: 7;
    transition: all .3s;
    transform: translateX(calc(50% + 450px));
    &--animate {
      animation: fromRight 0.3s;
      transform: translateX(0);
    }
    &_overlay {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.3);
      transition: all 0.3s;
      z-index: 6;
    }
    &__icon {
      font-size: 14px;
      font-weight: 600;
      color: #000;
      & span {
        font-size: 13px;
        font-weight: 400;
        color: #147efb;
        cursor: pointer;
        transition: all .3s ease;
        &:hover {
          color: #5C0089;
        }
      }
      & svg {
        font-size: 16px;
        color: #000;
      }
    }
    &__actions {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 15px 35px 15px;
      &_in {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    & ul {
      width: 100%;
      height: calc(100vh - 40px);
      list-style: none;
      overflow: auto;
      padding-top: 5px;
    }
    &_list {
      padding: 25px 17px 25px 20px;
      cursor: default;
      transition: all .3s;
      background: transparent;
      border-bottom: 1px solid #e2e3e5;
      position: relative;
      &--hovered {
        cursor: pointer;
        background: rgba(#5C0089, 0.1);
        border-bottom: 1px solid #A39F9F;
      }
      &_heading {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding-top: 5px;
        &_newIcon {
          position: absolute;
          top: 15px;
          right: 15px;
          &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            width: 8px;
            height: 8px;
            background: #5C0089;
            border-radius: 100px;
            transform: translateY(-50%);
          }
        }
      }
      &_order {
        color: #000;
        font-weight: 300;
        font-size: 12px;
        text-transform: lowercase;
        margin-right: auto;
        &--bold {
          color: #000;
          font-weight: 400;
        }
      }
      &_time {
        font-weight: 300;
        color: #000;
        font-size: 11px;
      }
      &_info {
        font-weight: 300;
        color: #000;
        font-size: 12px;
        padding-top: 10px;
        &--bold {
          font-weight: 400;
          color: #000;
        }
      }
    }
  }
}

@keyframes fromRight {
  0% {
    transform: translateX(calc(50% + 450px));
  }
  100% {
    transform: translateX(0);
  }
}

@media only screen and (max-width: 1024px) {
  .notifications {
    padding: 0 !important;
    margin-bottom: 0 !important;
    margin-top: 0;
  }
}
@media only screen and (max-width: 400px) {
  .notifications_container {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
}