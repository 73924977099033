.container {
  background: #f1f3f7;
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  height: calc(100% - 60px);
  overflow-y: auto;
  padding: 0 20px 0 20px;
  width: 100%;
  &--short {
    height: calc(100% - 115px);
  }
  &--disable_inner_padding {
    padding: 0 0 0 80px;
  }
  &--flex_dir_clmn {
    flex-direction: column;
  }
}
@media only screen and (max-width: 1024px) {
  .container {
    width: 100%;
    min-width: 100% !important;
    padding: 0 10px;
    height: calc(100% - 40px);
  }
}

