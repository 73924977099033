.order_history {
  width: 100%;
  padding: 10px 20px 0 20px;
  margin-bottom: auto;
  &_title {
    font-size: 14px;
    font-weight: 400;
    color: #000;
    text-transform: capitalize;
    margin: 20px 0;
    &:nth-child(1) {
      margin-top: 0;
    }
  }
  &_in {
    width: 100%;
    &_row {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      border-bottom: 1px solid rgba(0,0,0,.1);
      padding: 0 0 10px 0;
      & svg {
        font-size: 18px;
        color: #000;
        margin-right: 8px;
        margin-top: 10px;
      }
      &_wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
      }
      &_label {
        font-size: 12px;
        font-weight: 300;
        color: #000;
        margin-top: 10px;
      }
      &_descrip {
        font-size: 13px;
        font-weight: 400;
        color: #000;
        margin-top: 5px;
        margin-left: 27px;
      }
      &_location {
        font-size: 13px;
        font-weight: 400;
        color: #000;
        margin-top: 5px;
        margin-left: 27px;
        line-height: 20px;
      }
      &_status {
        margin-left: auto;
        margin-top: 5px;
        height: 24px !important;
        color: #fff;
        padding: 0 10px;
        text-transform: lowercase;
        display: flex;
        font-size: 12px;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        min-width: 80px;
        &--unassigned {
          background: rgba(128, 128, 128, 1);
          color: #fff !important;
          border: 1px solid rgba(128, 128, 128, 1);
        }
        &--created {
          background: rgba(128, 128, 128, 1);
          border: 1px solid rgba(128, 128, 128, 1);
          color: #fff !important;
        }
        &--assigned{
          background: #f0b101;
          color: #fff !important;
          border: 1px solid #f0b101;
        }
        &--accepted {
          background: darkorange;
          color: #fff !important;
          border: 1px solid darkorange;
        }
        &--started {
          background: rgba(92, 0, 137, 1);
          border: 1px solid rgba(92, 0, 137, 1);
          color: #fff !important;
        }
        &--picked_up {
          background: rgba(0, 0, 139, 1);
          border: 1px solid rgba(0, 0, 139, 1);
          color: #fff !important;
        }
        &--on_way {
          background: rgba(0, 0, 255, 1);
          border: 1px solid rgba(0, 0, 255, 1);
          color: #fff !important;
        }
        &--delivered {
          background: rgba(0, 128, 0, 1);
          border: 1px solid rgba(0, 128, 0, 1);
          color: #fff !important;
        }
        &--canceled, &--failed {
          background: rgba(255, 0, 0, 1);
          color: #fff !important;
          border: 1px solid rgba(255, 0, 0, 1);
        }
      }
    }
  }
  &_list {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    margin-top: 5px;
    margin-bottom: 10px;
    &:first-child {
      margin-top: 10px;
    }
    &_in {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      padding-left: 35px;
      &_wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 5px 0;
        &_title {
          font-size: 13px;
          font-weight: 400;
          color: #000;
        }
        &_status {
          margin-left: auto;
          height: 24px !important;
          color: #fff;
          padding: 0 10px;
          font-size: 12px;
          text-transform: lowercase;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          min-width: 80px;
          &--unassigned {
            background: rgba(128, 128, 128, 1);
            color: #fff !important;
            border: 1px solid rgba(128, 128, 128, 1);
          }
          &--created {
            background: rgba(128, 128, 128, 1);
            border: 1px solid rgba(128, 128, 128, 1);
            color: #fff !important;
          }
          &--assigned{
            background: #f0b101;
            color: #fff !important;
            border: 1px solid #f0b101;
          }
          &--accepted {
            background: darkorange;
            color: #fff !important;
            border: 1px solid darkorange;
          }
          &--started {
            background: rgba(92, 0, 137, 1);
            border: 1px solid rgba(92, 0, 137, 1);
            color: #fff !important;
          }
          &--picked_up {
            background: rgba(0, 0, 139, 1);
            border: 1px solid rgba(0, 0, 139, 1);
            color: #fff !important;
          }
          &--on_way {
            background: rgba(0, 0, 255, 1);
            border: 1px solid rgba(0, 0, 255, 1);
            color: #fff !important;
          }
          &--delivered {
            background: rgba(0, 128, 0, 1);
            border: 1px solid rgba(0, 128, 0, 1);
            color: #fff !important;
          }
          &--canceled, &--failed {
            background: rgba(255, 0, 0, 1);
            color: #fff !important;
            border: 1px solid rgba(255, 0, 0, 1);
          }
        }
        &_label {
          font-size: 12px;
          font-weight: 300;
          color: #000;
          margin-bottom: 5px;
        }
        &_description {
          font-size: 13px;
          font-weight: 400;
          color: #000;
          margin-left: 27px;
          margin-bottom: 5px;
          span {
            font-size: 10px;
            font-weight: 300;
            color: #000;
          }
        }
        &_btn {
          background: none;
          transition: all .3s;
          font-size: 13px;
          border: none;
          cursor: default;
          font-weight: 400;
          color: #000;
          margin-bottom: 1px;
          &--hovered {
            cursor: pointer;
            &:hover {
              color: #5c0089;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .order_history_in_row_status {
    font-size: 11px;
  }
  .order_history_list_in_wrapper_status {
    font-size: 11px;
  }
}