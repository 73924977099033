.billing_products_modal_card {
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 0 20px;
  transition: all .3s ease;
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 22px;
  height: 80%;
  &--recommended {
    background: linear-gradient(150deg, #6a30f5 0%, #9d43fe 100%);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    transition: all .3s ease;
    height: 100%;
    p, h3, button, svg {
      color: #fff !important;
    }
    .billing_products_modal_card_in h3 span {
      color: #fff !important;
    }
    button {
      background: #fff !important;
      color: #5C0089 !important;
      border: 1px solid #fff !important;
      cursor: pointer;
      &:hover {
        background: #f0b101 !important;
        color: #fff !important;
        border: 1px solid #f0b101 !important;
      }
    }
    ul {
      li {
        color: #fff !important;
      }
    }
  }
  &:last-child {
    margin-right: 0;
  }
  &_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10px;
    p {
      font-size: 22px;
      color: #5c0089;
      font-weight: 600;
      margin: 20px 0;
    }
    svg {
      font-size: 35px;
    }
  }
  &_in {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 100%;
    h3 {
      font-size: 60px;
      color: #000;
      font-weight: 600;
      position: relative;
      margin-bottom: 10px;
      span {
        position: absolute;
        top: 40%;
        transform: translateY(-50%);
        left: -40px;
        font-size: 20px;
        font-weight: 400;
        color: #000;
      }
    }
    ul {
      width: 100%;
      li {
        list-style: none;
        color: #5c5c5c;
        font-size: 14px;
        font-weight: 300;
        width: 100%;
        line-height: 32px;
      }
    }
  }
  &_actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    button {
      color: #fff;
      transition: all .3s;
      font-size: 14px;
      height: 50px;
      font-weight: 400;
      letter-spacing: .055em;
      padding: 17px 12px;
      cursor: pointer;
      box-sizing: border-box;
      width: 200px;
      background: #5C0089;
      border-radius: 26px;
      margin: 0 auto;
      border: 1px solid #5C0089;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background: #f0b101;
        color: #fff;
        border: 1px solid #f0b101;
      }
    }
  }
  &_alert {
    width: 400px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 15px;
    padding: 25px 25px;
    z-index: 11;
    &_title {
      font-size: 14px;
      font-weight: 400;
      line-height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      color: #444444;
      margin-bottom: 20px;
    }
    &_actions {
      display: flex;
      align-items: center;
      justify-content: center;
      &_btn {
        &--submit {
          background: #5C0089;
          color: #fff;
          border: 1px solid #5C0089;
          font-size: 13px;
          font-weight: 400;
          text-transform: lowercase;
          padding: 7px 14px;
          transition: all .3s ease;
          border-radius: 18px;
          height: 35px;
          cursor: pointer;
          &--freeze {
            background: red;
            border: 1px solid red;
          }
          &--unfreeze {
            background: green;
            border: 1px solid green;
          }
          &:hover {
            background: #f0b101;
            color: #fff;
            border: 1px solid #f0b101;
          }
        }
        &--cancel {
          display: flex;
          margin-right: auto;
          font-size: 13px;
          font-weight: 400;
          text-decoration: none;
          transition: all .3s;
          color: #000;
          background: none;
          border: none;
          cursor: pointer;
          text-transform: lowercase;
          &:hover {
            color: #5c0089;
          }
        }
      }
    }
    &_overlay {
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.3);
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
    }
  }
}

@media only screen and (max-width: 900px) {
  .billing_products_modal_card {
    width: 400px;
    margin-bottom: 20px;
    height: 450px;
  }
}
@media only screen and (max-width: 600px) {
  .billing_products_modal_card {
    width: 95%;
  }
  .billing_products_modal_card_alert {
    width: 95%;
  }
}