.create_payment_method_form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  padding: 0 20px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  z-index: 8;
  .form_header {
    padding: 15px 0;
  }
  &__cancel_btn {
    margin: 20px 0;
    display: flex;
    height: 100%;
    line-height: 30px;
    font-size: 14px;
    text-decoration: none;
    text-transform: lowercase;
    transition: all .3s;
    color: #444;
    background: none;
    border: none;
    cursor: pointer;
    &:hover {
      color: #5c0089;
    }
  }
  &__submit_btn {
    margin: 20px 0;
    background: #5C0089;
    color: #fff;
    border: 1px solid #5C0089;
    font-size: 14px;
    text-transform: lowercase;
    padding: 7px 10px;
    transition: all .3s ease;
    border-radius: 18px;
    height: 35px;
    cursor: pointer;
    width: 100%;
    &--pending {
      .loading-circle {
        padding: 0;
        height: 100%;
      }
      background: #f0b101;
      color: #fff;
      border: 1px solid #f0b101;
    }
    &:hover {
      background: #f0b101;
      color: #fff;
      border: 1px solid #f0b101;
    }
  }
  &__error {
    font-size: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: red;
    font-weight: 600;
  }
  .form {
    width: 100%;
  }
}

@media only screen and (max-width: 800px) {
  .create_payment_method_form {
    width: 80%;
  }
}
@media only screen and (max-width: 600px) {
  .create_payment_method_form__submit_btn {
    font-size: 12px;
  }
  .create_payment_method_form__cancel_btn {
    font-size: 12px;
  }
}
@media only screen and (max-width: 400px) {
  .create_payment_method_form {
    width: 95%;
  }
}