.orders_modal {
  width: 400px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #f1f3f7;
  height: 100%;
  transition: all 0.3s;
  position: relative;
  &_fixed {
    position: fixed;
    bottom: 80px;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    &_btn {
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: rgba(#5C0089, .9);
      line-height: 100%;
      color: #fff;
      border: none;
      font-size: 13px;
      font-weight: 400;
      text-transform: lowercase;
      padding: 0 10px;
      transition: all .3s ease;
      border-radius: 18px;
      cursor: pointer;
      height: 30px;
      &:hover {
        transform: scale(1.05);
        background: #f0b101;
        color: #fff;
      }
      svg {
        font-size: 18px;
        color: #fff;
        margin-right: 10px;
      }
    }
  }
  &__search {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    background-color: #f1f3f7;
    min-height: 53px;
    padding: 10px 10px 5px 10px;
    & input, p, a {
      font-weight: 300;
      font-size: 14px;
      color: #000;
      height: 40px;
      width: 100%;
      padding: 7px 5px;
      text-indent: 5px;
      border-radius: 16px;
      border: none;
      background: #e2e3e5;
      &::placeholder {
        color: #bbb6b6;
        font-weight: 200;
        font-size: 13px;
      }
      &:focus {
        outline: none;
      }
    }
    & label {
      display: none;
      font-size: 30px;
      position: absolute;
      padding: 7px 5px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      color: #000;
      margin-right: 10px;
      & svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 45px;
        color: #5c0089 !important;
        margin-left: 10px;
        font-size: 20px;
        cursor: pointer;
      }
    }
    & a {
      text-decoration: none;
      cursor: pointer;
      width: 100%;
      height: 35px;
      display: flex;
      align-items: center;
      transition: all .3s;
      background: rgba(92, 0, 137, 0.1);
      color: #5c0089;
      border: 1px solid transparent;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      &:hover {
        background: rgba(240, 177, 1, 0.3);
        border-radius: 16px;
        color: #5c0089;
        outline: none;
        border: 1px solid rgba(240, 177, 1, 0.3);
      }
    }
  }
  &__tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    padding: 0 5px 10px 5px;
    button {
      &:nth-child(1) {
        border-left: none;
      }
    }
  }
  &--animate {
    animation: animation3 0.3s ease-in-out;
  }
}

@keyframes animation3 {
  0% {
    transform: translateY(-400px);
  }
  100% {
    transform: translateX(0);
  }
}
@media only screen and (max-width: 1024px) {
  .orders_modal {
    width: 100%;
    height: calc(100% - 50px);
  }
  .orders_modal__search {
    padding: 5px 10px 5px 10px;
  }
}

@media only screen and (max-width: 400px) {
  .orders_modal__search {
    min-height: 35px;
    height: 35px;
    margin-top: 2.5px;
  }
  .orders_modal__search input, .orders_modal__search p, .orders_modal__search a {
    height: 30px;
  }
}