.stop_watch {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  cursor: default;
  margin-left: auto;
  & p {
    font-size: 12px;
    text-transform: lowercase;
    color: #676565;
    font-weight: 400;
  }
  &_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    padding: 2px 8px;
    border-radius: 6px;
    border: 1px solid #676565;
    & p, & span {
      color: green;
      margin: 0;
      font-size: 12px;
      text-transform: lowercase;
      font-weight: 400;
    }
    & span {
      margin: 0 1.5px;
    }
    &--yellow {
      & p, & span {
        color: #f0b101 !important;
      }
      border: 1px solid #f0b101 !important;
    }
    &--gray {
      border: 1px solid #676565 !important;
      & p, & span {
        color: #676565 !important;
      }
    }
    &--red {
      border: 1px solid red !important;
      & p, & span {
        color: red !important;
      }
    }
  }
  &_actions {
    display: flex;
    align-items: center;
    justify-content: center;
    & button {
      display: flex;
      height: 100%;
      font-size: 14px;
      text-decoration: none;
      margin-top: 5px;
      transition: all .3s;
      color: #676565;
      background: none;
      border: none;
      cursor: pointer;
      &:first-child {
        margin-right: 10px;
      }
      &:last-child {
        margin-left: 10px;
      }
      &:hover {
        color: #5c0089;
      }
    }
  }
}
@media only screen and (max-width: 400px) {
  .stop_watch {
    margin-right: auto;
    margin-left: 0;
    margin-bottom: 5px;
  }
  .stop_watch_wrapper {
    p, span {
      font-size: 11px;
    }
  }
}
