$offset: 187;
$duration: 1.4s;

.loading-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 100%;
  width: 100%;
  &__spinner {
    animation: rotator $duration linear infinite;
  }

  &__path {
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash $duration ease-in-out infinite,
      colors ($duration * 4) ease-in-out infinite;
  }
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

@keyframes colors {
  0% {
    stroke: #666;
  }
  25% {
    stroke: #f0b101;
  }
  50% {
    stroke: #5C0089;
  }
  75% {
    stroke: blue;
  }
  100% {
    stroke: green;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: $offset/4;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}
