.decoration {
  position: absolute;
  left: 5px;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 11px;
  margin-top: 13px;
  top: -5px;
  &_long {
    position: absolute;
    left: 5px;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 2px;
    margin-top: 13px;
    top: 0;
  }
  &--start {
    display: block;
    background: #fff;
    width: 5px;
    height: 5px;
    border-radius: 100px;
    position: relative;
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 100%;
      background-color: #fff;
      left: 50%;
      width: 6px;
      height: 6px;
      border-radius: 100px;
      border: 3px solid #999;
      transform: translate(-50%, -50%);
    }
  }
  &--body {
    display: block;
    width: 1px;
    height: 100%;
    border-right: 2px dashed #000;
    margin-right: .5px;
  }
  &--end {
    display: block;
    background: rgba(#5C0089, 1);
    width: 8px;
    height: 9px;
    border-radius: 100px;
    margin-bottom: 2px;
  }

}