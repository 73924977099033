.date_time_picker {
  width: 100%;
  position: relative;
  &_in {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all .3s;
    overflow: hidden;
    & input {
      width: 100%;
      height: 100%;
      color: #444;
      font-size: 14px;
      font-weight: 400;
      border: none !important;
      cursor: pointer;
      border-radius: 5px;
      background: transparent;
      z-index: 1;
      margin-bottom: 0 !important;
      &:focus {
        outline: none;
        cursor: text;
        border: none;
      }
      &:hover {
        border: none;
      }
    }
    & label {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 0 0 13px !important;
      margin: 0 !important;
      & svg {
        color: #666;
        font-size: 14px;
        cursor: pointer;
        background: transparent;
      }
    }
  }
  &_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: #fff;
    color: #000;
    padding: 15px 10px;
    border: 1px solid #A39F9F;
    border-radius: 25px;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 8;
    &_calendar {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 5px;
      &__double {
        padding: 0 30px;
        .date_time_picker_wrapper_btn {
          margin-left: auto;
          margin-top: 10px;
          margin-bottom: 0;
          width: 200px !important;
        }
      }
    }
    &_time {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 0 25px;
      &_double {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .drop_down_search input {
          text-indent: 10px !important;
        }
        .drop_down {
          margin-right: 5px;
          &:last-child {
            margin-right: 0;
          }
        }
        .drop_down_in {
          max-height: 25px;
          margin-right: 5px;
          .drop_down__input {
            font-size: 12px;
            &::placeholder {
              font-size: 12px;
            }
          }
          .drop_down__label svg {
            font-size: 15px;
          }
        }
      }
      &_single {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 80%;
        .drop_down_search input {
          text-indent: 10px !important;
        }
        .drop_down {
          margin-right: 5px;
          &:last-child {
            margin-right: 0;
          }
        }
        .drop_down_in {
          max-height: 25px;
          margin-right: 5px;
          .drop_down__input {
            font-size: 12px;
            &::placeholder {
              font-size: 12px;
            }
          }
          .drop_down__label svg {
            font-size: 15px;
          }
        }
      }
    }
    &_btn {
      padding: 2px 15px;
      width: 100%;
      height: 25px;
      background: #5c0089;
      font-weight: 300;
      text-transform: lowercase;
      color: #fff;
      font-size: 12px;
      transition: all .3s;
      cursor: pointer;
      border: 1px solid #5c0089;
      border-radius: 16px;
      margin: 10px auto 0 auto;
      &:hover {
        background: #fff;
        color: #5c0089;
        border: 1px solid #5c0089;
      }
    }
    .react-calendar {
      font-family: inherit !important;
      background: transparent;
      border: none;
      box-shadow: none;
      width: 100%;
    }
    .react-calendar__month-view__weekdays {
      font-size: 25px;
      color: #666;
      font-weight: 400;
      font-family: "Lexend", "sans-serif";
    }
    .react-calendar__month-view__weekdays__weekday {
      & abbr {
        text-decoration: none;
        font-size: 9px !important;
      }
    }
    .react-calendar__tile--active {
      border-radius: 5px;
      background: #5c0089;
    }
    .react-calendar__tile {
      line-height: 1px;
      border: 1px solid transparent;
    }
    .react-calendar__navigation {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 0;
      margin-bottom: 15px !important;
      margin-top: 5px !important;
      & button {
        font-size: 20px;
        color: #000;
        & span {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          text-transform: uppercase;
          font-weight: 600;
          margin-top: 3px;
          width: 100%;
        }
      }
    }
    .react-calendar__tile--active {
      background: transparent;
      color: #5C0089;
      border-radius: 5px;
      border: 1px solid #5C0089;
    }
    .react-calendar__tile--now {
      color: #fff;
      border-radius: 5px;
      border: 1px solid #5C0089;
      background: #5C0089;
    }
    .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
      background: transparent;
    }
    .date_range_picker .react-calendar__navigation button span {
      background: #fff;
      color: #5c0089;
      font-size: 12px;
      font-weight: 400;
      padding: 5px;
    }
    .date_time_picker__wrapper_calendar .react-calendar__navigation button span {
      font-size: 12px !important;
    }
    .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
      color: #5C0089;
      border-radius: 5px;
      border: 1px solid #5C0089;
      background: transparent;
    }
    .date_time_picker__wrapper_calendar .react-calendar__tile:enabled:hover, .date_time_picker__wrapper_calendar .react-calendar__tile:enabled:focus {
      color: #fff !important;
    }
    .react-calendar--selectRange .react-calendar__tile--hover {
      color: #5C0089;
      border-radius: 5px;
      border: 1px solid #5C0089;
      background: transparent;
    }
    .react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile {
      padding: 3px;
      font-size: 16px;
    }
    .react-calendar__tile--hasActive {
      background: #5c0089 !important;
      color: #fff !important;
    }
    .react-calendar__decade-view__years {
      & button {
        padding: 8px !important;
        font-size: 12px !important;
      }
    }
    .react-calendar__year-view {
      .date_time_picker__wrapper_calendar .react-calendar__decade-view .react-calendar__tile {
      }
      & button {
        padding: 7px 0 !important;
        font-size: 16px !important;
      }
    }
    .date_time_picker__wrapper_calendar .react-calendar__navigation button {
      font-size: 20px;
      color: #5c0089;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
    }
    .react-calendar--doubleView .react-calendar__viewContainer {
      align-items: center !important;
      justify-content: center !important;
      display: flex !important;
      gap: 15px !important;
    }
    .react-calendar__navigation__label {
      display: flex;
      align-items: center;
    }
  }
  &--disabled {
    pointer-events: none;
    opacity: 0.6;
  }
}
.date_time_picker_wrapper .react-calendar__tile {
  font-size: 11px !important;
}
.react-calendar__navigation button:disabled {
  color: transparent;
  background: transparent !important;
}

@media only screen and (max-width: 600px) {
  .date_time_picker_wrapper_time {
    padding: 0;
  }
  .date_time_picker_wrapper_calendar__double {
    padding: 0;
  }
  .date_time_picker_wrapper .react-calendar--doubleView .react-calendar__viewContainer {
    flex-direction: column;
    gap: 0;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 90% !important;
  }
  .date_time_picker_wrapper_time_single .drop_down {
    margin-right: 0;
  }
  .date_time_picker_wrapper_time_single .drop_down_in {
    max-height: 30px;
  }
  .date_time_picker_wrapper_btn {
    height: 30px !important;
  }
  .date_time_picker_wrapper_time_single .drop_down_in .drop_down__input {
    width: 100%;
  }
  .date_time_picker_wrapper_time_double .drop_down_in {
    max-height: 30px;
  }
}