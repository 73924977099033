.branch_settings_popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 6;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  flex-direction: column;
  &__title {
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    button {
      border: 1px solid #5C0089;
      color: #fff;
      transition: all .3s;
      font-size: 13px;
      font-weight: 400;
      height: 34px;
      letter-spacing: .055em;
      padding: 4px 16px;
      min-width: 180px;
      cursor: pointer;
      box-sizing: border-box;
      background: #5C0089;
      border-radius: 26px;
      text-transform: lowercase;
      margin-left: auto;
      margin-right: 30px;
      &:hover {
        background: #f0b101;
        color: #fff;
        border: 1px solid #f0b101;
      }
    }
    svg {
      color: #000;
      font-size: 14px;
      margin-left: 10px;
      transition: all .3s;
      cursor: pointer;
      &:hover {
        color: #5c0089;
        transform: scale(1.1);
      }
    }
  }
  &__close {
    position: absolute;
    top: 27px;
    right: 20px;
    svg {
      color: #000;
      font-size: 22px;
      transition: all .3s;
      cursor: pointer;
      &:hover {
        color: #5c0089;
        transform: rotate(180deg);
      }
    }
  }
  &__overlay {
    z-index: 6;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
  }
  &_in {
    width: 100%;
    overflow: auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &__icon {
      svg {
        font-size: 18px !important;
        cursor: pointer;
        color: #000;
        transition: all .3s ease;
        &:hover {
          transform: scale(1.1);
          color: #5C0089;
        }
      }
    }
    .table {
      overflow-y: auto;
      padding: 0 20px;
    }
    .table_header {
      position: static;
    }
  }
  .form {
    width: 100%;
  }
}