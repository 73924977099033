.header {
  width: 100%;
  background: #F1F3F7;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 60px;
  padding-left: 10px;
  z-index: 6;
  &--short {
    height: 60px !important;
  }
  &_nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &_tab {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .3s ;
      cursor: pointer;
      border: 1px solid #5c0089;
      text-transform: uppercase;
      background: #f8faff;
      color: #5c0089;
      font-weight: bold;
      min-height: 30px;
      min-width: 30px;
      max-height: 30px;
      max-width: 30px;
      border-radius: 100px;
      margin-left: 10px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      &:hover {
        transform: scale(1.1);
      }
      &--active {
        cursor: default;
        background: #f0b101;
        color: #fff;
        border: 1px solid #f0b101;
        & svg {
          color: #fff !important;
        }
      }
      & svg {
        font-size: 18px;
        color: #5c0089;
        cursor: pointer;
      }
    }
  }
  &_in {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    &_logo {
      width: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 5px;
      cursor: pointer;
      img {
        width: 100%;
        height: auto;
        background-size: cover;
        object-fit: contain;
      }
    }
    &_clock {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f1f3f7;
      text-transform: lowercase;
      padding: 7px 12px;
      min-width: 170px;
      transition: all .3s ease;
      border-radius: 18px;
      //box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      border: 1px solid #999;
      display: none;
      p {
        color: #000;
        font-size: 13px;
        font-weight: 400;
      }
    }
    &_analytics {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0;
      &_item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-right: 5px;
        height: 100%;
        background: #fff;
        padding: 6px 12px;
        border-radius: 16px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        .loading-circle {
          width: unset;
          height: unset;
          padding: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        p {
          color: #000;
          font-size: 13px;
          font-weight: 400;
          line-height: 100%;
        }
        svg {
          margin-right: 4px;
          width: 20px !important;
          height: 20px !important;
          path {
            width: 20px !important;
            height: 20px !important;
          }
        }
        &--purple {
          svg {
            color: #5C0089;
          }
        }
        &--yellow {
          svg {
            color: #f0b101;
          }
        }
        &--green {
          svg {
            color: green;
            width: 18px !important;
            height: 18px !important;
            path {
              width: 18px !important;
              height: 18px !important;
            }
          }
        }
      }
    }
    &_actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 20px 0 20px;
      margin-left: auto;
      &_btn {
        background: #5C0089;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        color: #fff;
        line-height: 100%;
        border: none;
        font-size: 13px;
        font-weight: 400;
        text-transform: lowercase;
        padding: 7px 12px;
        transition: all .3s ease;
        border-radius: 18px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg {
          font-size: 18px;
          color: #fff;
          margin-right: 10px;
        }
        &:hover {
          transform: scale(1.05);
          background: #f0b101;
          color: #fff;
        }
      }
    }
    &_wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: 5px;
      padding: 5px 0;
    }
    &_title {
      cursor: pointer;
      p {
        font-size: 10px;
        padding-top: 2px;
        color: #000;
      }
      &--main {
        font-weight: 600;
        font-size: 18px !important;
        color: #5c0089 !important;
      }
    }
  }
  &_profile {
    &__separator {
      width: 31px;
      height: 1px;
      box-sizing: border-box;
      border: 1px solid rgba(#000, .1);
      transform: rotate(-90deg);
      margin-left: 10px;
    }
    &__img {
      min-width: 40px;
      max-width: 40px;
      min-height: 40px;
      max-height: 40px;
      object-fit: fill;
      cursor: pointer;
      background-size: cover;
      border-radius: 100px;
      transition: all .3s;
      overflow: hidden;
      margin-left: 7px;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .header {
    height: 80px;
    align-items: flex-start;
    &--short {
      height: 40px !important;
    }
  }
  .header_in {
    flex-wrap: wrap;
    .notifications_in_label {
      display: none;
    }
    .notifications_in {
      margin-left: 5px;
    }
    .notifications_in svg {
      font-size: 30px;
    }
  }
  .header_in_actions_btn {
    display: none;
  }
  .header_in_logo {
    width: 140px;
    margin-right: auto;
  }
  .header_in_actions {
    padding: 0;
    justify-content: flex-start;
    margin: 0 10px 0 0;
    order: 1;
  }
  .header_in_analytics {
    width: 100%;
    justify-content: space-between;
    padding: 5px 15px 0 15px;
    order: 2;
  }
  .header_profile__separator {
    display: none;
  }
  .header_profile__img {
    min-width: 27px;
    max-width: 27px;
    min-height: 27px;
    max-height: 27px;
  }
  .header_nav_tab {
    min-height: 25px;
    min-width: 25px;
    max-height: 25px;
    max-width: 25px;
    margin-left: 10px;
    box-shadow: none;
    svg {
      font-size: 15px;
    }
  }
  .header_in_analytics_item p {
    font-size: 12px;
  }
}
@media only screen and (max-width: 400px) {
  .header_in_analytics_item p {
    font-size: 10px;
  }
}
@media only screen and (max-width: 370px) {
  .header_in_logo {
    display: none;
  }
  .header_in_actions {
    margin-left: auto;
  }
}
@media only screen and (max-width: 270px) {
  .header_in_actions {
    margin-left: auto;
  }
  .header_in_analytics_item p {
    font-size: 7px;
  }
}