.form_col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 2px;
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
  &--fit {
    width: 100%;
    flex: 1 1;
  }
  input[type='file'] {
    display: none;
  }
  &_file {
    &--add {
      border: none;
      background: none;
      font-size: 13px !important;
      font-weight: 400 !important;
      color: #5C0089 !important;
      transition: all .3s ease;
      margin: 5px 0 !important;
      cursor: pointer !important;
      &:focus, &:active {
        border: none;
      }
    }
    &--remove {
      border: none;
      cursor: pointer !important;
      background: none;
      font-size: 13px;
      font-weight: 400;
      color: #5C0089;
      transition: all .3s ease;
      margin: 5px 0 !important;
      &:focus, &:active {
        border: none;
      }
    }
  }
  label {
    text-align: left;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
    padding-left: 2px;
    color: #000000;
    font-weight: 400;
    margin: 25px 0 7px 0;
    span {
      font-size: 14px;
      color: red;
      height: 12px;
      display: flex;
      align-self: flex-start;
      margin-right: 2px;
    }
  }
  input[type="email"], input[type="text"], input[type="password"], input[type="number"], textarea {
    display: block;
    transition: all .3s ease;
    box-sizing: border-box;
    width: 100%;
    padding: 15px 10px;
    line-height: 15px;
    border: 1px solid #A39F9F;
    background: transparent;
    border-radius: 16px;
    height: 35px;
    font-weight: 300;
    font-size: 13px;
    color: #000;
    &:focus, &:hover, &:active {
      border: 1px solid #5C0089;
    }
    &::placeholder {
      color: #bbb6b6;
      font-weight: 200;
      font-size: 13px;
    }
  }
  textarea {
    min-height: 70px;
    padding: 10px 0;
    text-indent: 20px;
  }
  input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    display: inline-block;
    width: 20px !important;
    height: 20px !important;
    color: #fff;
    padding: 1px;
    background-clip: content-box;
    border: 2px solid rgb(200, 200, 200);
    background-color: rgb(200, 200, 200);
    border-radius: 100px;
    margin-right: 10px;
  }
  input[type="radio"]:checked {
    background-color: #5c0089;
  }
}
