.status {
  min-width: 100px !important;
  height: 24px !important;
  color: #fff;
  padding: 0 10px;
  text-transform: lowercase;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid transparent;
  border-radius: 6px;
  font-weight: 300;
  align-self: center;
  justify-self: center;
  justify-items: center;
  & p {
    font-size: 12px !important;
    font-weight: 300 !important;
    text-transform: lowercase;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &--sm {
    min-height: 25px !important;
    p {
      font-size: 12px !important;
      font-weight: 300 !important;
      text-align: center !important;
    }
  }
  &--flag {
    border-radius: 0 !important;
    min-width: 70px !important;
    min-height: 25px !important;
    .status {
    border-radius: 0;
  }
    p {
      font-size: 11px !important;
    }
  }
  &--unassigned, &--default {
    background: rgba(128, 128, 128, 1);
    color: #fff !important;
    border: 1px solid rgba(128, 128, 128, 1);
  }
  &--created {
    background: rgba(128, 128, 128, 1);
    border: 1px solid rgba(128, 128, 128, 1);
    color: #fff !important;
  }
  &--assigned, &--order_accepted_sms_template, &--website, &--new {
    background: #f0b101;
    color: #fff !important;
    border: 1px solid #f0b101;
  }
  &--accepted {
    background: darkorange;
    color: #fff !important;
    border: 1px solid darkorange;
  }
  &--started, &--order_started_sms_template {
    background: rgba(92, 0, 137, 1);
    border: 1px solid rgba(92, 0, 137, 1);
    color: #fff !important;
  }
  &--picked_up, &--order_picked_up_sms_template {
    background: rgba(0, 0, 139, 1);
    border: 1px solid rgba(0, 0, 139, 1);
    color: #fff !important;
  }
  &--on_way, &--order_on_way_sms_template {
    background: rgba(0, 0, 255, 1);
    border: 1px solid rgba(0, 0, 255, 1);
    color: #fff !important;
  }
  &--delivered, &--order_delivered_sms_template, &--order_delivered_by_partner_sms_template, &--active, &--online, &--unfreeze {
    background: rgba(0, 128, 0, 1);
    border: 1px solid rgba(0, 128, 0, 1);
    color: #fff !important;
  }
  &--canceled, &--failed, &--order_failed_sms_template, &--order_canceled_sms_template, &--freezed, &--offline {
    background: rgba(255, 0, 0, 1);
    color: #fff !important;
    border: 1px solid rgba(255, 0, 0, 1);
  }
}

@media only screen and (max-width: 800px) {
  .status--sm p {
    font-size: 10px !important;
    padding: 0 10px !important;
  }
}