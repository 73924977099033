.agent_history {
  width: 100%;
  padding: 15px 0;
  .orders_modal__tabs {
    padding: 0 5px 0 5px;
  }
  &_header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    p {
      font-size: 13px;
      font-weight: 400;
      color: #000;
      text-transform: lowercase;
    }
    & input[type="checkbox"] {
      -webkit-appearance: none;
      background: #f1f3f7;
      width: 45px;
      height: 7px;
      border-radius: 20px;
      box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
      transition: all .3s;
      position: relative;
      left: -7px;
      cursor: pointer;
      margin-left: 19px;
    }
    & input:checked[type="checkbox"] {
      background: #f1f3f7;
    }
    & input[type="checkbox"]:before {
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      border-radius: 20px;
      top: 50%;
      left: -5px;
      transform: translateY(-50%);
      background: #f0b101;
      transition: all .5s;
      box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    }
    & input:checked[type="checkbox"]::before {
      left: 30px;
      background: green;
    }
  }
  &_title {
    font-size: 14px;
    font-weight: 400;
    color: #000;
    text-transform: capitalize;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 5px;
    padding: 0 10px;
    &--no_margin {
      margin-top: 0;
    }
  }
  &_no_data {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    font-weight: 400;
    padding: 0 10px;
    margin-right: auto;
    color: #000;
  }
  &_profile {
    width: 100%;
    &__info {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      border-bottom: 1px solid rgba(0,0,0,.1);
      padding: 0 10px 10px 10px;
      & svg {
        font-size: 18px;
        color: #000;
        margin-right: 8px;
        margin-top: 10px;
      }
      &_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &--label {
        font-size: 12px;
        font-weight: 300;
        color: #000;
        margin-top: 10px;
      }
      &--descrip {
        font-size: 13px;
        font-weight: 400;
        color: #000;
        margin-top: 5px;
        margin-left: 27px;
      }
      &--location {
        font-size: 13px;
        font-weight: 400;
        color: #000;
        margin-top: 5px;
        margin-left: 27px;
        line-height: 20px;
      }
      &--location_btn {
        font-size: 13px;
        font-weight: 400;
        color: #000;
        margin-top: 5px;
        margin-left: 27px;
        line-height: 20px;
        cursor: pointer;
        transition: all .3s ease;
        background: none;
        border: none;
        text-align: left;
        &:hover {
          color: #5C0089;
        }
      }
    }
  }
  &_task {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 20px;
    position: relative;
    cursor: default;
    padding-left: 35px;
    &_in {
      background: transparent;
      &--highlighted {
        background: #f1f3f7;
        border: none;
        .agent_history_task_in_item {
          background: #fff;
        }
      }
      &--dragging {
        transition: all .3s ease;
        background: #f1f3f7;
        border: none;
        .agent_history_task_in_item {
          background: #fff;
          opacity: 0.5;
        }
      }
      &_item {
        width: 100%;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        transition: all .3s;
        position: relative;
        cursor: default;
        border-radius: 35px;
        background: rgba(#e2e3e5, .5);
        box-shadow: 0 0 0 rgba(0, 0, 0, 1);
        border: 1px solid #e2e3e5;
        padding-left: 10px;
        &_draggable {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          transition: all .3s ease;
          &:hover {
            svg {
              color: #f0b101;
            }
          }
          svg {
            transition: all .3s ease;
            font-size: 18px;
            color: #000;
            cursor: grab;
          }
        }
        &_btn {
          background: red;
          margin-left: auto;
          font-size: 13px;
          font-weight: 400;
          color: #000;
          background: none;
          transition: all .3s ease;
          cursor: pointer;
          padding: 4px 8px;
          border: none;
          &:hover {
            color: #5C0089;
          }
        }
        &_status {
          height: 24px !important;
          color: #fff;
          padding: 0 10px;
          text-transform: lowercase;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          font-size: 12px;
          min-width: 80px;
          margin-top: 0;
          &--unassigned {
            background: rgba(128, 128, 128, 1);
            color: #fff !important;
            border: 1px solid rgba(128, 128, 128, 1);
          }
          &--created {
            background: rgba(128, 128, 128, 1);
            border: 1px solid rgba(128, 128, 128, 1);
            color: #fff !important;
          }
          &--assigned{
            background: #f0b101;
            color: #fff !important;
            border: 1px solid #f0b101;
          }
          &--accepted {
            background: darkorange;
            color: #fff !important;
            border: 1px solid darkorange;
          }
          &--started {
            background: rgba(92, 0, 137, 1);
            border: 1px solid rgba(92, 0, 137, 1);
            color: #fff !important;
          }
          &--picked_up {
            background: rgba(0, 0, 139, 1);
            border: 1px solid rgba(0, 0, 139, 1);
            color: #fff !important;
          }
          &--on_way {
            background: rgba(0, 0, 255, 1);
            border: 1px solid rgba(0, 0, 255, 1);
            color: #fff !important;
          }
          &--delivered {
            background: rgba(0, 128, 0, 1);
            border: 1px solid rgba(0, 128, 0, 1);
            color: #fff !important;
          }
          &--canceled, &--failed {
            background: rgba(255, 0, 0, 1);
            color: #fff !important;
            border: 1px solid rgba(255, 0, 0, 1);
          }
        }
        &_heading {
          width: 100%;
          padding: 15px 25px 5px 25px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          &_id {
            font-size: 12px;
            text-transform: lowercase;
            color: #676565;
            font-weight: 400;
            margin-right: 5px;
          }
        }
        &_info {
          width: 100%;
          padding: 0 25px 10px 25px;
          &_wrapper {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 3px 0;
            span {
              font-size: 12px;
              font-weight: 300;
              color: #676565;
              min-width: 60px;
              text-transform: lowercase;
            }
            p {
              font-size: 13px;
              font-weight: 400;
              color: #000;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .agent_history {
    .order_item_times_time--pre {
      margin-bottom: 0;
    }
  }
  .agent_history_task_in_item_status {
    font-size: 11px;
  }
  .agent_history_task_in_item_info_wrapper p {
    font-size: 12px;
  }
  .agent_history_task_in_item_btn {
    font-size: 10px;
  }
  .agent_history_task_in_item_heading {
    padding: 15px 15px 5px 15px;
  }
  .agent_history_task_in_item_info {
    padding: 0 15px 10px 15px;
  }
}
@media only screen and (max-width: 350px) {
  .agent_history_task_in_item_info_wrapper p {
    font-size: 11px;
  }
  .agent_history_task_in_item_heading_id {
    font-size: 11px;
  }
  .agent_history_task_in_item_btn {
    font-size: 11px;
  }
}