.search {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  &_input {
    margin: 10px 0 10px 0;
    height: 35px;
    border: 1px solid #000;
    width: 80%;
    border-radius: 16px;
    transition: all .3s ease;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &:focus, &:hover, &:active {
      border: 1px solid #5C0089;
    }
    & label {
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 18px;
        color: #666;
      }
    }
    & input {
      display: block;
      width: 100%;
      box-sizing: border-box;
      padding: 15px 10px;
      border: none;
      background: transparent;
      height: 100%;
      font-weight: 300;
      font-size: 13px;
      color: #000;
      &:focus, &:hover, &:active {
        border: none;
      }
      &::placeholder {
        color: #bbb6b6;
        font-weight: 200;
        font-size: 13px;
      }
    }
  }
  &_drop_down {
    width: 20%;
    height: 100%;
    margin-left: 15px;
    input {
      width: 100%;
    }
    .drop_down_in {
      border-radius: 16px;
      border: 1px solid #000;
      &:hover {
        border: 1px solid #5C0089;
      }
    }
  }
  &_btn {
    background: rgba(92, 0, 137, 1);
    text-transform: uppercase;
    border: 1px solid rgba(92, 0, 137, 1);
    border-radius: 5px;
    margin: 0;
    padding: 6px 10px;
    color: #fff !important;
    cursor: pointer;
    transition: all 0.3s;
    font-weight: 500;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin-left: 5px;
    & svg {
      font-size: 20px;
      color: #fff;
      cursor: pointer;
      margin-left: 10px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .search_drop_down {
    width: 40%;
  }
  .search_input {
    width: 60%;
  }
}
@media only screen and (max-width: 600px) {
  .search_input {
    height: 30px;
    margin: 0;
  }
  .search_input input {
    font-size: 12px;
    &::placeholder {
      font-size: 12px;
    }
    &::-ms-input-placeholder {
      font-size: 12px;
    }
  }
  .search_drop_down {
    height: 30px;
    margin-left: 5px;
    .drop_down__input {
      font-size: 12px;
    }
    .drop_down_in {
      height: 30px;
    }
  }
}

