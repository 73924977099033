.page_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  padding: 20px 0;
  & p {
    color: #000;
    display: inline;
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
  }
  &__in {
    button {
      cursor: pointer;
      transition: all .3s;
      font-size: 11px;
      margin-left: 10px;
      padding: 5px 10px;
      min-width: 70px;
      border-radius: 5px;
      background: #5c0089;
      border: 1px solid #5c0089;
      color: #fff;
      font-weight: 600;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      span {
        font-size: 14px;
        margin-right: 5px;
      }
      &:hover {
        background: #fff;
        color: #5c0089;
      }
    }
  }
  &_navigation {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 20px 0 25px 0;
    height: 70px;
    svg {
      margin: 0 20px;
    }
    &--active {
      transition: all .3s;
      cursor: pointer;
      color: #000;
      font-size: 20px;
      font-weight: 600;
      line-height: normal;
    }
    &--title {
      color: #000;
      font-size: 20px;
      font-weight: 600;
      line-height: normal;
      text-decoration: none;
      transition: all .3s;
      cursor: pointer;
      &:hover {
        color: #000;
      }
    }
    & a {
      text-decoration: none;
      color: #000;
      transition: all .3s;
      cursor: pointer;
      font-size: 20px;
      font-weight: 300;
      line-height: normal;
      &:hover {
        color: #f0b101 !important;
      }
    }
  }
  &_profile {
    display: flex;
    align-items: center;
    margin-left: auto;
    &__data {
      display: flex;
      align-items: center;
      justify-content: center;
      & span {
        font-size: 12px;
        color: #000;
        font-weight: 300;
        margin-right: 10px;
      }
      & p {
        font-size: 14px;
        color: #000;
        font-weight: 400;
        margin-right: 5px;
        &:last-child {
          margin-right: 0;
        }
      }
      &_img {
        border-radius: 100px;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        margin-left: 10px;
        img {
          border-radius: 100px;
          width: 100%;
          height: 100%;
          object-fit: cover;
          background-position: center;
          background-size: cover;
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .page_header, .page_header_navigation {
    padding: 0 0 10px 0;
  }
}
@media only screen and (max-width: 800px) {
  .page_header_navigation svg {
    margin: 0 5px;
  }
  .page_header p {
    font-size: 16px;
  }
  .page_header_navigation a, .page_header_navigation--active {
    font-size: 16px;
  }
}
@media only screen and (max-width: 600px) {
  .page_header, .page_header_navigation {
    padding: 5px 0 10px 0;
  }
  .page_header_profile__data span {
    font-size: 10px;
    margin-right: 5px;
  }
  .page_header_profile__data p {
    font-size: 12px;
    margin-right: 0;
  }
  .page_header_profile__data_img {
    width: 40px;
    height: 40px;
  }
  .page_header_profile__data {
    span, p {
      display: none;
    }
  }
}
@media only screen and (max-width: 350px) {
  .page_header_navigation--active {
    display: none;
  }
}