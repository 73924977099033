.admin {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f1f3f7;
  &_in {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    &_header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      padding: 10px 30px;
      &_top {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        padding: 20px 0 15px 0;
        background: #f1f3f7;
        &_user {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-right: 10px;
          p {
            font-size: 14px;
            font-weight: 400;
            margin-left: 10px;
          }
        }
        &_logout {
          border-radius: 5px;
          border: none;
          background: none;
          margin-left: 10px;
          color: #000;
          cursor: pointer;
          display: block;
          font-size: 13px;
          font-weight: 400;
          transition: all .3s;
          text-align: right;
          &:hover {
            color: #5c0089;
          }
        }
      }
      &_calendar {
        min-width: 270px;
        margin-left: auto;
        .date_time_picker_wrapper {
          margin-top: 13px;
        }
        .date_time_picker {
          background: transparent;
        }
        .orders_date_time_picker {
          border: 1px solid #000 !important;
          transition: all .3s ease;
          &:hover {
            border: 1px solid #5C0089 !important;
          }
        }
        .date_time_picker.orders__date_time_picker {
          height: 40px !important;
          border-radius: 16px;
          background: transparent;
          & input {
            font-size: 16px !important;
            font-weight: 600;
          }
        }
      }
      &_logo {
        width: 180px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: auto;
          background-size: cover;
          object-fit: contain;
        }
      }
    }
    &_content {
      width: 100%;
      height: 100%;
      overflow: auto;
      padding: 0 25px 0 30px;
    }
    .table {
      thead {
        height: 40px !important;
        background-color: #f1f3f7 !important;
        color: #000 !important;
        font-weight: 300 !important;
        font-size: 12px !important;
        border-bottom: 1px solid #f1f3f7 !important;
        box-shadow: none;
        tr {
          box-shadow: none !important;
        }
        th {
          background-color: #f1f3f7 !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .admin_in_content {
    padding: 0 15px;
  }
  .admin_in_header {
    padding: 0 15px;
  }
  .admin_in_header {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 600px) {
  .admin_in_header_calendar {
    min-width: calc(100% - 100px);
    margin-right: auto;
    margin-left: unset;
  }
}