.phone_number_input {
  width: 100%;
  position: relative;
  input {
    display: block;
    transition: all .3s ease;
    box-sizing: border-box;
    width: 100%;
    padding: 15px 10px;
    line-height: 15px;
    border: 1px solid #A39F9F;
    background: transparent;
    border-radius: 16px;
    height: 35px;
    font-weight: 300;
    font-size: 13px;
    color: #000;
    margin: 0;
    &:focus, &:hover, &:active {
      border: 1px solid #5C0089;
    }
    &::placeholder {
      color: #bbb6b6;
      font-weight: 200;
      font-size: 13px;
    }
  }
  &_in {
    display: flex;
    align-self: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    height: 35px;
    width: 40px;
    &_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      &--loading {
        .loading-circle {
          width: 40px;
        }
      }
      &--error {
        svg {
          color: red;
          font-size: 20px;
        }
      }
      &--green {
        svg {
          color: green;
        }
      }
    }
  }
}