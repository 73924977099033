.navigation {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #f1f3f7;
  height: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  overflow: hidden;
  font-family: inherit;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  padding: 10px 0;
  z-index: 5;
  width: 0;
  &--bottom {
    top: 60px;
  }
  &__in {
    margin: 20px auto;
    position: relative;
    background: #f1f3f7;
  }
  & ul {
    width: 70px;
    margin: 30px 0 25px 0;
    display: none;
    background: #f1f3f7;
  }
  &__list {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 10px;
    height: 56px;
    background: #f1f3f7;
    margin-top: 5px;
    & a {
      text-decoration: none !important;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #000;
      width: 100%;
      height: 100%;
      display: inline-flex;
      align-items: center;
      text-align: start;
      background: #f1f3f7;
      position: relative;
      transition: all .3s;
      &:hover, &:active, &:focus {
        background: rgba(92, 0, 137, 0.1);
        border-radius: 6px;
        color: #5c0089;
        outline: none;
        .navigation__list-icon {
          color: #5c0089;
        }
      }
    }
    &--active {
      .navigation__list-icon {
        color: #5c0089;
        cursor: default;
      }
      & a {
        //background: rgba(92, 0, 137, 0.09);
        color: #5c0089;
        position: relative;
        text-decoration: none;
        background: rgba(92, 0, 137, 0.1);
        border-radius: 6px;
        cursor: default;
        //&:hover {
        //  background: linear-gradient(
        //                  to right,
        //                  rgba(92, 0, 137, 0.1) 50%,
        //                  transparent 50%
        //  );
        //  background-size: 200% 100%;
        //  background-position: left bottom;
        //  transition: all 0.5s ease-out;
        //}
        //&::after {
        //  content: "";
        //  height: 100%;
        //  width: 3.5px;
        //  background-color: rgba(92, 0, 137, 1);
        //  position: absolute;
        //  top: 0;
        //  left: 0;
        //  border-radius: 0 10px 10px 0;
        //}
      }
    }
    &-icon {
      font-size: 22px;
      color: #000;
      margin: 0 10px;
      display: flex;
      align-items: center;
      transition: all .3s;
    }
  }
  &__overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    transition: all 0.3s;
    z-index: 3;
  }
  &--animate {
    background: #f1f3f7;
    width: 250px;
    animation: animation1 0.3s ease-in-out;
    & ul {
      width: 250px;
      animation: animation1 0.3s ease-in-out;
      display: block;
      background: #f1f3f7;
    }
    & li {
      width: 250px;
      animation: animation1 0.3s ease-in-out;
    }
  }
}

@keyframes animation1 {
  0% {
    width: 0;
  }
  70% {
    width: 330px;
  }
  100% {
    width: 230px;
  }
}
@keyframes height {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .navigation {
    box-shadow: none !important;
    padding: 0;
    z-index: 9;
    top: 40px;
    &--animate {
      width: 100%;
    }
    &--bottom {
      top: 95px;
    }
    &__in {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      & > ul {
        width: 100%;
        position: fixed;
        top: 40px;
        left: 0;
        background-color: #f1f3f7;
        margin: 0;
        display: none;
        animation: none;
        & > li {
          width: 100%;
          animation: none;
        }
      }
    }
    .show {
      display: block;
      height: calc(100% - 40px);
      overflow-y: auto;
      z-index: 9;
      &--bottom {
        height: calc(100% - 95px);
        top: 95px !important;
      }
    }
  }
}

