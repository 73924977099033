.account_sms_credits {
  width: 100%;
  height: 100%;
  position: relative;
  padding-bottom: 20px;
  &_in {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    &_txt {
      width: 100%;
      font-size: 14px;
      color: #000;
      font-weight: 300;
      text-align: left;
      margin-bottom: 30px;
      line-height: 22px;
    }
    &_switcher {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-right: auto;
      margin-bottom: 25px;
      & input[type="checkbox"] {
        -webkit-appearance: none;
        background: #f1f3f7;
        width: 45px;
        height: 7px;
        border-radius: 20px;
        box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        transition: all .3s;
        position: relative;
        left: -18px;
        cursor: pointer;
        margin-left: 20px;
      }
      & input:checked[type="checkbox"] {
        background: #f1f3f7;
      }
      & input[type="checkbox"]:before {
        content: '';
        position: absolute;
        width: 15px;
        height: 15px;
        border-radius: 20px;
        top: 50%;
        left: -5px;
        transform: translateY(-50%);
        background: #f0b101;
        transition: all .5s;
        box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
      }
      & input:checked[type="checkbox"]::before {
        left: 30px;
        background: green;
      }
    }
    &_btn {
      transition: all .3s;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: .055em;
      cursor: pointer;
      box-sizing: border-box;
      background: none;
      border: none;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 17px 24px;
      margin-right: auto;
      margin-bottom: 20px;
      text-align: center !important;
      color: #fff;
      background: rgba(#5c0089, 0.5);
      border-radius: 26px;
      &:hover {
        background: #f0b101;
        color: #fff !important;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
}