.no_data {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  height: 100%;
  width: 100%;
  &__text {
    width: 100%;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    color: #999;
    font-size: 14px;
  }
}
