.edit_notifications_form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  z-index: 8;
  background: #fff;
  padding: 20px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 700px;
  &_submit_btn {
    background: #5C0089 !important;
    color: #fff;
    border: 1px solid #5C0089;
    font-size: 12px;
    text-transform: lowercase;
    padding: 7px 10px;
    transition: all .3s ease;
    border-radius: 18px;
    height: 35px;
    cursor: pointer;
    width: 100%;
    &--pending {
      background: #f0b101 !important;
      border: 1px solid #f0b101;
      .loading-circle {
        padding: 0;
        height: 100%;
      }
    }
    &:hover, &:active, &:focus {
      background: #f0b101 !important;
      color: #fff;
      border: 1px solid #f0b101;
    }
  }
  &_cancel_btn {
    display: flex;
    height: 100%;
    margin-right: auto;
    font-size: 13px;
    text-decoration: none;
    transition: all .3s;
    color: #444;
    background: none !important;
    border: none;
    cursor: pointer;
    text-transform: lowercase;
    &:hover {
      color: #5c0089;
    }
  }
  &_count {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    p {
      font-size: 12px;
      font-weight: 300;
      padding-top: 5px;
    }
    span {
      margin-right: 5px;
      font-size: 10px;
      font-weight: 300;
      padding-top: 5px;
    }
  }
  .form {
    width: 100%;
  }
}