.settings {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #f1f3f7;
  &_in {
    display: flex;
    align-items: center;
    width: 100%;
    height: calc(100% - 70px);
  }
  &_menu {
    width: 300px;
    height: 100%;;
    background: #f1f3f7;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    & h2 {
      font-size: 14px;
      font-weight: 400;
      color: #000;
      margin: 0 0 20px 0;
    }
    & ul {
      width: 100%;
      background: #f1f3f7;
      margin-bottom: 20px;
    }
    &_logout_btn {
      border-radius: 5px;
      border: none;
      background: transparent;
      color: #000;
      cursor: pointer;
      font-size: 13px;
      font-weight: 400;
      padding: 3px 4px;
      transition: all .3s;
      text-align: left;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;
      svg {
        color: #000;
        font-size: 20px;
      }
      &:hover {
        color: #5c0089;
      }
    }
    &_lng {
      margin-top: auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
     &_btn {
       cursor: pointer;
       transition: all .3s;
       color: #000;
       font-size: 13px;
       padding: 3px 6px;
       border-radius: 5px !important;
       font-weight: 300;
       border: 1px solid transparent;
       background: #f1f3f7;
       display: flex;
       align-items: center;
       margin-left: 4px;
       &:hover {
         color: #000;
         background: #f1f3f7;
         border: 1px solid #A39F9F;
       }
       &--active {
         color: #000;
         background: rgba(#f1f3f7, 1);
         border: 1px solid #A39F9F;
       }
     }
    }
    &_list {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      background: #f1f3f7;
      margin-top: 4px;
      & a {
        text-decoration: none !important;
        padding: 10px 0;
        width: 100%;
        height: 100%;
        display: inline-flex;
        align-items: center;
        text-align: start;
        background: #f1f3f7;
        transition: all .3s;
        &:hover, &:active, &:focus {
          background: rgba(92, 0, 137, 0.1);
          border-radius: 6px;
          color: #5c0089;
          outline: none;
          svg {
            color: #5c0089;
          }
        }
        span {
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          color: #000;
        }
        & svg {
          font-size: 19px;
          color: #000;
          margin: 0 10px;
          display: flex;
          align-items: center;
          transition: all .3s;
        }
      }
      &--active {
        a {
          background: rgba(92, 0, 137, 0.1);
          border-radius: 6px;
          outline: none;
          span {
            color: #5c0089;
          }
          svg {
            color: #5c0089;
          }
        }
      }
    }

  }
  &_main {
    width: calc(100% - 300px);
    height: calc(100% - 20px);
    margin-bottom: 20px;
    background-color: #fff;
    box-shadow: 0 20px 30px -18px rgba(4, 15, 21, 0.1);
    border-radius: 16px;
    -webkit-backdrop-filter: blur(20px);
    padding: 15px 30px 0 30px;
    overflow-y: auto;
  }
}

@media only screen and (max-width: 1024px) {
  .settings_menu h2 {
    display: none;
  }
  .settings_menu ul {
    margin-bottom: 0;
  }
  .settings_menu {
    width: 40px;
    margin-right: 10px;
  }
  .settings_main {
    width: calc(100% - 40px);
  }
  .settings_menu_list a span {
    display: none;
  }
  .settings_menu_lng {
    flex-direction: column;
  }
  .settings_menu_logout_btn {
    justify-content: center;
  }
}
@media only screen and (max-width: 500px) {
  .settings_main {
    padding: 15px 15px 0 15px;
  }
}