.branch_card {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: calc(20% - 8px);
  min-height: 260px;
  border-radius: 20px;
  background: #FFFFFF;
  border: 1px solid #000;
  box-shadow: 0 0 0 rgba(0, 0, 0, 1);
  position: relative;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 0 10px 5px 10px;
  &:nth-child(5n) {
    margin-right: 0 !important;
  }
  &_wrapper {
    width: 100%;
    overflow: hidden;
  }
  &_state {
    width: 100%;
    transition: all .3s;
    padding: 10px 0;
    &_in {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &_title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-size: 14px;
      color: #000;
      font-weight: 400;
      text-align: center;
      white-space: nowrap;
      width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__delete {
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 15px;
      svg {
        color: #000;
        cursor: pointer;
        font-size: 20px;
        transition: all .3s;
        &:hover {
          transform: scale(1.1);
          color: red;
        }
      }
    }
    &__settings {
      cursor: pointer;
      position: absolute;
      top: 10px;
      left: 15px;
      svg {
        cursor: pointer;
        color: #000;
        font-size: 18px;
        transition: all .3s;
        &:hover {
          transform: scale(1.1);
          color: #5C0089;
        }
      }
    }
  }
  &_loaction {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-size: 13px;
      color: #000;
      font-weight: 300;
      text-align: center;
      white-space: nowrap;
      width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-top: 5px;
    }
  }
  &_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &__img {
      min-width: 70px;
      min-height: 70px;
      max-width: 70px;
      max-height: 70px;
      overflow: hidden;
      border-radius: 100px;
      margin: 10px 0 5px 0;
      & img {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        object-fit: fill;
      }
    }
    &_info {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      &__name {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5px 0 5px 0;
        width: 100%;
        position: relative;
        p {
          white-space: nowrap;
          font-size: 14px;
          color: #000;
          font-weight: 400;
          width: 250px;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;
        }
      }
      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        svg {
          color: #000;
          cursor: pointer;
          font-size: 14px;
          transition: all .3s;
          &:hover {
            transform: scale(1.1);
            color: #5c0089;
          }
        }
      }
      &__data {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 5px;
        p {
          width: 250px;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 13px;
          color: #000;
          font-weight: 300;
          text-align: center;
        }
      }
    }
    &_money {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 8px 0;
      border-radius: 26px;
      border: 1px solid #EAEAEA;
      margin: 8px 0;
      background: #EAEAEA;
      .loading-circle {
        padding: 0 !important;
        height: 29px;
      }
      &__title {
        font-size: 13px;
        font-weight: 400;
        color: #000;
        margin-bottom: 5px;
      }
      &__amount {
        font-size: 20px;
        font-weight: 400;
        color: #000;
      }
    }
  }
  &_in {
    padding: 5px 5px 10px 5px;
    width: 100%;
    &__title {
      color: #000;
      font-weight: 400;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 13px;
      border-top: 1px solid #EAEAEA;
    }
    &_users {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      width: 100%;
      cursor: pointer;
      height: 40px;
      &:last-child {
        border-bottom: 1px solid #EAEAEA;
      }
      &__img {
        margin-right: auto;
        min-width: 30px;
        min-height: 30px;
        max-width: 30px;
        max-height: 30px;
        border-radius: 100px;
        overflow: hidden;
        background-position: center;
        background-size: cover;
        object-fit: fill;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border: 2px solid #fff;
        &:nth-child(1) {
          left: 0;
          z-index: 0;
        }
        &:nth-child(2) {
          left: 20px;
          z-index: 1;
        }
        &:nth-child(3) {
          left: 40px;
          z-index: 2;
        }
        &:nth-child(4) {
          left: 60px;
          z-index: 3;
        }
        &:nth-child(5) {
          left: 80px;
          z-index: 4;
        }
        &:nth-child(6) {
          left: 100px;
          z-index: 5;
        }
        img {
          width: 100%;
          border-radius: 100px;
        }
      }
      &__count {
        color: #000;
        font-size: 14px;
        font-weight: 400;
        display: flex;
        width: 35px;
        height: 35px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all .3s;
        border-radius: 5px;
        padding: 4px 12px;
        border: 1px solid transparent;
        background: #fff;
        text-transform: lowercase;
        margin-left: auto;
        &:hover {
          background: rgba(#EAEAEA, 1);
          border: 1px solid rgba(#EAEAEA, 1);
        }
      }
      &__no_data {
        font-size: 13px;
        text-transform: lowercase;
        color: #000;
        font-weight: 300;
      }
    }
  }
  &_footer {
    width: 100%;
    &_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-radius: 100px;
      &_in {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
      &__count {
        background: #EAEAEA;
        min-width: 50px;
        min-height: 50px;
        max-width: 50px;
        max-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 100px;
        font-size: 14px;
        font-weight: 400;
        padding: 3px;
        overflow: hidden;
        border: 1px solid rgba(#EAEAEA, 1);
      }
      &__icon {
        color: #000;
        margin-top: 3px;
        svg {
          font-size: 18px;
        }
        &--delivered {
          svg {
            color: green;
          }
        }
        &--failed {
          svg {
            color: red;
          }
        }
        &--rating {
          svg {
            color: #f0b101;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .branch_card_state {
    .status--sm p {
      font-size: 10px !important;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .branch_card {
    width: calc(50% - 5px);
    &:nth-child(5n) {
      margin-right: 10px !important;
    }
    &:nth-child(2n) {
      margin-right: 0 !important;
    }
  }
}
@media only screen and (max-width: 600px) {
  .more_popup__item {
    font-size: 12px;
    padding: 8px 12px;
  }
  .branch_card_header__img {
    min-width: 55px;
    max-width: 55px;
    min-height: 55px;
    max-height: 55px;
    margin: 5px 0 0 0;
  }
  .branch_card_header_money {
    padding: 4px 0;
    margin: 4px 0 0 0;
  }
  .branch_card_footer_item__count {
    min-width: 35px;
    max-width: 35px;
    min-height: 35px;
    max-height: 35px;
    font-size: 12px;
  }
  .branch_card_in {
    padding: 5px;
  }
}
@media only screen and (max-width: 500px) {
  .branch_card {
    width: calc(100%);
    &:nth-child(5n) {
      margin-right: 10px !important;
    }
    &:nth-child(2n) {
      margin-right: 10px !important;
    }
    &:nth-child(1n) {
      margin-right: 0 !important;
    }
  }
}