.form_row {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  &_in {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  &--radio {
    justify-content: unset;
  }
  &_title {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: #5c0089;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 4px 0;
    margin-bottom: 5px;
    span {
      font-size: 14px;
      color: red;
      position: relative;
      font-weight: normal;
    }
  }
}


