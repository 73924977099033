.orders_details {
  width: 400px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #f1f3f7;
  height: 100%;
  transition: all 0.3s;
  position: relative;
  z-index: 3;
  &--animate {
    animation: animate-to-right .5s ease;
  }
  &_header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #f1f3f7;
    min-height: 53px;
    padding: 0 10px 0 10px;
    .menu_tab_container {
      margin-top: 0;
    }
    &__close {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      & svg {
        font-size: 30px;
        color: #5c0089;
        cursor: pointer;
        transition: all .3s;
        &:hover {
          transform: rotate(180deg);
        }
      }
    }
    & input, p, a {
      font-weight: 600;
      font-size: 13px;
      color: #5c0089;
      height: 40px;
      width: 100%;
      padding: 7px 5px;
      text-indent: 5px;
      border-radius: 16px;
      border: 1px solid #5c0089;
      background: transparent;
      &::placeholder {
        color: #5c0089;
        font-weight: 600;
      }
      &:focus {
        outline: none;
      }
    }
    & label {
      font-size: 30px;
      position: absolute;
      padding: 7px 5px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      color: #000;
      margin-right: 10px;
      & svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 45px;
        color: #5c0089 !important;
        margin-left: 10px;
        font-size: 20px;
        cursor: pointer;
      }
    }
    & a {
      text-decoration: none;
      cursor: pointer;
      width: 100%;
      height: 35px;
      display: flex;
      align-items: center;
      transition: all .3s;
      background: rgba(92, 0, 137, 0.1);
      color: #5c0089;
      border: 1px solid transparent;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      &:hover {
        background: rgba(240, 177, 1, 0.3);
        border-radius: 16px;
        color: #5c0089;
        outline: none;
        border: 1px solid rgba(240, 177, 1, 0.3);
      }
    }
  }
  &_tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    padding: 0 10px 10px 10px;
    button {
      &:nth-child(1) {
        border-left: none;
      }
    }
  }
  &_in {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    background: #f1f3f7;
    margin-bottom: 10px;
  }
}

@keyframes animate-to-right {
  0% {
    transform: translateX(-450px);
  }
  100% {
    transform: translateX(0);
  }
}

@media only screen and (max-width: 1024px) {
  .orders_details {
    width: 100%;
  }
}